import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const BlogDetailsTrending = ({ title }) => {

    useEffect(() => {
        document.title = `Trending Blog Details - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <div className="section_bg_gray pb-30">
                <div className="container2">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5 text-uppercase"><span className="our-investors-span1">Among the</span> <span className="our-investors-span2">most commonly used varieties <br /> of beverage bottles are bottles made <br /> from plastic for single use</span></div>
                            </div>

                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="blog-details-card">
                            <img className="blogdetails-image" src="assets/images/blog/ecowrap-blog-trending.png" alt="trending-blog-details" />
                            <div style={{ margin: "50px" }}>
                                <div style={{ marginLeft: "10px" }}>
                                    <div className="set-inner-HTML text-left" style={{ fontFamily: "Open Sans", fontStyle: "normal", fontWeight: "500", fontSize: "24px" }}>
                                        One of the most pressing environmental issues we face is plastic pollution. We consume 400 billion plastic water bottles around the globe, equivalent to 20,000 bottles per second. Only 9% of all plastic gets recycled, while the remaining 91% is dumped in landfills or leaches into our oceans.
                                        <br />
                                        <br />
                                        Single-use plastic bottle pollution has been a hot topic of conversation in recent years. Plastic water bottles are among the most wasteful products we use. Each plastic water bottle requires considerable resources and energy to manufacture. Disposing of them presents a whole new set of problems for the environment.
                                        <br />
                                        <br />
                                        Plastics will not be completely phased out of our lives, specifically plastic water bottle items.
                                        <br />
                                        <br />
                                        Let us have a look at the life cycle of a plastic water bottle.
                                        <br />
                                        <br />
                                        <p className="font-weight-bolder font-italic" style={{ fontSize: "22px" }}>
                                            The stages of a plastic bottle's life
                                        </p>
                                        <br />
                                        <div style={{ fontSize: "20px" }}>
                                            The plastic bottle has to travel from one location to another multiple times during this process.
                                            <br />
                                            <br />
                                            <strong>→ Manufacturing:</strong> The initial step involves the extraction of crude oil from the earth. Crude oil is essential in this manufacturing process as polyethylene terephthalate (PET) is the main component in producing plastics.
                                            <br />
                                            <br />
                                            <strong>→ Processing:</strong> This crude oil goes to a refinery where the gas and oil are chemically bonded, with the resulting molecules creating monomers. The monomers are further broken down and fused into polyethylene terephthalate or PET. PET is the polymer that comprises most of these plastic water bottles. The final products are tiny pellets. The pellets undergo a process of melting and are later injected into moulds to fashion the shape and design of the plastic bottle.
                                            <br />
                                            <br />
                                            <strong>→ Distribution:</strong> TThe factory ships out these finished bottles to bottling plants, which fill the bottles with water, label them, package them, and eventually deliver them to stores for consumers.
                                            <br />
                                            <br />
                                            <strong>→ Consumption:</strong> The plastic water bottles are fresh from the bottling company, which then ships them to the grocery store, vendors, and other places. These are stations or venues where the water is sold or given away, making it available for buying and drinking.
                                            <br />
                                            <br />
                                            <strong>→ Disposal:</strong> After consumption of water , the plastic water bottle is then thrown in the garbage or, in rare cases, recycled. Unfortunately, water bottles go to the landfill (73-90% of them). They take up to 500-1000 years before decomposing.
                                            <br />
                                            <br />
                                            <strong>→ Recycling:</strong> When plastic water bottles are not recycled correctly and tossed into landfills, the aftermath affects our environment in more ways than one. However, you can take action today by reducing plastic waste and recycling your plastic water bottles.
                                        </div>
                                        <br />
                                        <br />
                                        <p className="font-weight-bolder font-italic" style={{ fontSize: "22px" }}>
                                            What Happens to Recycled Bottles?
                                        </p>
                                        <br />
                                        <div style={{ fontSize: "20px" }}>
                                            The following steps guide you to how this recycling process works.
                                            <br />
                                            <br />
                                            → All recyclable materials are placed on a disc screen for sorting and separation. Plastic water bottles are divided based on type and category.
                                            <br />
                                            <br />
                                            → The sorted plastic bottles are then collected into a large bale and transported to a different facility. This facility primarily deals with shredding, sorting, washing, and heating these plastic water bottles to create a resin.
                                            <br />
                                            <br />
                                            → The final resin is eventually ground into pellets to create more material for plastic products.
                                        </div>
                                        <br />
                                        <br />
                                        Recycling plastic bottles is a significant step towards using less crude oil, a finite resource. Therefore, while recycling plastic may be a bit complex, it is still a worthwhile endeavour until we come up with a better solution.
                                        <br />
                                        <br />
                                        Recycling a tonne of plastic bottle saves <strong>5,774 kWh</strong> of energy, <strong>685 gallons</strong> of oil and <strong>30 yards</strong> of landfill space.
                                        <br />
                                        <br />
                                        Recycling Plastic Water Bottles maintains the viability of resources used in the production of plastics and saves money as the cost of manufacturing and processing falls significantly. It reduces the amount of energy consumed, thus resulting in lesser GHG emissions. It also creates jobs for people in the collection, recycling, and processing industries.
                                        <br />
                                        <br />
                                        <p className="font-weight-bolder text-center pt-5">
                                            Ecowrap took the initiative of segregating waste at the source with HORECA. We have, as of now, diverted more than five metric tonnes of plastic bottles from landfills to recycling units.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginBottom: "30px" }}>
                                <a className="App-link" target="_blank" rel="noopener noreferrer"
                                    href="https://www.linkedin.com/shareArticle?mini=true&url=http://ecowrap.in/blogdetails">
                                    <img style={{ width: 32, height: 32 }} src="assets/images/blog/linkedin.png" alt="linkedin" />
                                </a>
                                &nbsp; &nbsp;
                                <a className="App-link" target="_blank" rel="noopener noreferrer"
                                    href="http://www.twitter.com/share?url=http://ecowrap.in/blogdetails">
                                    <img style={{ width: 32, height: 32 }} src="assets/images/blog/twitter.png" alt="twitter" />
                                </a>
                                &nbsp; &nbsp;
                                <a className="App-link" target="_blank" rel="noopener noreferrer"
                                    href="https://www.facebook.com/sharer/sharer.php?u=http://ecowrap.in/blogdetails">
                                    <img style={{ width: 32, height: 32 }} src="assets/images/blog/facebook.png" alt="facebook" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BlogDetailsTrending;