import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const RateList = ({ apiDashboardUrl, title }) => {

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    const getCategoryList = async () => {
        try {
            await axios.get(`${apiDashboardUrl}/website/rateList`).then((res) => {
                if (res.data.status === true) {
                    setCategoryList(res.data.data.data);
                    setSelectedCategory(res.data.data.data[0].CategoryId);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getCategoryList();
        document.title = `Rate List - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                <div className="app-content--inner">
                                    <div className="container">
                                        <div className="row">
                                            <div id="impact-box-head">
                                                <h5 className="display-2">Category</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row margin-auto">
                                            {
                                                !categoryList ? "" : categoryList.map((element, index) => {
                                                    return (
                                                        <div className="col-md-3" key={index} onClick={() => { setSelectedIndex(index); setSelectedCategory(element.CategoryId) }}>
                                                            <div className={`card mt-3 ${selectedCategory === element.CategoryId ? "activated-rate" : null}`} id="rate-top-box">
                                                                <img className="img-fluid text-center product-image-pricinglist" src={`${apiDashboardUrl}/rateListCategory/${categoryList[index].CategoryImage}`} alt="ecowrap-pricinglist" />
                                                            </div>
                                                            <div className={`card mt-2 ${selectedCategory === element.CategoryId ? "activated-rate" : null}`}>
                                                                <div className="font-size-lg text-center text-black">
                                                                    {categoryList[index].CategoryName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="pt-3 pb-5">
                                                    {categoryList.length === 0 ? (<div></div>) : <div className="home-page-headings">{categoryList[selectedIndex].CategoryName}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        {
                                            categoryList.length === 0 ? <div></div> : categoryList[selectedIndex].SubCategory.map((element, index) => {
                                                return (
                                                    <div className="align-items-center" key={index}>
                                                        <div className="card-pricinglist">
                                                            <div className="row">
                                                                <span className="col-md-3">
                                                                    <img className="img-fluid product-image-pricinglist"
                                                                        src={`${apiDashboardUrl}/rateListCategory/${element[2]}`} alt="ecowrap-pricinglist" />
                                                                </span>
                                                                <span className="col-md-9">
                                                                    <div className="container card-title font-size-xxl font-weight-bold mt-3 color-356859">
                                                                        {element[0]}
                                                                    </div>
                                                                    <div className="container font-size-lg color-000000">
                                                                        {element[1]}
                                                                    </div>
                                                                    <div className="container text-right font-size-lg font-weight-bold color-green">
                                                                        ₹{element[3]}{"/"}{element[4]}
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <br />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default RateList;