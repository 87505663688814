import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Spinner from "../../components/Spinner";

const Blocked = ({ title }) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        document.title = `Property Rejected - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="container-fluid main_header">
                        <div className="rows">
                            <div className="col-md-10 col-12 mx-auto">
                                <div className="rows">
                                    <div className="col-md-12 col-12 main_header_left align-items-center">
                                        <figure className="errimg">
                                            <img className="img-fluid" src="assets/images/blocked.gif" style={{ width: "100%" }} alt="blocked-response" />
                                        </figure>
                                        <p className="text-center">Welcome to Ecowrap Website</p>
                                        <h4 className="text-center">Your property is blocked</h4>
                                        <h5 className="text-center">Please, contact to Admin</h5>
                                        <NavLink className="pt-3" to="/"><button>Go to home</button></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Blocked;