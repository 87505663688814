import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const DeactiveOtp = ({ apiUserUrl, title }) => {

    const { addPropertyAndAddressData, addPersonalInformationData } = useLocation().state;
    const navigate = useNavigate();

    const [phoneNumber, setPhoneNumber] = useState(addPersonalInformationData?.number);
    const [message, setMessage] = useState("");
    const [description, setDescription] = useState(() => {
        const storedData = secureLocalStorage.getItem("description");
        return storedData ? JSON.parse(storedData) : {
            reason: "",
            by: "self"
        }
    });
    const refOpen = useRef(null);
    const refClose = useRef(null);

    const deactiveOtp = async (e) => {
        try {
            e.preventDefault();
            const response = await fetch(`${apiUserUrl}/deactiveOTP`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    phoneNumber: phoneNumber
                })
            });

            const data = await response.json();
            if (data.status === true) {
                setMessage(data.message);
                secureLocalStorage.setItem("phoneNumber", phoneNumber);
                secureLocalStorage.setItem("hash", data.data[0].PropertyName);
                navigate("/verify-deactive-otp", { state: { addPropertyAndAddressData, addPersonalInformationData, description } })
            } else {
                setMessage(data.message);
            }
        } catch (error) {
            setMessage(error);
        }
    };

    var regex = /[0-9]|null/;

    const handler = (e) => {
        if (!regex.test(e.nativeEvent.data)) return;
        const inputValue = e.target.value;
        if (inputValue.length <= 10) setPhoneNumber(e.target.value);
    };

    const errorHandler = () => {
        return phoneNumber && phoneNumber[0] < 6 ? "Enter a valid phone number" : "";
    };

    const toggleAuditReport = () => refOpen.current.click();

    const handleDescription = (e) => {
        e.preventDefault();
        refClose.current.click();
    };

    const inputOnChange = (e) => {
        const { name, value } = e.target;
        setDescription((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    useEffect(() => {
        if (description.reason.length === 0) {
            toggleAuditReport();
        }
        document.title = `Deactive OTP - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [description.reason.length]);

    useEffect(() => {
        secureLocalStorage.setItem("description", JSON.stringify(description));
    }, [description]);

    return (
        <>
            <button ref={refOpen} type="button" className="btn btn-dark d-none" data-bs-toggle="modal" data-bs-target="#exampleModal"></button>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Reason for deactive account</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={refClose}></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="reason" className="col-form-label">Reason</label>
                                    <select className="form-select" id="reason" name="reason" value={description.reason} onChange={inputOnChange} autoComplete="off" required>
                                        <option value="" defaultValue>---Select---</option>
                                        <option value="Joining a new property">
                                            Joining a new property
                                        </option>
                                        <option value="Previous property has been closed">
                                            Previous property has been closed
                                        </option>
                                    </select>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-pickupschedule" onClick={handleDescription}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-md-center pt-5">
                    <div className="col-md-6 text-center pt-5">
                        <div className="contact-form">
                            <form>
                                <div className="form-row justify-content-md-center text-center">
                                    <div className="form-group col-md-6">
                                        <div className="navbar-brand navbar-brands pt-5">
                                            <img className="navbar-logo" src="assets/images/logo/ecowrap-logo.png" alt="Ecowrap logo" />
                                        </div>
                                        <h5 className="verify-h5">Deactive old account</h5>
                                        <h6 className="verify-h6">Enter the 10 digit mobile number</h6>
                                        <input
                                            type="text"
                                            className="form-control justify-content-md-center text-center"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={handler}
                                            placeholder="Enter the 10 digit mobile number"
                                            autoComplete="off"
                                            title="10 digit phone number"
                                            pattern="[0-9]{10}"
                                            required
                                        />
                                    </div>
                                </div>
                                {errorHandler() === "Enter a valid phone number" ? (
                                    <div className="text-center text-danger password-error mb-3">{errorHandler()}</div>
                                ) : message ? (
                                    <div className="text-center text-warning mb-3">{message}</div>
                                ) : null}
                                <div className="text-center">
                                    <button type="button" className="btn btn-sm btn-light custom-btn" id="verification" onClick={deactiveOtp}>
                                        Send OTP
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img className="mobile-verify-img" src="assets/images/auth/mobile-image.png" alt="Mobile verification images" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeactiveOtp;