import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const PickupHistoryDetails = ({ title, userId }) => {

    const [loading, setLoading] = useState(false);
    const location = useLocation().state;
    const navigate = useNavigate()


    // console.log('userId', userId);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        document.title = `Pickup History Details - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);


    if (!userId) {
        navigate('/send-otp')
        return
    }
    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                <div className="app-content--inner">
                                    <div className="row">
                                        <div className="col-md-12 col-xl-12">
                                            <div className="card card-box mb-5">
                                                <div className="card-header">
                                                    <h5 className="my-3">
                                                        <b>Pickup Details</b>
                                                    </h5>
                                                </div>
                                                <div className="divider"></div>
                                                <div className="card-body">
                                                    <div className="container">
                                                        <div className="card p-3 m-2">
                                                            <div className="card-text">
                                                                <div>Property Name : {location.PropertyName}</div>
                                                                <div>Status : {location.PickupStatus === "0" ? <span className="badge badge-warning">Under verification</span> : location.PickupStatus === "1" ? <span className="badge badge-danger">Rejected</span> : location.PickupStatus === "2" ? <span className="badge badge-primary">Booked</span> : location.PickupStatus === "3" ? <span className="badge badge-info">Started</span> : location.PickupStatus === "4" ? <span className="badge badge-dark">Reached</span> : location.PickupStatus === "5" ? <span className="badge badge-success">Completed</span> : location.PickupStatus === "6" ? <span className="badge badge-danger">Cancelled</span> : location.PickupStatus === "7" ? <span className="badge badge-warning">Empty Pickup</span> : location.PickupStatus === "8" ? <span className="badge badge-second">Unsegregated Waste</span> : "Not yet updated"}
                                                                </div>
                                                                {
                                                                    location.PickupStatus === "5" || "7" || "8" ? <div> Driver Name : {location.DriverName} </div> : <div></div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="card p-2 m-2">
                                                            <div className="card-text p-2">
                                                                <div>
                                                                    Schedule Time : {moment(location.ScheduleTime).format("DD-MM-YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}
                                                                </div>
                                                                <div>
                                                                    Pickup Time: {moment(location.PickupTime).format("DD-MM-YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card p-3 m-2">
                                                            <div className="card-title">
                                                                <div className="ml-2">List of collected waste</div>
                                                                <br />
                                                                <table className="table table-borderless text-center">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Product</th>
                                                                            <th scope="col">weight</th>
                                                                            <th scope="col">Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th>Amount</th>
                                                                            <th>{location.TotalWeigth}</th>
                                                                            <th>₹ {location.TotalAmount}</th>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="card p-3 m-2">
                                                            <div className="card-title">
                                                                <div className="d-flex justify-content-between">
                                                                    <div>Saved total emission</div>
                                                                    <div className="card-text">{location.TotalEmission}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default PickupHistoryDetails;