import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {

    const [show, setShow] = useState(true);

    return (
        <>
            <header className="header">
                <nav className="navbar navbar-expand-lg fixed-top py-3">
                    <div className="container-fluid">
                        <NavLink className="navbar-brand text-uppercase font-weight-bold" to="/">
                            <img className="navbar-logo" src="assets/images/logo/ecowrap-logo.png" alt="ecowrap-logo" />
                        </NavLink>
                        <button type="button" className="navbar-toggler navbar-toggler-right" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShow(prev => !prev)}>
                            <i className="fa fa-bars"></i>
                        </button>
                        {
                            show && <div className="collapse navbar-collapse">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <NavLink className="nav-link font-weight-bold" to="/">
                                            Home
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link font-weight-bold" to="/blogs">
                                            Blogs
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link font-weight-bold" to="/clients-and-partners">
                                            Clients and Partners
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <a href="https://www.bhayli.co.in/" className="nav-link font-weight-bold" target="_blank" rel="noopener noreferrer">
                                            Bhayli
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link font-weight-bold" to="/about-us">
                                            About Us
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link font-weight-bold" to="/contact-us">
                                            Contact Us
                                        </NavLink>
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink className="nav-link font-weight-bold" to="/competition">
                                            Competition
                                        </NavLink>
                                    </li> */}
                                    <li className="nav-item mb-2">
                                        <NavLink className="nav-link font-weight-bold" to="/incentive-list" id="nav-ratelist">
                                            Incentive List
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Navbar;