import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const CheckRegistrationOtp = ({ apiUserUrl, title }) => {

    const { addPropertyAndAddressData, addPersonalInformationData } = useLocation().state;
    const navigate = useNavigate();

    const [phoneNumber, setPhoneNumber] = useState(addPersonalInformationData?.number);
    const [statusCode, setStatusCode] = useState(null);
    const [message, setMessage] = useState("");
    const [registerdUser, setRegisterdUser] = useState([]);

    const mobileVerification = async (e) => {
        try {
            e.preventDefault();
            const response = await fetch(`${apiUserUrl}/registrationOTP`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    phoneNumber: phoneNumber
                })
            });

            const data = await response.json();
            if (data.statusCode === 200) {
                setStatusCode(data.statusCode);
                setMessage(data.message);
                secureLocalStorage.setItem("phoneNumber", phoneNumber);
                secureLocalStorage.setItem("hash", data.data[0].PropertyName);
            } else if (data.statusCode === 409) {
                setStatusCode(data.statusCode);
                setMessage(data.message);
                setRegisterdUser(data.data);
            } else {
                setMessage(data.message);
            }
        } catch (error) {
            setMessage(error);
        }
    };

    var regex = /[0-9]|null/;

    const handler = (e) => {
        if (!regex.test(e.nativeEvent.data)) return;
        const inputValue = e.target.value;
        if (inputValue.length <= 10) setPhoneNumber(e.target.value);
    };

    const errorHandler = () => {
        return phoneNumber && phoneNumber.length < 6 ? "Enter a valid phone number" : "";
    };

    useEffect(() => {
        if (statusCode === 200) {
            navigate("/verify-registration-otp", { state: { addPersonalInformationData, addPropertyAndAddressData } });
        } else if (statusCode === 409) {
            navigate("/check-registered-property", { state: { message, registerdUser, addPersonalInformationData, addPropertyAndAddressData } });
        }
        document.title = `Registration OTP - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [message, registerdUser, statusCode, addPersonalInformationData, addPropertyAndAddressData, navigate]);

    return (
        <>
            <div className="container">
                <div className="row justify-content-md-center pt-5">
                    <div className="col-md-6 text-center pt-5">
                        <div className="contact-form">
                            <form>
                                <div className="form-row justify-content-md-center text-center">
                                    <div className="form-group col-md-6">
                                        <div className="navbar-brand navbar-brands pt-5">
                                            <img className="navbar-logo" src="assets/images/logo/ecowrap-logo.png" alt="ecowrap-logo" />
                                        </div>
                                        <h5 className="verify-h5">Register New Account</h5>
                                        <h6 className="verify-h6">Enter the 10 digit mobile number</h6>
                                        <input
                                            type="text"
                                            className="form-control justify-content-md-center text-center"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            // onChange={(e) => { handler(e) }}
                                            placeholder="Enter the 10 digit mobile number"
                                            autoComplete="off"
                                            title="10 digit phone number"
                                            pattern="[0-9]{10}"
                                            required
                                        />
                                    </div>
                                </div>
                                {errorHandler() && <div className="text-center text-danger password-error mb-3">{errorHandler()}</div>}
                                {message && <div className="text-center text-warning mb-3">{message}</div>}
                                <div className="text-center">
                                    <button type="button" className="btn btn-sm btn-light custom-btn" id="verification" onClick={mobileVerification}>
                                        Send OTP
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img className="mobile-verify-img" src="assets/images/auth/mobile-image.png" alt="Mobile verify images" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckRegistrationOtp;