import React, { useState, useRef } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import swal from "sweetalert";
import Mess from "../Mess";

const NavbarForDashboard = () => {

    const apiDashboardUrl = process.env.REACT_APP_DASHBOARD_API;
    const userId = secureLocalStorage.getItem("UserId");

    const [isOpen, setIsopen] = useState(false);
    const [show, setShow] = useState(false);
    const [showDocuments, setShowDocuments] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const refOpenAuditReport = useRef(null);
    const refCloseAuditReport = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const toggleAuditReport = () => refOpenAuditReport.current.click();

    var currentMonth = moment().format("YYYY/MM/DD");
    var lastMonth = moment().subtract(30, "days").format("YYYY/MM/DD");
    var threeMonth = moment().subtract(90, "days").format("YYYY/MM/DD");
    var sixMonth = moment().subtract(180, "days").format("YYYY/MM/DD");
    var oneYear = moment().subtract(365, "days").format("YYYY/MM/DD");

    const ToggleSidebar = () => isOpen === true ? setIsopen(false) : setIsopen(true);

    const logout = () => {
        swal("Success", "Logout Succesfully!", "success").then((ok) => {
            if (ok) {
                secureLocalStorage.clear();
                navigate("/");
            }
        })
    };

    return (
        <>
            <Mess />

            <button ref={refOpenAuditReport} type="button" className="btn btn-dark d-none" data-bs-toggle="modal" data-bs-target="#auditReportModal"></button>

            <div className="modal fade" id="auditReportModal" tabIndex="-1" aria-labelledby="auditReportModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="auditReportModalLabel">
                                Choose your option
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="mb-2 col-md-6">
                                    <a href={`${apiDashboardUrl}/user/appViewAuditReport/${userId}?startDate=${lastMonth}&endDate=${currentMonth}`}>
                                        <div
                                            id="audit-report"
                                            className={`cp mt-1 mr-2`}>
                                            Last one month
                                        </div>
                                    </a>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <a href={`${apiDashboardUrl}/user/appViewAuditReport/${userId}?startDate=${threeMonth}&endDate=${currentMonth}`}>
                                        <div
                                            id="audit-report"
                                            className={`cp mt-1 mr-2`}>
                                            Last three month
                                        </div>
                                    </a>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <a href={`${apiDashboardUrl}/user/appViewAuditReport/${userId}?startDate=${sixMonth}&endDate=${currentMonth}`}>
                                        <div
                                            id="audit-report"
                                            className={`cp mt-1 mr-2`}>
                                            Last six month
                                        </div>
                                    </a>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <a href={`${apiDashboardUrl}/user/appViewAuditReport/${userId}?startDate=${oneYear}&endDate=${currentMonth}`}>
                                        <div
                                            id="audit-report"
                                            className={`cp mt-1 mr-2`}>
                                            Last one year
                                        </div>
                                    </a>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <div
                                        onClick={() => setShow(prev => !prev)}
                                        id="audit-report"
                                        className={`cp mt-1 mr-2`}>
                                        Custom
                                    </div>
                                    {
                                        show && <div className="row">
                                            <div className="mb-2">
                                                <label htmlFor="startDate" className="font-size-md form-label br-2rem ml-2">
                                                    Start Date
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control ml-2"
                                                    id="audit-report"
                                                    name="startDate"
                                                    value={startDate}
                                                    onChange={(e) => { setStartDate(e.target.value) }}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="endDate" className="font-size-md form-label br-2rem ml-2">
                                                    End Date
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control ml-2"
                                                    id="audit-report"
                                                    name="endDate"
                                                    placeholder="enddats"
                                                    value={endDate}
                                                    onChange={(e) => { setEndDate(e.target.value) }}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <a href={`${apiDashboardUrl}/user/appViewAuditReport/${userId}?startDate=${startDate}&endDate=${endDate}`}>
                                                    {
                                                        startDate === "" || endDate === "" ? <div></div> : <button
                                                            type="button"
                                                            className="btn btn-pickupschedule">
                                                            Send
                                                        </button>
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-pickupschedule"
                                data-bs-dismiss="modal"
                                ref={refCloseAuditReport}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="app-header">
                <div className="d-flex">
                    <div className="container-fluid p-1">
                        <div className="form-inline ml-auto mobile">
                            <div className="btn" onClick={ToggleSidebar}>
                                <i className="fa fa-bars"></i>
                            </div>
                        </div>
                        <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
                            <div className="sd-header">
                                <h4 className="mb-0"> </h4>
                                <div className="btn" onClick={ToggleSidebar}><i className="fa fa-times"></i></div>
                            </div>
                            <div className="sd-body">
                                <div className="sidebar-navigation">
                                    <ul>
                                        <li className="left-nav-list-logo">
                                            <a href="/dashboard" aria-expanded="true">
                                                <img className="navbar-logo1" src="assets/images/logo/ecowrap-logo.png" alt="ecowrap-logo" />
                                            </a>
                                        </li>
                                        <li className="left-nav-list-item">
                                            <a href="/dashboard" aria-expanded="true">
                                                <span>
                                                    <i className="fas fa-tachometer-alt" id="leftnavbar-components-id"></i>
                                                    <span className="leftnavbar-components">Dashboard</span>
                                                </span>
                                                <i className="fas fa-chevron-right"></i>
                                            </a>
                                        </li>
                                        <li className="left-nav-list-item">
                                            <a href="/apply-dustbins">
                                                <span>
                                                    <i className="fas fa-trash" id="leftnavbar-components-id"></i>
                                                    <span className="leftnavbar-components">
                                                        Dustbins
                                                    </span>
                                                </span>
                                                <i className="fas fa-chevron-right"></i>
                                            </a>
                                        </li>
                                        <li className="left-nav-list-item">
                                            <a href="/wallet">
                                                <span>
                                                    <i className="fas fa-wallet" id="leftnavbar-components-id"></i>
                                                    <span className="leftnavbar-components">Wallet</span>
                                                </span>
                                                <i className="fas fa-chevron-right"></i>
                                            </a>
                                        </li>
                                        <li className="left-nav-list-item">
                                            <a data-toggle="collapse" href="#documents" aria-expanded="false" aria-controls="ui-basic" onClick={() => setShowDocuments(prev => !prev)}>
                                                <span>
                                                    <i className="fas fa-id-card" id="leftnavbar-components-id"></i>
                                                    <span className="leftnavbar-components">Documents</span>
                                                </span>
                                                <i className="fa fa-chevron-right"></i>
                                            </a>
                                            {
                                                showDocuments && <div className="collapse" id="documents">
                                                    <ul>
                                                        <li className="left-nav-list-item">
                                                            <a className="left-nav-sub-history" href={`${apiDashboardUrl}/user/viewPerformaInvoice/${userId}`}>
                                                                Performa Invoice
                                                            </a>
                                                        </li>
                                                        <li className="left-nav-list-item">
                                                            <a className="left-nav-sub-history" href={`${apiDashboardUrl}/user/viewMouDocument/${userId}`}>
                                                                MOU Document
                                                            </a>
                                                        </li>
                                                        <li className="left-nav-list-item">
                                                            <div>
                                                                {/* eslint-disable-next-line */}
                                                                <a className="cp left-nav-sub-history" onClick={() => { toggleAuditReport() }}>
                                                                    Audit Report
                                                                </a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                        </li>
                                        <li className="left-nav-list-item">
                                            <a href="#history" data-toggle="collapse" aria-expanded="false" aria-controls="ui-basic" onClick={() => setShowHistory(prev => !prev)}>
                                                <span>
                                                    <i className="fas fa-history" id="leftnavbar-components-id"></i>
                                                    <span className="leftnavbar-components">History</span>
                                                </span>
                                                <i className="fa fa-chevron-right"></i>
                                            </a>
                                            {
                                                showHistory && <div className="collapse" id="history">
                                                    <ul>
                                                        <li className="left-nav-list-item">
                                                            <a className="left-nav-sub-history" href="/wallet-history">
                                                                Wallet History
                                                            </a>
                                                        </li>
                                                        <li className="left-nav-list-item">
                                                            <a className="left-nav-sub-history" href="/pickup-history">
                                                                Pickup History
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                        </li>
                                        <li className="left-nav-list-item">
                                            <a href="/rate-list">
                                                <span>
                                                    <i className="fas fa-rupee" id="leftnavbar-components-id"></i>
                                                    <span className="leftnavbar-components">Rate List</span>
                                                </span>
                                                <i className="fas fa-chevron-right"></i>
                                            </a>
                                        </li>
                                        <li className="left-nav-list-item">
                                            <a href="/help-and-support">
                                                <span>
                                                    <i className="fas fa-headset" id="leftnavbar-components-id"></i>
                                                    <span className="leftnavbar-components">
                                                        Help & Support
                                                    </span>
                                                </span>
                                                <i className="fas fa-chevron-right"></i>
                                            </a>
                                        </li>
                                        <li className="left-nav-list-item">
                                            <a href="#!" onClick={() => logout()}>
                                                <span>
                                                    <i className="fa fa-sign-out" id="leftnavbar-components-id"></i>
                                                    <span className="leftnavbar-components"> &nbsp; &nbsp; Logout</span>
                                                </span>
                                                <i className="fas fa-chevron-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={`sidebar-overlay ${isOpen === true ? "active" : ""}`} onClick={ToggleSidebar}></div>
                    </div>
                </div>
                <div className="d-none d-lg-flex">
                    <div className="nav-dashboard-card">
                        <div className="pt-0 row text-center">
                            <div className="col-md-6">
                                <div className="nav-dashboard-sub-card cp" onClick={() => { navigate("/dashboard") }}>
                                    {
                                        location.pathname === "/dashboard" ? <NavLink to="/dashboard">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/wallet" ? <NavLink to="/wallet">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/wallet-history" ? <NavLink to="/wallet-history">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/impact" ? <NavLink to="/impact">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/howToEarn" ? <NavLink to="/howToEarn">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/current-pickup-details" ? <NavLink to="/current-pickup-details">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/schedule-pickup" ? <NavLink to="/schedule-pickup">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/confirmPickup" ? <NavLink to="/confirmPickup">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/rate-list" ? <NavLink to="/rate-list">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/pickup-history" ? <NavLink to="/pickup-history">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/pickup-history-details" ? <NavLink to="/pickup-history-details">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/apply-dustbins" ? <NavLink to="/apply-dustbins">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/savedAddress" ? <NavLink to="/savedAddress">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/add-address" ? <NavLink to="/add-address">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/help-and-support" ? <NavLink to="/help-and-support">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : location.pathname === "/profile" ? <NavLink to="/profile">
                                            <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                        </NavLink> : <div className="nav-dashboard-sub-card-text">Ecowrap</div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="nav-dashboard-sub-card">
                                    <NavLink to="/shop">
                                        <div className="nav-dashboard-sub-card-text">Shop</div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="container-fluid p-2">
                        <div className="form-inline mr-auto">
                            <button type="button" className="btn bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-node d-44 position-relative">
                                <a href="/profile">
                                    <i className="fas fa-user-alt text-success"></i>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavbarForDashboard;