const review = [
    {
        rating: 4,
        content:
            "The service is good. The team responsible for waste collection is humble and also shows up on time. The complaints placed are positively resolved. Ecowrap also provides us with an audit report for our waste.",
        name: "Ravi Sharma (Manager)",
        establishment: "Hotel Kashvi",
        image: "assets/images/datareview/hotel-kashvi.png",
    },
    {
        rating: 4.2,
        content:
            "The dustbin placement was on time and free of cost. The waste pickup team also comes as per schedule. Immediately after scheduling the pickup, the waste bins get emptied.",
        name: "Satyendra Chaudhary (Supervisor)",
        establishment: "Route 99 Bar & Kitchen",
        image: "assets/images/datareview/route99.png",
    },
    {
        rating: 4.8,
        content:
            "The app is good. The team assigned for waste collection is professional and disciplined. A company where employees work together to solve environmental issues by recycling.",
        name: "Ravindra Singh (Supervisor)",
        establishment: "Bombay House",
        image: "assets/images/datareview/bombay-house.png",
    },
    {
        rating: 4.5,
        content:
            "It is easy to schedule a pickup, and the waste pickup crew also shows up on time. The waste gets collected without any discrepancies. Team Ecowrap inspires us to make our property dumping-free.",
        name: "Vinod Binchar (Manager)",
        establishment: "After 7 Restro and Bar",
        image: "assets/images/datareview/after7.png",
    },
];

export default review;