import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const PrivacyPolicy = ({ title }) => {

    useEffect(() => {
        document.title = `Privacy Policy - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">INTRO</span><span className="our-investors-span2">DUCTION</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                1.1 We are committed to safeguarding the privacy of [our website
                                visitors and service users].
                                <br />
                                1.2 This policy applies where we are acting as a data controller
                                with respect to the personal data of [our website visitors and
                                service users]; in other words, where we determine the purposes
                                and means of the processing of that personal data.
                                <br />
                                1.3 We use cookies on our website. Insofar as those cookies are
                                not strictly necessary for the provision of [our website and
                                services], we will ask you to consent to our use of cookies when
                                you first visit our website.
                                <br />
                                1.4 Our website incorporates privacy controls which affect how
                                we will process your personal data. By using the privacy
                                controls, you can [specify whether you would like to receive
                                direct marketing communications and limit the publication of
                                your information]. You can access the privacy controls via
                                [URL].
                                <br />
                                1.5 In this policy, "we", "us" and "our" refer to [data
                                controller name]. [ For more information about us, see Section
                                13.]
                                <br />
                                <br />
                                ECOWRAP IMPACT PRIVATE LIMITED operates the Website Name
                                https://ecowrap.in, which provides the SERVICE.
                                <br />
                                ECOWRAP has created this privacy policy in order to demonstrate
                                our firm commitment to your privacy. The following discloses our
                                information gathering and dissemination practices relating to
                                ECOWRAPs websites (“Sites”), mobile applications, the delivery
                                of our services, and other interactions with you. Please note
                                that when you access any external links from ECOWRAPs Sites
                                (including any ECOWRAP subsidiary Sites), such external websites
                                may have different privacy policies from the Sites and ECOWRAP
                                is not responsible for the privacy practices of such external
                                websites. We encourage you to read all privacy policies posted
                                on the web sites that you visit.
                                <br />
                                This page is used to inform website visitors regarding our
                                policies with the collection, use, and disclosure of Personal
                                Information if anyone decided to use our Service, the Website
                                Name website.
                                <br />
                                If you choose to use our Service, then you agree to the
                                collection and use of information in relation with this policy.
                                The Personal Information that we collect are used for providing
                                and improving the Service. We will not use or share your
                                information with anyone except as described in this Privacy
                                Policy.
                                <br />
                                The terms used in this Privacy Policy have the same meanings as
                                in our Terms and Conditions, which is accessible at Website URL,
                                unless otherwise defined in this Privacy Policy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">INFORMATION</span> <span className="our-investors-span2">COLLECTION AND USE</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                For a better experience while using our Service, we may require
                                you to provide us with certain personally identifiable
                                information, including but not limited to your name, phone
                                number, and postal address. The information that we collect will
                                be used to contact or identify you.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">LOG</span> <span className="our-investors-span2">DATA</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                We want to inform you that whenever you visit our Service, we
                                collect information that your browser sends to us that is called
                                Log Data. This Log Data may include information such as your
                                computer's Internet Protocol (“IP”) address, browser version,
                                pages of our Service that you visit, the time and date of your
                                visit, the time spent on those pages, and other statistics.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">COO</span><span className="our-investors-span2">KIES</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                Cookies are files with small amount of data that is commonly
                                used an anonymous unique identifier. These are sent to your
                                browser from the website that you visit and are stored on your
                                computer's hard drive.
                                <br />
                                Our website uses these “cookies” to collection information and
                                to improve our Service. You have the option to either accept or
                                refuse these cookies, and know when a cookie is being sent to
                                your computer. If you choose to refuse our cookies, you may not
                                be able to use some portions of our Service.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">SERVICVE</span> <span className="our-investors-span2">PROVIDERS</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                We may employ third-party companies and individuals due to the
                                following reasons: • To facilitate our Service; • To provide the
                                Service on our behalf; • To perform Service-related services; or
                                • To assist us in analysing how our Service is used.
                                <br />
                                We want to inform our Service users that these third parties
                                have access to your Personal Information. The reason is to
                                perform the tasks assigned to them on our behalf. However, they
                                are obligated not to disclose or use the information for any
                                other purpose.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">SEC</span><span className="our-investors-span2">URITY</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                We value your trust in providing us your Personal Information,
                                thus we are striving to use commercially acceptable means of
                                protecting it. But remember that no method of transmission over
                                the internet, or method of electronic storage is 100% secure and
                                reliable, and we cannot guarantee its absolute security.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">LINKS</span> <span className="our-investors-span2">TO OTHER SITES</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                Our Service may contain links to other sites. If you click on a
                                third-party link, you will be directed to that site. Note that
                                these external sites are not operated by us.
                                <br />
                                Therefore, we strongly advise you to review the Privacy Policy
                                of these websites. We have no control over, and assume no
                                responsibility for the content, privacy policies, or practices
                                of any third-party sites or services.
                                <br />
                                Children's Privacy
                                <br />
                                Our Services do not address anyone under the age of 13. We do
                                not knowingly collect personal identifiable information from
                                children under 13. In the case we discover that a child under 13
                                has provided us with personal information, we immediately delete
                                this from our servers. If you are a parent or guardian and you
                                are aware that your child has provided us with personal
                                information, please contact us so that we will be able to do
                                necessary actions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">CHANGES</span> <span className="our-investors-span2">TO THIS PRIVACY POLICY</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                We may update our Privacy Policy from time to time. Thus, we
                                advise you to review this page periodically for any changes. We
                                will notify you of any changes by posting the new Privacy Policy
                                on this page. These changes are effective immediately, after
                                they are posted on this page.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray pb-30">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">CONTACT</span> <span className="our-investors-span2">US</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                If you have any questions or suggestions about our Privacy
                                Policy, do not hesitate to contact us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PrivacyPolicy;