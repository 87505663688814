import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const RecycleRight = ({ title }) => {

    useEffect(() => {
        AOS.init({ duration: 1000, offset: 120 });
        document.title = `Recycle Right - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <div className="row text-center projects-sub-row-2">
                <div className="col-md-7 project-recycle-img">
                    <img className="max-width-100percent" src="assets/images/projects/projects-recycle-right.png" alt="recycle-right-png" />
                </div>
                <div className="col-md-5 muskaan-projects">
                    <div className="container2">
                        <div className="projects-sub-head-muskaan"></div>
                        <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">#RECYCLE</span> <span className="our-investors-span2">RIGHT</span></div>
                        <div className="projects-sub-text text-justify" style={{ marginLeft: 15 }}>
                            India consumes millions of tonnes of aluminium foil for food packaging, but only about 25% of it is recycled. Ecowrap in association with Freshwrapp (Hindalco) is working to build an ecosystem to add used aluminium food packaging items can be brought back into the value chain.
                        </div>
                        <div className="row pt-3">
                            <div className="col-md-6 container2 text-left pd-left-100px pr-0">
                                <NavLink to="/recycle-right-details">
                                    <button className="btn btn-projects">Read More</button>
                                </NavLink>
                            </div>
                            <div className="col-md-6 pl-0">
                                <img className="muskaan-intext-image" src="assets/images/projects/muskaan-text-bottom-image.png" alt="muskaan-text-container" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default RecycleRight;