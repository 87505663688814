import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <section id="footer-down">
                <div className="footer-down-content container2">
                    <div className="row text-center">
                        <div className="col-md-6 text-center">
                            <div>
                                <img className="footer-gif" src="assets/images/footer/footer.gif" alt="footer-logo" />
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <div className="footer-down-mission">
                                <div className="footer-down-mission-statement text-left">
                                    "A blueprint to achieve a better and more sustainable future
                                    for all people and the world by 2030."
                                </div>
                                <div className="footer-down-mission-statement text-right pb-10">
                                    - SDG's Mission Statement
                                </div>
                                <img className="footer-down-mission-image" src="assets/images/footer/footer-down-mission.png" alt="footer-down-mission" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="footer">
                <div className="container2">
                    <div className="row">
                        <div className="col-sm-6 col-md-3">
                            <NavLink to="/about-us">
                                <div className="footer-col-head text-center">About Us</div>
                            </NavLink>
                            <div className="mt-5">
                                <div className="card-footer-down-text">
                                    “Ecowrap brings sustainability with source segregation culture.”
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="footer-col-head">Useful Links</div>
                            <div className="footer-col-body mt-3">
                                <ul className="list-unstyled quick-links">
                                    <li>
                                        <NavLink to="/" className="footer-links">Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/bhayli" className="footer-links">Bhayli</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/recycle-right" className="footer-links">Recycle Right</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/faq" className="footer-links">FAQ</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="footer-col-head">Quick Links</div>
                            <div className="footer-col-body mt-3">
                                <ul className="list-unstyled quick-links">
                                    <li>
                                        <NavLink to="/awards-and-media" className="footer-links">Awards & Media</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/contact-us" className="footer-links">Career & Jobs</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/incentive-list" className="footer-links">Incentive List</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <div className="footer-col-head">Contact Us</div>
                            <div className="footer-col-body mt-3">
                                <ul className="list-unstyled">
                                    <li className="footer-li" onClick={() => { window.open("tel:9269099902") }}>
                                        <div className="row footer-links">
                                            <span className="col-2"><i className="fa fa-phone rotate-phone"></i></span>
                                            <span className="col-10">+91 9269099902</span>
                                        </div>
                                    </li>
                                    <li className="footer-li">
                                        <a href="mailto:info@ecowrap.in?">
                                            <div className="row footer-links">
                                                <span className="col-2"><i className="fa fa-envelope"></i></span>
                                                <span className="col-10">info@ecowrap.in</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="footer-li">
                                        <a className="address" href="https://www.google.com/maps/@26.975899,75.3309623,10z?entry=ttu" target="_blank" rel="noopener noreferrer">
                                            <div className="row footer-links">
                                                <span className="col-2"><i className="fa fa-map-marker"></i></span>
                                                <span className="col-10">99, Kiran Vihar, Opp. Saint Angles School, Mangyawas, Jaipur, Rajasthan 302020</span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                                <div className="footer-down-bottom">
                                    <div className="container">
                                        <div className="row text-center">
                                            <div className="margin-auto">
                                                <span className="social-icon">
                                                    <a href="https://in.linkedin.com/company/ecowrap" target="_blank" rel="noopener noreferrer">
                                                        <img src="assets/images/footer/footer-down-icon2.png" style={{ width: 30, height: 30 }} alt="linkedin-icon" />
                                                    </a>
                                                </span>
                                                <span className="social-icon">
                                                    <a href="https://www.facebook.com/ecowrap.in/" target="_blank" rel="noopener noreferrer">
                                                        <img src="assets/images/footer/footer-down-icon1.png" style={{ width: 30, height: 30 }} alt="facebook-icon" />
                                                    </a>
                                                </span>
                                                <span className="social-icon">
                                                    <a href="https://www.instagram.com/ecowrap.in/?hl=en" target="_blank" rel="noopener noreferrer">
                                                        <img src="assets/images/footer/footer-down-icon4.png" style={{ width: 30, height: 30 }} alt="instagram-icon" />
                                                    </a>
                                                </span>
                                                <span className="social-icon">
                                                    <a href="https://twitter.com/ecowrap" target="_blank" rel="noopener noreferrer">
                                                        <img src="assets/images/footer/footer-down-icon3.png" style={{ width: 30, height: 30 }} alt="twitter-icon" />
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="hr2"></hr>
                <div className="container-fluid">
                    <div className="row">
                        <div className="footer-end-text col-md-6 text-left">
                            <span>©ECOWRAP (Ecowrap Impact Pvt. Ltd.) | 2018 - 2024 - All rights reserved.</span>
                        </div>
                        <div className="footer-end-text col-md-6 text-right pb-2">
                            <span className="mg-right-20">
                                <NavLink className="nav-links" to="/refund-and-cancellation-policy">
                                    Refund & Cancellation Policy
                                </NavLink>
                            </span>
                            <span className="mg-right-20">
                                <NavLink className="nav-links" to="/shipping-policy">
                                    Shipping Policy
                                </NavLink>
                            </span>
                            <span className="mg-right-20">
                                <NavLink className="nav-links" to="/privacy-policy">
                                    Privacy Policy
                                </NavLink>
                            </span>
                            <span>
                                <NavLink className="nav-links" to="/terms-and-conditions">
                                    Terms & Conditions
                                </NavLink>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;