import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const ShippingPolicy = ({ title }) => {

    useEffect(() => {
        document.title = `Shipping Policy - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">SHIPPING</span> <span className="our-investors-span2">POLICY</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                <p className="fw-normal">Thank you for choosing our Muskaan project and purchasing our upcycled products. We appreciate your support in empowering women and promoting sustainable waste management practices. Please review our shipping policy outlined below:</p>
                                <p>
                                    A. Order Processing:
                                    <p className="ms-3">
                                        i. Once you place an order, our team will process it within 2 business days.
                                        <br />
                                        ii. Please note that orders are not processed or shipped on weekends or holidays.
                                    </p>
                                </p>
                                <p>
                                    B. Shipping Methods and Timeframes:
                                    <p className="ms-3">
                                        i. We offer shipping through trusted courier services to ensure the safe and timely delivery of your products.
                                        <br />
                                        ii. The estimated shipping time frames are as follows:
                                        <p className="ms-3">
                                            a. Domestic (within India): 7 business days.
                                            <br />
                                            b. International: 10 to 30 business days (may vary based on destination).
                                        </p>
                                    </p>
                                </p>
                                <p>
                                    C. Shipping Charges:
                                    <p className="ms-3">
                                        i. Shipping charges are calculated based on the destination and weight of the products.
                                        <br />
                                        ii. The exact shipping cost will be displayed during the checkout process.
                                        <br />
                                        iii. Any applicable customs duties or taxes imposed by the destination country are the responsibility of the customer.
                                    </p>
                                </p>
                                <p>
                                    D. Order Tracking:
                                    <p className="ms-3">
                                        i. Once your order is shipped, you will receive a shipping confirmation email containing the tracking details.
                                        <br />
                                        ii. You can track the progress of your shipment using the provided tracking number and the courier's website or tracking portal.
                                    </p>
                                </p>
                                <p>
                                    E. Shipping Restrictions:
                                    <p className="ms-3">
                                        i. Please note that there may be certain restrictions on shipping specific products to certain locations due to customs regulations or other legal constraints.
                                        <br />
                                        ii. In such cases, we will notify you promptly and provide assistance in finding alternative solutions if possible.
                                    </p>
                                </p>
                                <p>
                                    F. Incorrect Shipping Address:
                                    <p className="ms-3">
                                        i. It is important to provide an accurate shipping address during checkout.
                                        <br />
                                        ii. If you realize that you have provided an incorrect or incomplete address, please contact us as soon as possible.
                                        <br />
                                        iii. We cannot guarantee that we will be able to update the shipping address, but we will do our best to assist you.
                                    </p>
                                </p>
                                <p>
                                    G. Address Changes during Shipping:
                                    <p className="ms-3">
                                        i. We understand that unforeseen circumstances may require a change in the shipping address after the order has been placed.
                                        <br />
                                        ii. Contact us as soon as possible with the updated address details.
                                        <br />
                                        iii. Note that we cannot guarantee the address change, especially if the shipment is already in transit, but we will assist you to the best of our abilities.
                                    </p>
                                </p>
                                <p>
                                    G. Delayed or Lost Shipments:
                                    <p className="ms-3">
                                        i. While we strive for timely delivery, please note that shipping delays may occur due to unforeseen circumstances or factors beyond our control, such as weather conditions or customs delays.
                                        <br />
                                        ii. If your shipment is significantly delayed or lost, please reach out to our customer support team, and we will work with the courier to resolve the issue and provide a suitable solution.
                                    </p>
                                </p>
                                <p>
                                    H. Damaged or Lost Shipments:
                                    <p className="ms-3">
                                        i. In the unfortunate event that your shipment arrives damaged or is lost during transit, please contact us immediately.
                                        <br />
                                        ii. For damaged shipments, provide photographic evidence of the damage, including the packaging.
                                        <br />
                                        iii. We will work with the courier to file a claim and arrange for a replacement or refund, depending on product availability.
                                    </p>
                                </p>
                                <p>
                                    I. International Shipping:
                                    <p className="ms-3">
                                        i. For international shipments, please be aware that customs regulations and import duties may apply.
                                        <br />
                                        ii. Any additional charges or fees imposed by customs authorities are the responsibility of the customer.
                                        <br />
                                        iii. Delays may occur due to customs inspections or other international shipping processes beyond our control.
                                    </p>
                                </p>
                                <p>
                                    J. Multiple Shipments:
                                    <p className="ms-3">
                                        i. If your order includes multiple items, they may be shipped separately and arrive on different dates.
                                        <br />
                                        ii. This is to ensure efficient and timely delivery, especially for products sourced from different locations.
                                    </p>
                                </p>
                                <p>
                                    K. Pre-Order and Backordered Items:
                                    <p className="ms-3">
                                        i. In the case of pre-order or backordered items, please refer to the estimated shipping time frame mentioned on the product page.
                                        <br />
                                        ii. The entire order will be shipped once all items are available, unless otherwise specified or requested by the customer.
                                    </p>
                                </p>
                                <p className="fw-normal">If you have any questions or concerns regarding our shipping policy, please feel free to contact our customer support team at +919269099902 or email us at support@ecowrap.in. We are here to assist you and ensure a smooth and satisfactory shipping experience.</p>
                                <p className="fw-normal">Thank you for your support and being a part of our mission.</p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ShippingPolicy;