import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(...registerables);

const BarChart = (props) => {

    const { apiDashboardUrl, userId } = props;
    const [txnData, setTxnData] = useState();

    const getTxnData = async () => {
        try {
            await axios.get(`${apiDashboardUrl}/user/walletHistory?userId=${userId}&page=1`).then((res) => {
                if (res.data.status === "success") {
                    setTxnData(res.data.data.reverse());
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    const userData = {
        labels: !txnData ? "" : txnData.map((element) => moment(element.Time).format("DD-MM")), datasets: [
            {
                label: "Amount",
                data: !txnData ? "" : txnData.map((element) => element.Amount),
                backgroundColor: ["#ecf0f1"],
                borderColor: "rgb(255, 99, 132)",
                borderWidth: 2
            }
        ]
    };

    const options = {
        animations: {
            tension: {
                duration: 2000,
                from: 1,
                to: 0,
                loop: true
            }
        }
    };

    useEffect(() => {
        getTxnData();
        // eslint-disable-next-line 
    }, []);

    return (
        <>
            <Line data={userData} options={options} />
        </>
    )
}

export default BarChart;