import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const CurrentPickupDetail = ({ apiDashboardUrl, userId, title }) => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation().state;


    const cancelPickup = async () => {
        try {
            await axios.get(`${apiDashboardUrl}/user/cancelPickup?userId=${userId}&curPickupId=${location.CurrentPickupId}`).then((res) => {
                if (res.data.status === "success") {
                    swal("Success", res.data.message, "success").then((ok) => {
                        if (ok)
                            navigate("/dashboard");
                    })
                } else {
                    swal("Error", res.data.message, "error")
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        document.title = `Current Pickup Details - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    if (!userId) {
        navigate('/send-otp')
        return
    }


    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                <div className="app-content--inner">
                                    <div className="row">
                                        <div className="col-md-12 col-xl-12">
                                            <div className="card card-box mb-5">
                                                <div className="card-header">
                                                    <h5 className="my-3"><b>Current Pickup Details</b></h5>
                                                </div>
                                                <div className="divider"></div>
                                                <div className="card-body">
                                                    <div className="container">
                                                        <div className="card p-3 m-2">
                                                            <div className="card-title">
                                                                <div className="ml-2">Property Details</div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div>Property Name : {location.PropertyName}</div>
                                                                <div>Status : {location.PickupStatus === "0" ? <span className="badge badge-warning">Under Processing</span> : location.PickupStatus === "1" ? <span className="badge badge-danger">Rejected</span> : location.PickupStatus === "2" ? <span className="badge badge-primary">Booked</span> : location.PickupStatus === "3" ? <span className="badge badge-info">Started</span> : location.PickupStatus === "4" ? <span className="badge badge-dark">Reached</span> : location.PickupStatus === "5" ? <span className="badge badge-success">Completed</span> : location.PickupStatus === "6" ? <span className="badge badge-danger">Cancelled</span> : location.PickupStatus === "7" ? <span className="badge badge-warning">Empty Pickup</span> : location.PickupStatus === "8" ? <span className="badge badge-second">Unsegregated Waste</span> : "Not yet updated"}</div>
                                                            </div>
                                                        </div>
                                                        <div className="card p-3 m-2">
                                                            <div className="card-title">
                                                                <div className="ml-2">Timing Details</div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div>Schedule Time : {moment(location.ScheduleTime).format("DD-MM-YYYY | hh:mm:ss A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</div>
                                                                <div>Pickup Time: {moment(location.PickupTime).format("DD-MM-YYYY | hh:mm:ss A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}</div>
                                                            </div>
                                                        </div>
                                                        <div className="card p-3 m-2">
                                                            <div className="card-title">
                                                                <div className="ml-2">Driver Details</div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div>Driver Name : {location.DriverName}</div>
                                                                <div>Driver Number : {location.DriverNumber}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        location.PickupStatus === "0" || location.PickupStatus === "2" ? <div><button className="btn btn-danger"
                                                            style={{ float: "right", marginRight: "25px" }}
                                                            onClick={() =>
                                                                swal("Warning", "Are you sure you want to cancel this pickup!", "warning", {
                                                                    buttons: ["No", "Yes"]
                                                                }).then((willCancel) => {
                                                                    if (willCancel) {
                                                                        cancelPickup();
                                                                    }
                                                                })
                                                            }
                                                        >
                                                            Cancel Pickup
                                                        </button></div> : <div></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default CurrentPickupDetail;