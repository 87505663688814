import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const ProfilePersonalInformation = ({ refOpenPersonal, apiDashboardUrl, editPersonalInformation, userId }) => {

    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const refClosePersonal = useRef(null);
    const navigate = useNavigate();

    const initialValue = () => {
        setName(editPersonalInformation.Name);
        setNumber(editPersonalInformation.Number);
        setEmail(editPersonalInformation.Email);
    };

    const refreshPage = () => window.location.reload(false);

    const updatePersonalInformation = async (e) => {

        e.preventDefault();

        await fetch(`${apiDashboardUrl}/user/updateProfile/${userId}`, {
            method: "PUT",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Name: name,
                Number: number,
                Email: email
            })
        }).then((res) => {
            res.json().then((data) => {
                const status = data.status;
                if (status === "success") {
                    swal("Success", data.message, "success").then((ok) => {
                        if (ok) {
                            navigate("/profile");
                            refClosePersonal.current.click();
                            refreshPage();
                        }
                    })
                } else {
                    swal("Error", data.message, "error");
                }
            })
        })
    };

    useEffect(() => {
        initialValue();
        // eslint-disable-next-line
    }, [editPersonalInformation]);

    return (
        <>
            <button ref={refOpenPersonal} type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#personalModal"></button>

            <div className="modal fade" id="personalModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="personalModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="personalModalLabel">
                                Edit Personal Information
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form className="my-3">
                                <div className="mb-3">
                                    <label htmlFor="name" className="font-size-md form-label br-2rem">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => { setName(e.target.value) }}
                                        placeholder="eg: John doe"
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="number" className="font-size-md form-label br-2rem">
                                        Number
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="number"
                                        name="number"
                                        value={number}
                                        onChange={(e) => { setNumber(e.target.value) }}
                                        placeholder="eg: 9425006123"
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="font-size-md form-label br-2rem">
                                        Email Id
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        placeholder="eg: johndoe@gmail.com"
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-pickupschedule"
                                data-bs-dismiss="modal"
                                ref={refClosePersonal}>
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-pickupschedule"
                                onClick={updatePersonalInformation}>
                                Update Profile
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilePersonalInformation;