import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const WalletHistory = ({ apiDashboardUrl, userId, title }) => {

    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const [loading, setLoading] = useState(false);
    var size = posts.length;
    var pages = 1;

    const navigate = useNavigate()
    if (!userId) navigate('/send-otp')

    const pageMin = () => {
        pages = page - 1;
        getWalletHistory(pages);
    };

    const pageAdd = () => {
        pages = page + 1;
        getWalletHistory(pages);
    };

    const getWalletHistory = async (pageNumber) => {
        try {
            await axios.get(`${apiDashboardUrl}/user/walletHistory?userId=${userId}&page=${pageNumber}`).then((res) => {
                if (res.data.status === "success") {
                    setPosts(res.data.data);
                    setTotalPage(res.data.TotalPages);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getWalletHistory(pages);
        document.title = `Wallet History - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                {
                                    size === 0 ? <div className="row">
                                        <div className="col-md-3 col-xl-3"></div>
                                        <div className="col-md-6 col-xl-6 align-items-center">
                                            <img src="assets/images/dashboard/data-not-found.png" alt="data-not-found"></img>
                                            <div style={{ fontSize: 20, textAlign: "center" }} className="footer-down-head">
                                                No data found at that moment:
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-xl-3"></div>
                                    </div> : <div className="app-content--inner">
                                        <div className="row">
                                            <div className="col-md-12 col-xl-12">
                                                <div className="card card-box mb-5">
                                                    <div className="card-header">
                                                        <h5 className="my-3">
                                                            <b>Wallet History</b>
                                                        </h5>
                                                    </div>
                                                    <div className="divider"></div>
                                                    <div className="card-body">
                                                        <div className="container">
                                                            <div className="list-group mb-4">
                                                                {
                                                                    !posts ? "" : posts.map((element, index) => {
                                                                        return (
                                                                            <div className="card p-3 bg-light mb-3" key={index}>
                                                                                <div className="card-title row">
                                                                                    <span className="col-sm-4">
                                                                                        ₹ {parseFloat(element.Amount === "" ? 0 : element.Amount)}
                                                                                    </span>
                                                                                    <span className="col-sm-4">
                                                                                        {element.Credit ? <dev>Debited</dev> : <div>Credited</div>}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="card-text row">
                                                                                    <span className="col-sm-4 txnId">
                                                                                        Transaction Id : {element.TransactionId}
                                                                                    </span>
                                                                                    <span className="col-sm-4 date">
                                                                                        Date : {moment(element.Time).format("DD-MM-YYYY")}
                                                                                    </span>
                                                                                    <span className="col-sm-4 time">
                                                                                        Time : {moment(element.Time).format("hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="container" id="paginationnumbers">
                                                                <div className="pagination" id="paginationnumbers">
                                                                    <div className="page-item text-left">
                                                                        <button disabled={page <= 1} className="page-link" onClick={() => {
                                                                            pageMin();
                                                                            page === 1 ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                setPage(page - 1)
                                                                            );
                                                                        }}>
                                                                            &laquo; Previous
                                                                        </button>
                                                                    </div>
                                                                    <div className="page-item">
                                                                        <div className="page-link">
                                                                            {page} of {totalPage}
                                                                        </div>
                                                                    </div>
                                                                    <div className="page-item text-left">
                                                                        <button disabled={page === totalPage} className="page-link" onClick={() => {
                                                                            pageAdd();
                                                                            page === totalPage ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                setPage(page + 1)
                                                                            );
                                                                        }}>
                                                                            Next &raquo;
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default WalletHistory;