import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const RefundAndCancellationPolicy = ({ title }) => {

    useEffect(() => {
        document.title = `Refund And Cancellation Policy - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">REFUND</span> <span className="our-investors-span2">POLICY</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                <p className="fw-bold">Thank you for supporting our Muskaan project and purchasing our upcycled products. We value your satisfaction and strive to provide you with the best possible experience. However, we understand that there may be instances where a refund or cancellation is necessary. Please review our detailed refund and cancellation policy outlined below:</p>
                                <p>
                                    A. We accept refund requests within 15 days from the date of delivery.
                                </p>
                                <p>
                                    B. To be eligible for a refund, the product must be unused, in its original condition, and in the original packaging.
                                </p>
                                <p>
                                    C. In the event that the product gets broken during the courier process:
                                    <p className="ms-3">
                                        i. Please inform us immediately upon receiving the broken product.
                                        <br />
                                        ii. Provide photographic evidence of the damage.
                                        <br />
                                        iii. We will initiate a refund or replacement, depending on your preference and product availability.
                                    </p>
                                </p>
                                <p>
                                    D. If you receive a broken product, unrelated to courier mishandling:
                                    <br />
                                    <p className="ms-3">
                                        i. Please inform us within 2 days of receiving the product.
                                        <br />
                                        ii. Provide photographic evidence of the damage.
                                        <br />
                                        iii. We will initiate a refund or replacement, depending on the circumstances and product availability.
                                    </p>
                                </p>
                                <p>
                                    E. If you receive an incorrect product:
                                    <p className="ms-3">
                                        i. Please inform us within 2 days of receiving the product.
                                        <br />
                                        ii. Provide details and photographic evidence of the incorrect item.
                                        <br />
                                        iii. We will initiate a refund or send you the correct product, depending on your preference and availability.
                                    </p>
                                </p>
                                <p>
                                    F. If your refund request is approved:
                                    <p className="ms-3">
                                        i. Refunds will be processed back to the original payment method used for the purchase.
                                        <br />
                                        ii. Shipping and handling charges are non-refundable.
                                        <br />
                                        iii. Depending on your location, it may take some time for the refunded amount to reflect in your account.
                                    </p>
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">CANCELLATION</span> <span className="our-investors-span2">POLICY</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                <p>
                                    A. You may request a cancellation of your order within 48 hours/ 2 days from the time of purchase.
                                </p>
                                <p>
                                    B. If the product has already been shipped:
                                    <p className="ms-3">
                                        i. Unfortunately, we cannot cancel the order. However, you can follow the refund policy mentioned above if you wish to return the product.
                                    </p>
                                </p>
                                <p>
                                    C. If the product has not been shipped:
                                    <p className="ms-3">
                                        i. We will process your cancellation request and initiate a refund if applicable.
                                    </p>
                                </p>
                                <p className="fw-bold">Please Note:</p>
                                <p>
                                    A. Our team takes utmost care in packaging and ensuring the safe delivery of products.
                                </p>
                                <p>
                                    B. In case of any issues, contacting us promptly and providing photographic evidence is crucial for a smooth resolution.
                                </p>
                                <p>
                                    C. We appreciate your understanding that your purchase directly contributes to empowering women and promoting sustainable waste management practices.
                                </p>
                                <p className="fw-normal">To initiate a refund or cancellation, please contact our customer support team at +919269099902 or email us at support@ecowrap.in. Kindly provide your order number and a detailed explanation for the refund or cancellation request. Our team will review your request and assist you accordingly.</p>
                                <p className="fw-normal">Thank you for your support and being a part of our mission.</p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default RefundAndCancellationPolicy;