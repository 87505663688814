import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const SchedulePickup = ({ apiDashboardUrl, userId, title }) => {

    const [schedulePickup, setSchedulePickup] = useState([]);
    const [propertyId, setPropertyId] = useState([]);
    const [futureDate, setFutureDate] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    if (!userId) navigate('/send-otp')
    const setDateForPickup = (e) => {
        let date = moment(e.target.value, "YYYY-MM-DD");
        const currentHours = new Date().getHours();
        let today = moment().format("YYYY-MM-DD");
        if (currentHours > 11 && date.format("YYYY-MM-DD") === today) {
            date = date.add(1, "day");
        }
        const nameOfDay = date.format("ddd");
        if (nameOfDay === "Sun") {
            date = date.add(1, "day");
        }
        setFutureDate(date.format("YYYY-MM-DD"));
    };

    const getSchedulePickup = async () => {
        try {
            await axios.get(`${apiDashboardUrl}/user/getProperties?userId=${userId}`).then((res) => {
                if (res.data.status === "success") {
                    setSchedulePickup(res.data.data);
                    setPropertyId(res.data.data[0].PropertyId);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const data = {
                UserId: `${userId}`,
                PropertyId: `${propertyId}`,
                Glass: e.target[0].checked ? 1 : 0,
                Plastic: e.target[1].checked ? 1 : 0,
                PackingItem: e.target[2].checked ? 1 : 0,
                OtherType: e.target[3].checked ? 1 : 0,
                futureDate: futureDate
            };
            await axios.post(`${apiDashboardUrl}/user/schedulePickupWithRoute`, data).then((res) => {
                if (res.data.status === "success") {
                    swal("Success", res.data.message, "success").then((ok) => {
                        if (ok) {
                            navigate("/dashboard");
                        }
                    })
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getSchedulePickup();
        document.title = `Schedule Pickup - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                <div className="app-content--inner">
                                    {
                                        !schedulePickup ? "" : schedulePickup.map((element, index) => {
                                            return (
                                                <div className="page-title" id="schedule-box-head" key={index}>
                                                    <h5 className="display-2 propertyName" style={{ fontSize: "1.5rem" }}>{element.PropertyName}</h5>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="container mt-5">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="col-md-12 align-left" id="confirmpickup-head">
                                                    <i className="fa fa-calendar-check-o"></i> Please mark pickup items
                                                </div>
                                                <div className="container">
                                                    <form method="post" className="form" onSubmit={(e) => { handleSubmit(e) }}>
                                                        <div className="form-check">
                                                            <div className="row mt-3" id="confirmpickup-background">
                                                                <div className="col-md-8">
                                                                    <label htmlFor="glass" className="form-check-label" id="confirmpickup-label">
                                                                        Dustbin 1
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-4 align-right">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input checkbox-round"
                                                                        id="glass"
                                                                        name="glass"
                                                                        value={1}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3" id="confirmpickup-background">
                                                                <div className="col-md-8">
                                                                    <label htmlFor="plastic" className="form-check-label" id="confirmpickup-label">
                                                                        Dustbin 2
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-4 align-right">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input checkbox-round"
                                                                        id="plastic"
                                                                        name="plastic"
                                                                        value={1}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3" id="confirmpickup-background">
                                                                <div className="col-md-8">
                                                                    <label htmlFor="packing_item" className="form-check-label" id="confirmpickup-label">
                                                                        Dustbin 3
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-4 align-right">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input checkbox-round"
                                                                        id="packing_item"
                                                                        name="packing_item"
                                                                        value={1}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3" id="confirmpickup-background">
                                                                <div className="col-md-8">
                                                                    <label htmlFor="other" className="form-check-label" id="confirmpickup-label">
                                                                        Other scrap material
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-4 align-right">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="form-check-input checkbox-round"
                                                                        id="other"
                                                                        name="other"
                                                                        value={1}
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="m-2">
                                                                        <label htmlFor="futureDate" className="font-size-md form-label m-3" style={{ color: "" }}>
                                                                            Select date for pickup
                                                                        </label>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control ml-2"
                                                                            id="futureDate"
                                                                            name="futureDate"
                                                                            min={moment(new Date()).format("YYYY-MM-DD")}
                                                                            value={futureDate}
                                                                            onChange={setDateForPickup}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center mt-5">
                                                            <button type="submit" className="btn btn-pickupschedule">
                                                                Confirm
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="container">
                                                    <img className="confirmpickup-img" src="assets/images/dashboard/confirm-pickup.png" alt="confirm-pickup-response" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default SchedulePickup;