import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Home = ({ apiUrl, title }) => {

    const [difference, setDifference] = useState([]);
    const [wasteGenerators, setWasteGenerators] = useState([]);
    const navigate = useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const getDifferenceData = async () => {
        try {
            await axios.get(`${apiUrl}/website/totalGenerate`).then((res) => {
                if (res.data.status === "success") {
                    setDifference(res.data.data);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        };
    };

    const getWasteGenerators = async () => {
        try {
            await axios.get(`${apiUrl}/website/topWasteGenerators`).then((res) => {
                if (res.data.status === "success") {
                    setWasteGenerators(res.data.data);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        };
    };

    useEffect(() => {
        getDifferenceData();
        getWasteGenerators();
        AOS.init({ duration: 1000, offset: 120 });
        document.title = `Home - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <section id="home">
                <div className="container2">
                    <div className="home-page-top-quote">
                        “The key to solid waste management is to make people understand
                        the importance of community involvement and participation.”
                    </div>
                    <div className="home-page-top-quote text-right">
                        -Team Ecowrap
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <button className="btn-get-started" onClick={() => { navigate("/send-otp") }}>
                            Get Started &nbsp; <i className="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </section>
            {/* <div className="section_bg_gray what-we-do">
                <div className="container2">
                    <div className="what-we-do-text">
                        <span className="what-span">What</span> <span className="we-do-span">WE Do?</span>
                    </div>
                    <div className="what-we-do-sub-text">
                        “The key to solid waste management is to make people understand the importance of community involvement and participation.” “The key to solid waste management is to make people understand the importance of community involvement and participation.” “The key to solid waste management is to make people understand the importance of community involvement and participation.”
                    </div>
                </div>
            </div> */}
            <div className="section_bg_gray">
                <div className="container2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pt-30 pb-30">
                                    <div className="our-process"><span className="our-process-span1">OUR</span> <span className="our-process-span2">PROCESS</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <img className="img-home-process" src="assets/images/home/work-process-ecowrap.gif" alt="ecowrap-work-process" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pt-30 pb-30">
                                <div className="our-process"><span className="our-process-span1">ROUTE</span> <span className="our-process-span2">OPTIMIZATION</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container2">
                    <div className="row">
                        <div className="col-md-12">
                            <img className="img-route" src="assets/images/home/route-optimization-ecowrap1.png" alt="ecowrap-solutions" />
                        </div>
                    </div>
                </div>
            </div>
            {
                !difference ? "" : difference.map((element, index) => {
                    return (
                        <div className="section_bg_gray" key={index}>
                            <div className="container" data-aos="zoom-in">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="pt-30 pb-30">
                                            <div className="our-investors"><span className="our-investors-span1">WE</span> <span className="our-investors-span2">MAKE A DIFFERENCE</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row text-center">
                                            <div className="col-md-5">
                                                <img className="max-width-100percent" src="assets/images/home/pickup-ecowrap.png" alt="ecowrap-pickup" />
                                            </div>
                                            <div className="col-md-5">
                                                <div className="container2 text-center we-make-a-diff-home">
                                                    <div className="home-wmad-text we-make-a-diff-home-color">
                                                        Pick Up
                                                    </div>
                                                    <div className="home-wmad-text">{parseInt(6000 + element.TotalPickups) + "+"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row text-center">
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <img className="max-width-100percent" src="assets/images/home/scrap-picked-ecowrap.png" alt="ecowrap-scrap-picked" />
                                            </div>
                                            <div className="col-md-5">
                                                <div className="container2 text-center we-make-a-diff-home">
                                                    <div className="home-wmad-text we-make-a-diff-home-color">
                                                        Scrap Picked
                                                    </div>
                                                    <div className="home-wmad-text">{parseInt(1000 + (Number(element.CollectedWaste) / 1000)) + "+ MT"}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row text-center">
                                            <div className="col-md-5">
                                                <img className="max-width-100percent" src="assets/images/home/ghg-emission-ecowrap.png" alt="ecowrap-ghg-emission" />
                                            </div>
                                            <div className="col-md-7">
                                                <div className="container2 text-center we-make-a-diff-home">
                                                    <div className="home-wmad-text we-make-a-diff-home-color">
                                                        GHG Emission Saved
                                                    </div>
                                                    <div className="home-wmad-text">{((1000 + (Number(element.CollectedWaste) / 1000)) * 1.84)?.toFixed(3) + "+ MT"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <img className="we-make-a-diff-img" src="assets/images/home/we-make-a-difference.png" alt="ecowrap-ghg-emission" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            {
                !difference ? "" : difference.map((element, index) => {
                    return (
                        <div className="section_bg_gray" key={index}>
                            <div className="container" data-aos="zoom-in">
                                <div className="row text-center">
                                    <div className="col-md-12">
                                        <div className="pt-30 pb-30">
                                            <div className="our-investors"><span className="our-investors-span1">OUR</span> <span className="our-investors-span2">IMPACT</span></div>
                                            <h5 className="fonts">
                                                <b>{parseInt((Number(element.CollectedWaste) / 1000) + 1000) + "+ MT"}</b> tonnes of waste collected from you that saves our mother nature by
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <img className="we-make-a-diff-img" src="assets/images/home/our-impact.png" alt="ecowrap-ghg-emission" />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row text-center">
                                            <div className="col-md-5">
                                                <img className="max-width-100percent max-height-200px" src="assets/images/home/save-tree-ecowrap.png" alt="ecowrap-pickup" />
                                            </div>
                                            <div className="col-md-5">
                                                <div className="container2 text-center we-make-a-diff-home">
                                                    <div className="home-wmad-text we-make-a-diff-home-color">
                                                        Trees
                                                    </div>
                                                    <div className="home-wmad-text">
                                                        {parseInt((Number(element.CollectedWaste) / 1000) * 5 + 1000 * 5) + "+"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row text-center">
                                            <div className="col-md-2"></div>
                                            <div className="col-md-5">
                                                <img className="max-width-100percent max-height-200px" src="assets/images/home/green-energy-ecowrap.png" alt="ecowrap-scrap-picked" />
                                            </div>
                                            <div className="col-md-5">
                                                <div className="container2 text-center we-make-a-diff-home">
                                                    <div className="home-wmad-text we-make-a-diff-home-color">
                                                        kWh of Energy
                                                    </div>
                                                    <div className="home-wmad-text">
                                                        {parseInt((Number(element.CollectedWaste) / 1000) * 237 + 1000 * 237) + "+"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row text-center">
                                            <div className="col-md-5">
                                                <img className="max-width-100percent max-height-200px" src="assets/images/home/save-water-ecowrap.png" alt="ecowrap-ghg-emission" />
                                            </div>
                                            <div className="col-md-7">
                                                <div className="container2 text-center we-make-a-diff-home">
                                                    <div className="home-wmad-text we-make-a-diff-home-color">
                                                        Lakhs Litre of Water
                                                    </div>
                                                    <div className="home-wmad-text">
                                                        {parseInt((Number(element.CollectedWaste) / 1000) * 0.6 + 1000 * 0.6) + "+"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <div className="section_bg_gray">
                <div className="container2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pt-30 pb-30">
                                <div className="our-investors"><span className="our-investors-span1">OUR</span> <span className="our-investors-span2">INVESTORS PARTNERS</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center pb-30">
                        <div className="col-sm-12 col-md-6" data-aos="fade-up">
                            <div className="service-card-image">
                                <a href="https://www.socialalpha.org/ecowrap-has-emerged-as-a-one-stop-solution-for-waste-segregation-collection-tracking-recycling-and-up-cycling/" target="_blank" rel="noreferrer noopener">
                                    <img className="investment-partner-home" src="assets/images/home/social-alpha-logo-ecowrap.png" alt="ecowrap-social-alpha" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6" data-aos="fade-up">
                            <div className="service-card-image">
                                <a href="https://villgro.org" target="_blank" rel="noreferrer noopener">
                                    <img className="investment-partner-home" src="assets/images/home/villgro-possible-logo.png" alt="ecowrap-social-alpha" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray mentors">
                <div className="container2">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pt-30 pb-30">
                                <div className="home-page-headings"></div>
                                <div className="our-investors"><span className="our-investors-span1">OUR</span> <span className="our-investors-span2">MENTORS IN OUR JOURNEY</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center pb-30">
                        <div className="sliders">
                            <div className="slider-track">
                                <div className="slide mr-5">
                                    <a href="http://www.aimsmartcity.com/">
                                        <img src="assets/images/home/mentor-ecowrap1.png" alt="mentor-ecowrap1" />
                                    </a>
                                </div>
                                <div className="slide mr-5">
                                    <a href="http://miic.mnit.ac.in/">
                                        <img src="assets/images/home/mentor-ecowrap3.png" alt="mentor-ecowrap2" />
                                    </a>
                                </div>
                                <div className="slide mr-5">
                                    <a href="https://thebuddhainstitute.org/">
                                        <img src="assets/images/home/mentor-ecowrap6.png" alt="mentor-ecowrap3" />
                                    </a>
                                </div>
                                <div className="slide mr-5">
                                    <a href="https://www.kmtrans.in/index.html">
                                        <img src="assets/images/home/mentor-ecowrap7.png" alt="mentor-ecowrap4" />
                                    </a>
                                </div>
                                <div className="slide mr-5">
                                    <a href="https://www.iitk.ac.in/">
                                        <img src="assets/images/home/mentor-ecowrap8.png" alt="mentor-ecowrap5" />
                                    </a>
                                </div>
                                <div className="slide mr-5">
                                    <a href="http://www.aimsmartcity.com/">
                                        <img src="assets/images/home/mentor-ecowrap1.png" alt="mentor-ecowrap1" />
                                    </a>
                                </div>
                                <div className="slide mr-5">
                                    <a href="http://miic.mnit.ac.in/">
                                        <img src="assets/images/home/mentor-ecowrap3.png" alt="mentor-ecowrap2" />
                                    </a>
                                </div>
                                <div className="slide mr-5">
                                    <a href="https://thebuddhainstitute.org/">
                                        <img src="assets/images/home/mentor-ecowrap6.png" alt="mentor-ecowrap3" />
                                    </a>
                                </div>
                                <div className="slide mr-5">
                                    <a href="https://www.kmtrans.in/index.html">
                                        <img src="assets/images/home/mentor-ecowrap7.png" alt="mentor-ecowrap4" />
                                    </a>
                                </div>
                                <div className="slide mr-5">
                                    <a href="https://www.iitk.ac.in/">
                                        <img src="assets/images/home/mentor-ecowrap8.png" alt="mentor-ecowrap5" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                wasteGenerators.length === 0 ? <div></div> : <div className="section_bg_gray">
                    <div className="container2">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pt-30 pb-30">
                                    <div className="our-investors"><span className="our-investors-span1">OUR</span> <span className="our-investors-span2">TOP ECO FRIENDS</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="row text-center">
                            <Slider {...settings}>
                                {
                                    !wasteGenerators ? "" : wasteGenerators.map((item, index) => {
                                        return (
                                            <div className="card-carsoul" key={index}>
                                                <div className="single-testimonial-item">
                                                    <div className="card-top">
                                                        {item.PropertyType === "Business" ? <img src="assets/images/users/business.png" alt={item.PropertyName} /> : item.PropertyType === "Cafe" ? <img src="assets/images/users/cafe.png" alt={item.PropertyName} /> : item.PropertyType === "Educational" ? <img src="assets/images/users/educational.png" alt={item.PropertyName} /> : item.PropertyType === "Hotel" ? <img src="assets/images/users/hotel.png" alt={item.PropertyName} /> : item.PropertyType === "Household" ? <img src="assets/images/users/household.png" alt={item.PropertyName} /> : item.PropertyType === "Restaurant" ? <img src="assets/images\users/restaurant.png" alt={item.PropertyName} /> : item.PropertyType === "Retailer" ? <img src="assets/images/users/retailer.png" alt={item.PropertyName} /> : <img src="assets/images/users/common.png" alt="common" />}
                                                    </div>
                                                    <h4><u>{item.PropertyName}</u> <span>{"Green Champion: " + item.ContactPerson}</span></h4>
                                                    <br />
                                                    <p><a className="color-black" href={`https://www.google.com/maps/place/${item.Latitude},${item.Longitude}`}>
                                                        <i className="fa fa-map-marker" aria-hidden="true"></i> {item.Address.slice(0, 65) + "..."}
                                                    </a></p>
                                                    <div>{"Sustanablity Rating: " + item.Rating.substring(0, 3)} <span className="fa fa-star checked" style={{ color: "#6aaf08" }}></span></div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            }
            <div className="section_bg_gray pt-30">
                <div className="container2">
                    <div className="row mg-bottom-download">
                        <div className="col-md-6">
                            <div className="download-home-mobile text-center">
                                <img className="download-app-mobile" src="assets/images/home/download-app-home-mobile.png" alt="download-app-mobile" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="download-home-head text-center">DOWNLOAD OUR APP</div>
                            <div className="download-home-sub-head text-center">to know more!!</div>
                            <div className="text-center pt-30">
                                <a href="https://play.google.com/store/apps/details?id=com.ecowrap.hcbr" target="_blank" rel="noopener noreferrer">
                                    <img src="assets/images/home/download-app-home-gplay.png" alt="download-app-gplay" />
                                </a>
                            </div>
                            <div className="text-center pt-30">
                                <img src="assets/images/home/download-app-home-qr.png" alt="download-app-qr-code" />
                            </div>
                            <div className="scan-qr-to-download text-center">
                                Scan the QR to <br /> Download
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Home;