import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import swal from "sweetalert";

const VerifyDeactiveOtp = ({ apiUserUrl, title }) => {

    const { addPropertyAndAddressData, addPersonalInformationData, description } = useLocation().state;
    const navigate = useNavigate();

    const [otp, setOtp] = useState("");
    const [message, setMessage] = useState("");
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(30);

    const verifyDeactiveOtp = async (e) => {
        try {
            e.preventDefault();
            const response = await fetch(`${apiUserUrl}/deactiveAccount`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    otp: otp,
                    phoneNumber: secureLocalStorage.getItem("phoneNumber"),
                    fullHash: secureLocalStorage.getItem("hash"),
                    desc: JSON.stringify(description)
                })
            });
            const data = await response.json();
            if (data.status === true) {
                setMessage(data.message);
                secureLocalStorage.removeItem("hash");
                navigate("/check-registration-otp", { state: { addPropertyAndAddressData, addPersonalInformationData } })
            } else {
                setMessage(data.message);
            }
        } catch (error) {
            setMessage(error.message || "An error occurred");
        }
    };

    var regex = /[0-9]|null/;

    const handler = (e) => {
        if (!regex.test(e.nativeEvent.data)) return;
        if (e.target.value.length <= 4) setOtp(e.target.value);
    };

    const resendOTP = async () => {
        try {
            const response = await axios.post(`${apiUserUrl}/deactiveOTP`, {
                phoneNumber: secureLocalStorage.getItem("phoneNumber"),
            });
            if (response.data.status === true) {
                secureLocalStorage.removeItem("hash");
                secureLocalStorage.setItem("hash", response.data.data[0].PropertyName);
            } else {
                swal("Error", response.data.message, "error");
            }
        } catch (error) {
            console.error(error);
            swal("Error", "An error occurred", "error");
        }
    };

    const refreshPage = () => window.location.reload(false);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) setSeconds(seconds - 1);
            if (seconds === 0) {
                if (minutes === 0) clearInterval(interval);
                else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);
        document.title = `Verify Deactive OTP - ${title}`;
        window.scrollTo(0, 0);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [seconds]);

    return (
        <>
            <div className="container">
                <div className="row justify-content-md-center pt-5">
                    <div className="col-md-6 text-center pt-5">
                        <div className="contact-form">
                            <form>
                                <div className="form-row justify-content-md-center text-center">
                                    <div className="form-group col-md-6">
                                        <div className="navbar-brand navbar-brands pt-5">
                                            <img className="navbar-logo" src="assets/images/logo/ecowrap-logo.png" alt="ecowrap-logo" />
                                        </div>
                                        <h5 className="verify-h5">Verify Deactive Otp</h5>
                                        <h6 className="verify-h6">Please enter the four digit OTP sent on{" "}
                                            <span>{secureLocalStorage.getItem("phoneNumber")}.</span>
                                        </h6>
                                        <input
                                            type="text"
                                            className="form-control justify-content-md-center text-center"
                                            id="otp"
                                            name="otp"
                                            value={otp}
                                            onChange={(e) => { handler(e) }}
                                            placeholder="Enter your 4 digit OTP"
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="countdown-text">
                                    {
                                        seconds > 0 || minutes > 0 ? (<p> Resend OTP in {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds} </p>) : (<button type="button" className="btn btn-blog" style={{ color: "#FF5630" }} onClick={() => { resendOTP(); refreshPage(); }}>Resend OTP</button>)
                                    }
                                </div>
                                {
                                    message === "" ? <div></div> : <div className="text-warning mb-3">{message}</div>
                                }
                                <div className="text-center text-bold">
                                    <button type="button" className="btn btn-sm btn-light custom-btn" id="verification" onClick={verifyDeactiveOtp}>
                                        Verify
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img className="otp-verify-img" src="assets/images/auth/otp-image.png" alt="otp-verify" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyDeactiveOtp;