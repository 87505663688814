import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const TermsAndConditions = ({ title }) => {

    useEffect(() => {
        document.title = `Terms & Conditions - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">DEFI</span><span className="our-investors-span2">NITIONS</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                A. In these terms and conditions (“Conditions”) the following
                                definitions shall have effect unless the context otherwise
                                dictates:
                                <br />
                                i. "Company" shall mean ECOWRAP IMPACT PRIVATE LIMITED (Company
                                number AAJ-3167) whose registered office is at 56B, Shiv Colony,
                                Vaishali Nagar (Jaipur).
                                <br />
                                ii. "Supplier" shall mean any person firm or company authorised
                                by the Company to act on its behalf in the provision of the
                                Service.
                                <br />
                                iii. "Customer" shall mean the person firm or company any
                                person, firm or corporation for who any Service is undertaken by
                                the Company.
                                <br />
                                iv. "Container" shall mean any skip, container, drum, sack or
                                other receptacle, supplied by the Company or the Supplier in
                                connection with the provision of the Service.
                                <br />
                                v. "Service" shall mean the collection transportation and/or
                                disposal of waste materials and/or any industrial activity on
                                site on behalf of the Customer as set out in the Contract and
                                any other services that the Company provides to the Customer.
                                <br />
                                vi. “Contract” shall mean the contract between the Company and
                                the Customer for the supply of the Service in accordance with
                                these Conditions.
                                <br />
                                vii. “Waste” shall mean any waste materials to be collected,
                                disposed of or recycled by the Company as part of the Service.
                                <br />
                                viii. “Waste Transfer Note” shall mean any waste transfer notes
                                relating to all or any part of the Waste.
                                <br />
                                ix. “Order” shall mean the Customer's order for the Service as
                                set out in the Customer's purchase order form or the Customer's
                                written acceptance of a quotation by the Company as the case may
                                be
                                <br />
                                x. “Quotation” shall mean the description or specification of
                                the Service provided in writing by the Company to the Customer.
                                <br />
                                xi. “Equipment” shall mean any Container and any tools and other
                                property supplied by the Company or the Supplier in connection
                                with the provision of the Service.
                                <br />
                                <br />
                                B. In these Conditions, the following rules apply:
                                <br />
                                i. a person includes a natural person, corporate or
                                unincorporated body (whether or not having separate legal
                                personality);
                                <br />
                                ii. a reference to a party includes its personal
                                representatives, successors or permitted assigns;
                                <br />
                                iii. a reference to a statute or statutory provision is a
                                reference to such statute or statutory provision as amended or
                                re-enacted. A reference to a statute or statutory provision
                                includes any subordinate legislation made under that statute or
                                statutory provision, as amended or re-enacted;
                                <br />
                                iv. any phrase introduced by the terms including, include, in
                                particular or any similar expression, shall be construed as
                                illustrative and shall not limit the sense of the words
                                preceding those terms; and
                                <br />
                                v. a reference to writing or written includes faxes and emails.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">STATUTORY</span> <span className="our-investors-span2">OBLIGATIONS</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                It is a condition of every Contract entered into by the Company
                                and the Customer warrants to the Company that that the Customer
                                shall obtain and maintain (and produce evidence thereof when
                                requested by the Company) all necessary registrations, licences
                                and consents and shall comply with all relevant legislation and
                                the requirements of government or any statutory local or public
                                authority in relation to the Service or to the use of any
                                Equipment by the Customer including in particular the
                                Environmental Protection Act 1990, the Environmental Protection
                                (Duty of Care) Regulations 1991, the Environment Act 1995, the
                                Highways Act 1971 and the Health & Safety at work etc Act 1974.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">BASIS</span> <span className="our-investors-span2">OF CONTRACT</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. The Company shall arrange collection and disposal of or, in
                                appropriate circumstances, recycle Waste subject to the Contract
                                and these Conditions. The Company shall not accept toxic,
                                dangerous, hazardous or special waste.
                                <br />
                                ii. The Order constitutes an offer by the Customer to avail the
                                Services in accordance with these Conditions. The Order shall
                                only be deemed to be accepted when the Company issues written
                                acceptance of the Order or, if earlier, the Company starts to
                                provide the Service, at which point and on which date the
                                Contract shall come into existence.
                                <br />
                                iii. The Contract constitutes the entire agreement between the
                                parties. The Customer acknowledges that it has not relied on any
                                statement, promise, representation, assurance or warranty made
                                or given by or on behalf of the Company which is not set out in
                                the Contract.
                                <br />
                                iv. These Conditions apply to the Contract to the exclusion of
                                any other terms that the Customer seeks to impose or
                                incorporate, or which are implied by trade, custom, practice or
                                course of dealing. Any conditions contained in an Order which
                                conflict with any of these Conditions shall be deemed to be
                                inapplicable to any Order unless expressly agreed by the Company
                                in writing when acknowledging an Order.
                                <br />
                                v. Any quotation given by the Company shall not constitute an
                                offer, and is only valid for a period of 30 days from its date
                                of issue (unless previously withdrawn by the Company).
                                <br />
                                vi. The Company shall have the right to make any changes to the
                                Service which are necessary to comply with any applicable law or
                                safety requirement, or which do not materially affect the nature
                                or quality of the Service, and the Company shall notify the
                                Customer in any such event.
                                <br />
                                vii. The Company's or Supplier's employees or agents shall not
                                be required to undertake any Service outside the terms of the
                                Contract and furthermore the Customer shall not request such
                                employees, agents or contractors to do so without the Company's
                                or Supplier's prior agreement.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">NATURE</span> <span className="our-investors-span2">OF WASTE MATERIALS</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. The Customer warrants that the Waste shall subject to
                                condition 3.1 be non-hazardous and of the type, character and
                                quantities specified in any quotation or waste transfer note and
                                the Customer shall ensure that no material change in the nature
                                of the Waste shall take place during the Contract. Where the
                                Waste differs from the quotation or any waste transfer note the
                                Company reserves the right either to refuse to accept such Waste
                                or to apply additional charges (at rates agreed with the
                                Customer or failing agreement at the Company's then standard
                                rates) in relation to its collection, disposal or recycling
                                <br />
                                ii. The Customer shall sign a single or multiple consignment
                                waste transfer note declaring the Waste type and shall ensure
                                that the Waste is accurately described when completing the waste
                                transfer note.
                                <br />
                                iii. The Company reserves the right not to accept any waste
                                which by reason of size or weight is difficult to collect or
                                dispose or in respect of which a waste transfer note has not
                                been properly completed and signed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">CUSTOMER'S</span> <span className="our-investors-span2">PREMISES</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. The Customer must notify the Company before any supply of a
                                Service is commenced of any particular requirement of the
                                Customer relating to health and safety at work and of any
                                security arrangements that may need to be observed or any
                                hazards, risks or dangers that may arise during or as a result
                                of the Company, Supplier or their employees, agents or
                                contractors undertaking any Service on the premises of the
                                Customer.
                                <br />
                                ii. The Customer shall provide the Company, the Supplier, their
                                employees, agents and contractors, with access to the Customer's
                                premises, office accommodation and other facilities as
                                reasonably required for the provision of the Service.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">CUSTOMER'S</span> <span className="our-investors-span2">OBLIGATIONS</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. The Customer shall be responsible for placing and shall only
                                place the Waste referred to in Clause 4 in the Containers.
                                <br />
                                ii. The Customer warrants that he has the right to place such
                                Waste in the Containers and the property in all Waste shall pass
                                to the Company upon the same being commenced to be removed.
                                Provided there shall have been no breach of the Customers
                                obligations in relation thereto risk in the Waste shall pass to
                                the Company upon the same being commenced to be removed. The
                                Customer shall remain liable at all times for (and shall
                                indemnify the Company and the Supplier in respect of) any damage
                                caused by the Waste in breach of these Conditions.
                                <br />
                                iii. It shall be a condition that the Customer observes and
                                performs the following:
                                <br />
                                a. All containers to be loaded safely and evenly and no sharp or
                                hot materials except for the ones mentioned in the contract are
                                to be placed in any plastic Container or other Container likely
                                to be damaged thereby.
                                <br />
                                b. Unless otherwise agreed in writing, no Container shall be
                                placed on the highway,
                                <br />
                                c. No rubbish shall be burned in any container or any fire
                                started or permitted therein by the Customer or any other
                                person,
                                <br />
                                d. No Container shall be removed from the Customer's premises to
                                which it was delivered without the Company's prior written
                                consent,
                                <br />
                                e. No sign lettering insignia advertising or other device of the
                                Customer shall be placed or fixed on any Container.
                                <br />
                                f. No sign lettering insignia advertising or other device of the
                                Company or the Supplier on any Container shall be removed or
                                defaced.
                                <br />
                                iv. The Customer shall at all reasonable times allow the Company
                                or Supplier and any person authorised by either of them to have
                                access to the Equipment to inspect, test, adjust, repair or
                                replace the same so far as possible at times convenient to the
                                Customer.
                                <br />
                                v. The Company shall not be responsible for any property
                                including personal effects deposited by the Customer or any
                                other person in any Container and shall not be bound to return
                                the same nor be liable for any loss or damage thereto.
                                <br />
                                vi. Title and property in the Equipment remain with the Company
                                at all times and the Customer is responsible for (and shall
                                indemnify the Company and the Supplier in respect of) any loss
                                or damage to any Equipment whilst in the Customer's care,
                                custody or control.
                                <br />
                                vii. The Customer shall: -
                                <br />
                                a. co-operate with the Company and the Supplier in all matters
                                relating to the Service
                                <br />
                                b. provide the Company and the Supplier with such information
                                and materials as they may reasonably require in order to supply
                                the Service, and ensure that such information is accurate in all
                                material respects.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">TIME</span> <span className="our-investors-span2">OF COLLECTION</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                When the Supplier calls on the Customer to perform any part of
                                the Service the Customer shall ensure that the Equipment is
                                ready and available at the time of collection by the Supplier.
                                The Company and the Supplier shall not be under any obligation
                                to carry out any Service nor be liable to the Customer where the
                                Customer is in breach of this condition, but nevertheless the
                                Company reserves the right to make a wasted service charge. The
                                Company also reserves the right to charge for any failed
                                collection caused by circumstances outside the control of the
                                Company.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">CHANGING</span> <span className="our-investors-span2">CIRCUMSTANCES</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. If it becomes apparent for reasons not disclosed by the
                                Customer or which could not have been foreseen by the Company at
                                the time of entering the Contract or commencing the work that
                                the Service to be undertaken differs from that originally
                                envisaged and/or set out in any quotation, the Company shall
                                notify the Customer accordingly giving particulars as soon as
                                reasonably practicable after the circumstances become apparent
                                and the Company shall be entitled either to terminate the
                                Contract without any liability to the Customer or to payment for
                                additional work (at rates agreed with the Customer or failing
                                agreement at the Company's then standard rates).
                                <br />
                                ii. Should the Company elect to cease the provision of the
                                Service in the changed circumstances, the Contract shall be
                                deemed to be terminated. Upon such termination the Company shall
                                be entitled to charge for any work carried out up to the time of
                                termination and to be reimbursed for the costs associated with
                                commitments and liabilities entered into pursuant to the
                                Contract and for any Equipment or other items provided or
                                intended to be provided for the purpose of the Contract.
                                <br />
                                iii. The Customer shall pay additional charges at the rates
                                agreed with the Customer or failing agreement at the Company's
                                then standard rates occasioned by:
                                <br />
                                a. any additional costs arising from changes to the agreed
                                frequency or volumes of Service as notified by the Customer.
                                <br />
                                b. any delay caused by any act or omission of the Customer or
                                its employees, agents or contractors.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">TERMS</span><span className="our-investors-span2">OF PAYMENT</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. The Company shall be entitled to invoice [at such times as
                                are set out in the Contract or otherwise upon completion of the
                                provision of the Service]. All payments shall be made upfront at
                                the time of purchasing the waste. Other times of payment can
                                only be made with the prior written approval of the Company. The
                                Customer shall not be entitled to delay or withhold payment on
                                account of any alleged set-off or counterclaim. Time for payment
                                shall be of the essence of the Contract.
                                <br />
                                ii. Where the charge for the Service is liable to VAT, the
                                Customer shall pay an additional amount equivalent to the amount
                                of VAT at the appropriate rate.
                                <br />
                                iii. The Company reserves the right without liability to the
                                Customer not to execute any Order and to remove any Equipment if
                                arrangements for payment of the Customer's account are not in
                                the Company's discretion satisfactory to the Company or where
                                the Customer is in breach of any of these conditions or where
                                the Company considers that the Service required may place at
                                risk any person, goods, vehicle, Equipment or property.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">TIME</span> <span className="our-investors-span2">FOR PROVISION OF SERVICE</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. The Company may at any time assign, transfer, mortgage,
                                charge, subcontract or deal in any other manner with all or any
                                of its rights under the Contract and may subcontract or delegate
                                in any manner any or all of its obligations under the Contract
                                to any third party or agent.
                                <br />
                                ii. Where the Company is unable due to Bank or Public Holidays,
                                breakdown or circumstances outside its reasonable control to
                                carry out any Service on the day notified to the Customer, the
                                Company shall make all reasonable efforts to carry out such
                                Service as soon as practicable thereafter.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">CUSTOMER'S</span> <span className="our-investors-span2">FURTHER OBLIGATIONS</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. If a Service is required by the Customer involving vehicle
                                movement otherwise than on the public highway: the Customer
                                shall afford reasonable, safe and adequate access and space in
                                which loading and unloading operations, delivery and collection
                                may be carried out without risk of damage to the vehicle, its
                                driver or its load and without obstruction to the public
                                highway.
                                <br />
                                ii. The driver of a vehicle providing such a Service shall be
                                deemed to be under the control of the Customer whilst on or
                                adjacent to the Customer's premises and the Customer shall be
                                solely responsible for any accident or any damage by the weight
                                of its vehicle or its load to any bridges, wires, cables, drains
                                and other services, manholes, roads, paths or any surface or any
                                other property on, above or below the surface of the land and
                                shall indemnify the Company and the Supplier against all claims
                                in respect of legal liability arising therefrom.
                                <br />
                                iii. The Company and the Supplier reserve the right to refuse to
                                carry out any Service if it considers that the Service required
                                might place at risk any person, goods, vehicle or property.
                                <br />
                                iv. The Customer's attention is drawn to the need to obtain the
                                consent of the Local Authority to cross pavements or to site
                                Equipment on a public highway.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">LIMITATION</span> <span className="our-investors-span2">OF COMPANY'S LIABILITY</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. Except for defects in the Service which shall have been
                                notified by the Customer to the Company in writing within 14
                                days after the date of the provision of the Service the Company
                                shall not be liable for any claim, loss or damage howsoever
                                arising in respect of any defect in the Service whether due to
                                any act, neglect, default of the Company, the Supplier or their
                                employees, contractors or agents or otherwise, and all
                                warranties and conditions express or implied are hereby excluded
                                to the extent permitted by law.
                                <br />
                                ii. In particular (without prejudice to generality of the
                                foregoing) the Company shall not be liable for any such claim,
                                loss or damage resulting from: (A) any circumstance arising
                                outside the reasonable control of the Company or the Supplier,
                                (B) any instruction given by or any act or omission of the
                                Customer or his servants or agents, (C) any inherent or latent
                                defect which the Company or the Supplier could not reasonably
                                have discovered or rectified, (D) any material breach by the
                                Customer of any of these Conditions required to be observed or
                                performed by the Customer or, (E) any damage howsoever caused by
                                any Equipment.
                                <br />
                                iii. Nothing in these Conditions shall limit or exclude the
                                Company's liability for
                                <br />
                                a. death or personal injury caused by its negligence, or the
                                negligence of the Supplier or their respective its employees,
                                agents or subcontractors;
                                <br />
                                b. fraud or fraudulent misrepresentation; or
                                <br />
                                c. breach of the terms implied by section 2 of the Supply of
                                Goods and Services Act 1982 (title and quiet possession).
                                <br />
                                iv. Subject to Condition 12.3:
                                <br />
                                a. the Company shall under no circumstances whatsoever be liable
                                to the Customer, whether in contract, tort (including
                                negligence), breach of statutory duty, or otherwise, for any
                                loss of profit, or any indirect or consequential loss arising
                                under or in connection with the Contract; and
                                <br />
                                b. the Company's total liability to the Customer in respect of
                                all other losses arising under or in connection with the
                                Contract, whether in contract, tort (including negligence),
                                breach of statutory duty, or otherwise, shall in no
                                circumstances exceed ₹ 2000.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">INDEMNITY</span> <span className="our-investors-span2">BY CUSTOMER</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. The Customer shall indemnify the Company against any loss or
                                any damage to any Equipment occurring or caused during the
                                continuance of the Contract with the Customer.
                                <br />
                                ii. The Customer shall indemnify the Company against claims,
                                loss or damage which the Company suffers or incurs arising out
                                of the use of any Equipment or the breach by the Customer or any
                                of these Conditions required to be observed or performed by the
                                Customer.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">ASSIGNMENT</span> <span className="our-investors-span2">BY CUSTOMER</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                The Customer shall not, without the prior written consent of the
                                Company, assign, transfer, mortgage, charge, subcontract,
                                declare a trust over or deal in any other manner with any or all
                                of its rights or obligations under the Contract.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">LAW</span> <span className="our-investors-span2">OF CONTRACRT/JURISDICTION</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                The Contract, these Conditions and any dispute or claim arising
                                out of or in connection with it or them or subject matter or
                                formation (including non-contractual disputes or claims), shall
                                be governed by, and construed in accordance with the law of
                                Indian Waste Management. Each party irrevocably agrees that the
                                courts of India shall have exclusive jurisdiction to settle any
                                dispute or claim arising out of or in connection with this
                                Contract or its subject matter or formation (including
                                non-contractual disputes or claims).
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">EFFECT</span> <span className="our-investors-span2">OF HEADINGS</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                The headings to these conditions are for guidance only and are
                                not to be construed as forming part of or in any way limiting
                                the effect of the condition themselves.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">TERM</span><span className="our-investors-span2">INATION</span></div>
                            </div>

                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. Without limiting its other rights or remedies, either party
                                may terminate a Contract by giving the other party one months'
                                written notice.
                                <br />
                                ii. Without limiting its other rights or remedies, the Company
                                may terminate the Contract with immediate effect by giving
                                written notice to the Customer if:
                                <br />
                                a. the Customer commits a material breach of any term of the
                                Contract and (if such a breach is remediable) fails to remedy
                                that breach within 5 days of the Customer being notified in
                                writing to do so;
                                <br />
                                b. the Customer, being a company or limited liability
                                partnership becomes insolvent, or if an order is made or a
                                resolution is passed for the winding up of the Customer (other
                                than voluntarily for the purpose of solvent amalgamation or
                                reconstruction), or if an administrator, administrative receiver
                                or receiver is appointed in respect of the whole or any part of
                                the Customer's assets or business, or if the Customer makes any
                                composition with its creditors or takes or suffers any similar
                                or analogous action in consequence of debt
                                <br />
                                c. the Customer (being an individual) is the subject of a
                                bankruptcy petition or order, is deemed either unable to pay its
                                debts or as having no reasonable prospect of so doing, in either
                                case, within the meaning of section 268 of the Insolvency Act
                                1986 or (being a partnership) has any partner to whom any of the
                                foregoing apply;
                                <br />
                                d. the Customer suspends or ceases, or threatens to suspend or
                                cease, to carry on all or a substantial part of its business;
                                <br />
                                e. the Customer (being an individual) dies or, by reason of
                                illness or incapacity (whether mental or physical), is incapable
                                of managing his own affairs or becomes a patient under any
                                mental health legislation.
                                <br />
                                iii. Without limiting its other rights or remedies, the Company
                                may terminate the Contract with immediate effect by giving
                                written notice to the Customer if the Customer fails to pay any
                                amount due under this Contract on the due date for payment and
                                fails to pay all outstanding amounts within 14 days after being
                                notified in writing to do so.
                                <br />
                                iv. Without limiting its other rights or remedies, the Company
                                may suspend provision of the Services under the Contract or any
                                other contract between the Customer and the Company if the
                                Customer becomes subject to any of the events listed in clause
                                16.2 or the Company reasonably believes that the Customer is
                                about to become subject to any of them, or if the Customer fails
                                to pay any amount due under this Contract on the due date for
                                payment.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">CONSEQUENCES</span> <span className="our-investors-span2">OF TERMINATION</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                On termination of the Contract for any reason:
                                <br />
                                i. the Customer shall immediately pay to the Company all of the
                                Company's outstanding unpaid invoices and interest and, in
                                respect of Service supplied but for which no invoice has been
                                submitted, the Company shall submit an invoice, which shall be
                                payable by the Customer immediately on receipt;
                                <br />
                                ii. the Customer shall return or make available for collection
                                all of the Equipment. If the Customer fails to do so, then the
                                Company may enter the Customer's premises and take possession of
                                them. Until they have been returned, the Customer shall be
                                solely responsible for their safe keeping and will not use them
                                for any purpose not connected with this Contract;
                                <br />
                                iii. the accrued rights, remedies, obligations and liabilities
                                of the parties as at expiry or termination shall be unaffected,
                                including the right to claim damages in respect of any breach of
                                the Contract which existed at or before the date of termination
                                or expiry; and
                                <br />
                                iv. clauses which expressly or by implication survive
                                termination shall continue in full force and effect.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">FORCE</span> <span className="our-investors-span2">MAJEURE</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                For the purposes of these Conditions, “Force Majeure Event”
                                means an event beyond the reasonable control of the Company
                                including but not limited to strikes, lock-outs or other
                                industrial disputes (whether involving the workforce of the
                                Company or any other party), failure of a utility service or
                                transport network, act of God, war, riot, civil commotion,
                                malicious damage, compliance with any law or governmental order,
                                rule, regulation or direction, accident, breakdown of plant or
                                machinery, fire, flood, storm or default of suppliers or
                                subcontractors. The Company shall not be liable to the Customer
                                as a result of any delay or failure to perform its obligations
                                under this Contract as a result of a Force Majeure Event. If the
                                Force Majeure Event prevents the Company from providing the
                                Service for more than 4 weeks, the Company shall, without
                                limiting its other rights or remedies, have the right to
                                terminate this Contract immediately by giving written notice to
                                the Customer.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray pb-30">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">GEN</span><span className="our-investors-span2">ERAL</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-justify">
                        <div className="col-sm-12 col-md-12">
                            <p className="fonts">
                                i. Notice:; Any notice or other communication given to a party
                                under or in connection with the Contract shall be in writing,
                                addressed to that party at its registered office (if it is a
                                company) or its principal place of business (in any other case)
                                or such other address as that party may have specified to the
                                other party in writing in accordance with this clause, and shall
                                be delivered personally, sent by pre-paid first className post
                                or other next working day delivery service, commercial courier,
                                fax [or e-mail].
                                <br />
                                A notice or other communication shall be deemed to have been
                                received: if delivered personally, when left at the address
                                referred to above; if sent by pre-paid first className post or
                                other next working day delivery service, at 9.00 am on the
                                second day after posting; if delivered by commercial courier, on
                                the date and at the time that the courier's delivery receipt is
                                signed; or, if sent by fax or e-mail, one day after
                                transmission. The provisions of this clause shall not apply to
                                the service of any proceedings or other documents in any legal
                                action.
                                <br />
                                ii. Severance: If any provision or part-provision of the
                                Contract is or becomes invalid, illegal or unenforceable, it
                                shall be deemed modified to the minimum extent necessary to make
                                it valid, legal and enforceable. If such modification is not
                                possible, the relevant provision or part-provision shall be
                                deemed deleted. Any modification to or deletion of a provision
                                or part-provision under this clause shall not affect the
                                validity and enforceability of the rest of the Contract.
                                <br />
                                If one party gives notice to the other of the possibility that
                                any provision or part-provision of this Contract is invalid,
                                illegal or unenforceable, the parties shall negotiate in good
                                faith to amend such provision so that, as amended, it is legal,
                                valid and enforceable, and, to the greatest extent possible,
                                achieves the intended commercial result of the original
                                provision.
                                <br />
                                iii. Waiver: A waiver of any right under the Contract, these
                                Conditions or law is only effective if it is in writing and
                                shall not be deemed to be a waiver of any subsequent breach or
                                default. No failure or delay by a party in exercising any right
                                or remedy provided under the Contract, these Conditions or by
                                law shall constitute a waiver of that or any other right or
                                remedy, nor shall it prevent or restrict its further exercise of
                                that or any other right or remedy. No single or partial exercise
                                of such right or remedy shall prevent or restrict the further
                                exercise of that or any other right or remedy.
                                <br />
                                iv. No partnership or agency: Nothing in these Conditions is
                                intended to, or shall be deemed to, establish any partnership or
                                joint venture between the parties, nor constitute either party
                                the agent of the other for any purpose. Neither party shall have
                                authority to act as agent for, or to bind, the other party in
                                any way.
                                <br />
                                v. Third parties: A person who is not a party to the Contract
                                shall not have any rights to enforce its terms.
                                <br />
                                vi. Variation: Except as set out in these Conditions, no
                                variation of the Contract or these Conditions, including the
                                introduction of any additional terms and conditions, shall be
                                effective unless it is agreed in writing and signed by the,
                                these Conditions.
                                <br />
                                Customer Certification :- I declare that: -
                                <br />
                                • the description of the Waste given in the Waste Transfer Notes
                                is complete and accurate and undertake to notify ECOWRAP IMPACT
                                PRIVATE LIMITED immediately if any of the details change and
                                that hazardous waste will not be placed in the container(s).
                                <br />
                                • the waste collected from the below named company and herein
                                described has been treated in accordance with Waste Management
                                Rules for Solid Waste
                                <br />• I have fulfilled my duty to apply the waste hierarchy as
                                required by India Waste Management Rules
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TermsAndConditions;