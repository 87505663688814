import React, { useState, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import ProfilePersonalInformation from "./ProfilePersonalInformation";
import ProfilePropertyInformation from "./ProfilePropertyInformation";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const Profile = ({ apiDashboardUrl, userId, title }) => {

    const [profile, setProfile] = useState();
    const [editPersonalInformation, setEditPersonalInformation] = useState("");
    const [editPropertyInformation, setEditPropertyInformation] = useState("");
    const [loading, setLoading] = useState(false);
    const refOpenPersonal = useRef(null);
    const refOpenProperty = useRef(null);
    const navigate = useNavigate()
    if (!userId) navigate('/send-otp')
    const getProfile = async () => {
        try {
            await axios.get(`${apiDashboardUrl}/user/userProfile/${userId}`).then((res) => {
                if (res.data.status === "success") {
                    setProfile(res.data.data);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    const updatePersonalInformation = (updatePersonal) => {
        refOpenPersonal.current.click();
        setEditPersonalInformation(updatePersonal);
    };

    const updatePropertyInformation = (updateProperty) => {
        refOpenProperty.current.click();
        setEditPropertyInformation(updateProperty);
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getProfile();
        document.title = `User Profile - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                {
                                    !profile ? "" : profile.map((element, index) => {
                                        return (
                                            <div className="app-content--inner" key={index}>
                                                <div className="page-title" id="profile-box-head">
                                                    <div className="display-2 font-weight-bold text-center pt-2">
                                                        {
                                                            element.PropertyType === "Hotel" ? <img src="assets/images/users/hotel.png" style={{ height: "75px" }} alt="user-profile" /> : element.PropertyType === "Bar/Restaurant" ? <img src="assets/images/users/restaurant.png" style={{ height: "75px" }} alt="user-profile" /> : element.PropertyType === "Cafe" ? <img src="assets/images/users/cafe.png" style={{ height: "75px" }} alt="user-profile" /> : element.PropertyType === "Educational" ? <img src="assets/images/users/educational.png" style={{ height: "75px" }} alt="user-profile" /> : element.PropertyType === "Buisness" ? <img src="assets/images/users/business.png" style={{ height: "75px" }} alt="user-profile" /> : element.PropertyType === "Retail" ? <img src="assets/images/users/retailer.png" style={{ height: "75px" }} alt="user-profile" /> : element.PropertyType === "Household" ? <img src="assets/images/users/household.png" style={{ height: "75px" }} alt="user-profile" /> : <></>
                                                        }
                                                    </div>
                                                    <div className="display-2 font-weight-bold text-center"
                                                        style={{ fontSize: "24px" }}>
                                                        {element.PropertyName}
                                                    </div>
                                                    <div className="card-title font-weight-bold text-center">
                                                        {"ECO" + element.auto_id}
                                                    </div>
                                                    <div className="card-title font-weight-bold text-center pb-2">
                                                        <i className="fas fa-regular fa-check-double"></i> <span style={{ fontSize: "20px" }}>
                                                            {element.IsAdmin === 0 ? "Default" : element.IsAdmin === 1 ? "Primary" : "User"}</span>
                                                    </div>
                                                </div>
                                                <div className="container">
                                                    <div className="main-body">
                                                        <div className="row gutters-sm">
                                                            <div className="col-md-12">
                                                                <div className="card text-center">
                                                                    <div className="card-body">
                                                                        <div className="card card-body">
                                                                            <div style={{ textAlign: "right" }}>
                                                                                <i className="far fa-edit mx-2 cp" onClick={() => { updatePersonalInformation(element) }}></i>
                                                                            </div>
                                                                            <div className="text-center font-weight-bold mb-2" style={{ fontSize: "18px" }}>
                                                                                Personal Information
                                                                            </div>
                                                                            <div className="row p-1">
                                                                                <div className="col-sm-6 text-center align-center">
                                                                                    <span className="text-center font-weight-bold">
                                                                                        Name
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-sm-6 text-secondary text-center">
                                                                                    <span style={{ color: "black" }}>
                                                                                        {element.Name}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row p-1">
                                                                                <div className="col-sm-6 text-center align-center">
                                                                                    <span className="text-center font-weight-bold">
                                                                                        Number
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-sm-6 text-secondary text-center">
                                                                                    <span style={{ color: "black" }}>
                                                                                        {element.Number}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row p-1">
                                                                                <div className="col-sm-6 text-center align-center">
                                                                                    <span className="text-center font-weight-bold">
                                                                                        Email Id
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-sm-6 text-secondary text-center">
                                                                                    <span style={{ color: "black" }}>
                                                                                        {element.Email}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card card-body">
                                                                            {
                                                                                element.IsAdmin === 1 ? <div style={{ textAlign: "right" }}>
                                                                                    <i className="far fa-edit mx-2 cp" onClick={() => { updatePropertyInformation(element) }}></i>
                                                                                </div> : <div></div>
                                                                            }
                                                                            <div className="text-center font-weight-bold mb-2" style={{ fontSize: "18px" }}>
                                                                                Property Information
                                                                            </div>
                                                                            <div className="row p-1">
                                                                                <div className="col-sm-6 text-center">
                                                                                    <span className="text-center font-weight-bold">
                                                                                        Owner Name
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-sm-6 text-secondary text-center">
                                                                                    <span style={{ color: "black" }}>
                                                                                        {element.ContactPerson}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row p-1">
                                                                                <div className="col-sm-6 text-center align-center">
                                                                                    <span className="text-center font-weight-bold">
                                                                                        Dustbins Status
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-sm-6 text-secondary text-center">
                                                                                    <span style={{ color: "black" }}>
                                                                                        {element.DustbinStatus === 0 ? <div className="text-blue"><NavLink to="/apply-dustbins">Apply Now</NavLink>
                                                                                        </div> : element.DustbinStatus === 1 ? <div className="text-green">{element.RequiredDustbin + " Dustbins requested"}</div> : element.DustbinStatus === 2 ? <div className="text-warnings">{element.RequiredDustbin + " Under processing"}</div> : element.DustbinStatus === 3 ? <div className="text-green">{element.NoDustbinPlaced + " Dustbins placed"}</div> : element.DustbinStatus === 4 ? <div className="text-red">Rejected</div> : <div className="text-red">Removed</div>}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row p-1">
                                                                                <div className="col-sm-6 text-center">
                                                                                    <span className="text-center font-weight-bold">
                                                                                        Address
                                                                                    </span>
                                                                                </div>
                                                                                <div className="col-sm-6 text-secondary text-center">
                                                                                    <span style={{ color: "black" }}>
                                                                                        {element.Address}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="p-2">
                                                                                <div className="row p-1"
                                                                                    style={{ backgroundColor: "#fcf6f6" }}>
                                                                                    <div className="col-sm-6 text-center align-center">
                                                                                        <span className="text-center font-weight-bold">
                                                                                            Total Waste &nbsp;
                                                                                            <i className="fa fa-recycle text-success font-weight-bold"></i>
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className="col-sm-6 text-secondary text-center">
                                                                                        <span style={{ color: "black" }}>
                                                                                            {element.TotalWaste} Kgs
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
            }
            <ProfilePersonalInformation refOpenPersonal={refOpenPersonal} apiDashboardUrl={apiDashboardUrl} editPersonalInformation={editPersonalInformation} userId={userId} />
            <ProfilePropertyInformation refOpenProperty={refOpenProperty} apiDashboardUrl={apiDashboardUrl} editPropertyInformation={editPropertyInformation} userId={userId} />
        </>
    )
}

export default Profile;