import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Spinner from "../components/Spinner";

const Shop = ({ title }) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        document.title = `Shop - ${title}`;
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="container-fluid main_header">
                        <div className="rows">
                            <div className="col-md-10 col-12 mx-auto">
                                <div className="rows">
                                    <div className="col-md-12 col-12 main_header_left align-items-center">
                                        <figure className="errimg">
                                            <img className="img-fluid" src="assets/images/under-construction.gif" style={{ width: "100%" }} alt="under-construction-response" />
                                        </figure>
                                        <p className="text-center">Welcome to Ecowrap Website</p>
                                        <h1 className="text-center">Website is under development</h1>
                                        <NavLink to="/dashboard"><button>Go to dashboard</button></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Shop;