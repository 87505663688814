import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const AboutUs = ({ title }) => {

    useEffect(() => {
        AOS.init({ duration: 1000, offset: 120 });
        window.scrollTo(0, 0);
        document.title = `About Us - ${title}`;
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <section id="about">
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-8">
                        <br />
                        <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">WHO</span> <span className="our-investors-span2">ARE WE ?</span></div>
                        <h4 className="fonts-poppins text-black text-center" id="aboutustext2">
                            ECOWRAP is at the forefront of waste management, driven by cutting-edge technology and a core focus on source segregation. The platform is committed to incentivizing and promoting waste segregation through its unique AI-powered rewards system. This system recognizes and rewards users based on their segregation practices, fostering a culture of sustainable waste management.
                        </h4>
                    </div>
                </div>
            </section>
            <div className="section_bg_gray mt-5">
                <div className="container2">
                    <div className="row text-center">
                        <div className="col-md-5-5">
                            <div className="card2" data-aos="flip-right">
                                <div>
                                    <div className="our-investors pt-5 pb-1"><span className="our-investors-span1">Our</span> <span className="our-investors-span2">Mission</span></div>
                                </div>
                                <div className="card-body text-left">
                                    <p className="fonts-poppins text-center" id="aboutustext1">
                                        Cultivating eco-conscious communities, innovate waste collection, and advance toward a zero-dumping society, aiming to conserve resources and minimize environmental impact.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-5-5">
                            <div className="card2" data-aos="flip-left">
                                <div>
                                    <div className="our-investors pt-5 pb-1"><span className="our-investors-span1">Our</span> <span className="our-investors-span2">Vision</span></div>
                                </div>
                                <div className="card-body text-left">
                                    <p className="fonts-poppins  text-center" id="aboutustext1">
                                        To pioneer a waste-free future, where every individual contributes to sustainable waste management practices, fostering a world where waste is viewed as a valuable resource.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray mt-5">
                <div className="container2">
                    <div className="row">
                        <div className="col-md-6" data-aos="flip-right">
                            <img className="img-fluid about-images pt-aboutus-problem shadow-aboutus" src="assets/images/aboutUs/aboutus-problem.png" alt="ecowrap-problems" />
                        </div>
                        <div className="col-md-6">
                            <div id="aboutusheadings2">Problems</div>
                            <p className="fonts-poppins text-left pt-20" id="aboutustext2">
                                ECOWRAP addresses the challenge of unsegregated waste, hindering proper treatment and recycling. Current technologies fall short, emphasizing the need for precise source segregation. Post-collection mechanical separation struggles, and the high cost of washing mixed recyclables hampers recycling. ECOWRAP employs innovative waste management and source segregation strategies to overcome these challenges, promoting effective and sustainable waste disposal practices.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray mt-5">
                <div className="container2">
                    <div className="row">
                        <div className="col-md-6">
                            <div id="aboutusheadings2">Our Approach</div>
                            <p className="fonts-poppins text-left pt-20" id="aboutustext2">
                                ECOWRAP employs a multifaceted approach to address the challenge of unsegregated waste in the following ways:
                                <br />
                                1.	Building efficient infrastructure to promoting a behavioural shift in sustainable solution.
                                <br />
                                2.	Artificial intelligence driven Waste Segregation ratings.
                                <br />
                                3.	Optimized Waste Collection Routes through smart APIs
                                <br />
                                4.	Data Analytics for Continuous Improvement.
                                <br />
                                By combining technology, education, incentives, and data-driven insights, ECOWRAP seeks to revolutionize waste management practices and establish a ‘Zero Dumping’
                            </p>
                        </div>
                        <div className="col-md-6" data-aos="flip-left">
                            <img className="img-fluid about-images pt-aboutus-problem shadow-aboutus2" src="assets/images/aboutUs/aboutus-ourapproch.png" alt="ecowrap-solutions" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray mt-5">
                <div className="row" style={{ width: "100%", margin: "auto" }}>
                    <div className="col-md-4" id="aboutus-pbe-bkgimage">
                        <div className="padding-peoplebehindecowrap"></div>
                    </div>
                    <div className="col-md-7-5 background-color-aboutus-peoplebehindecowrap">
                        <div>
                            <div className="row" style={{ width: "100%", margin: "auto" }}>
                                <span className="card-aboutus-pbe col-md-5">
                                    <div className="row">
                                        <span className="col-5 text-center" data-aos="fade-up">
                                            <div className="service-cardsss">
                                                <div className="service-card-img">
                                                    <img src="assets/images/aboutUs/angraj-sir-ecowrap.png" alt="ecowrap-angraj-sir" />
                                                </div>
                                            </div>
                                        </span>
                                        <span className="col-7 text-center" data-aos="fade-down">
                                            <h4 className="title-pbe">Angraj Swami</h4>
                                            <p className="designation-pbe">
                                                Founder - <br /> Head of execution
                                            </p>
                                            <a href="https://www.linkedin.com/in/angrajswami/" target="_blank" rel="noopener noreferrer">
                                                <i className="aboutus-icon fa fa-linkedin"></i>
                                            </a>
                                        </span>
                                    </div>
                                </span>
                                <span className="col-md-7"></span>
                            </div>
                            <div className="row" style={{ width: "100%", margin: "auto" }}>
                                <span className="col-md-7"></span>
                                <span className="card-aboutus-pbe col-md-5">
                                    <div className="row">
                                        <span style={{ marginBottom: 50 }} className="col-5 text-center" data-aos="fade-up">
                                            <div className="service-cardsss">
                                                <div className="service-card-img">
                                                    <img src="assets/images/aboutUs/ajay-sir-ecowrap.png" alt="ecowrap-ajay-sir" />
                                                </div>
                                            </div>
                                        </span>
                                        <span className="col-7 text-center" data-aos="fade-down">
                                            <h4 className="title-pbe">Ajay Buri</h4>
                                            <p className="designation-pbe">
                                                Co-Founder - Head of product development & operations
                                            </p>
                                            <a href="https://www.linkedin.com/in/ajayburi/" target="_blank" rel="noopener noreferrer">
                                                <i className="aboutus-icon fa fa-linkedin"></i>
                                            </a>
                                        </span>
                                    </div>
                                </span>
                            </div>
                            <div className="row" style={{ width: "100%", margin: "auto" }}>
                                <span className="card-aboutus-pbe col-md-5">
                                    <div className="row">
                                        <span className="col-5 text-center" data-aos="fade-up">
                                            <div className="service-cardsss">
                                                <div className="service-card-img">
                                                    <img src="assets/images/aboutUs/c-kant-sir-ecowrap.png" alt="ecowrap-ck-sir" />
                                                </div>
                                            </div>
                                        </span>
                                        <span className="col-7 text-center" data-aos="fade-down">
                                            <h4 className="title-pbe">C kant Swami</h4>
                                            <p className="designation-pbe">
                                                Co-Founder - Head of reverse supply chain
                                            </p>
                                            <a href="https://www.linkedin.com/in/c-kant/" target="_blank" rel="noopener noreferrer">
                                                <i className="aboutus-icon fa fa-linkedin"></i>
                                            </a>
                                        </span>
                                    </div>
                                </span>
                                <span className="col-md-7"></span>
                            </div>
                            <div className="row" style={{ width: "100%", margin: "auto" }}>
                                <span className="col-md-7"></span>
                                <span style={{ marginBottom: 15 }} className="card-aboutus-pbe col-md-5">
                                    <div className="row">
                                        <span className="col-5 text-center" data-aos="fade-up">
                                            <div className="service-cardsss">
                                                <div className="service-card-img">
                                                    <img src="assets/images/aboutUs/manoj-sir-ecowrap.png" alt="ecowrap-manoj-sir" />
                                                </div>
                                            </div>
                                        </span>
                                        <span className="col-7 text-center" data-aos="fade-down">
                                            <h4 className="title-pbe">Manoj Sabu</h4>
                                            <p className="designation-pbe">
                                                Co-Founder - <br /> Head of business
                                            </p>
                                            <a href="https://www.linkedin.com/in/manojsabu/" target="_blank" rel="noopener noreferrer">
                                                <i className="aboutus-icon fa fa-linkedin"></i>
                                            </a>
                                        </span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AboutUs;