import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const ErrorPage = ({ title }) => {

    useEffect(() => {
        document.title = `404 Error | Page Not Found - ${title}`;
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="container-fluid main_header">
                <div className="rows">
                    <div className="col-md-10 col-12 mx-auto">
                        <div className="rows">
                            <div className="col-md-12 col-12 main_header_left align-items-center">
                                <figure className="errimg">
                                    <img className="img-fluid" src="assets/images/404-ecowrap.png" alt="error-response" />
                                </figure>
                                <p className="text-center">Welcome to Ecowrap Website</p>
                                <h1 className="text-center">Opps! Page Not Found</h1>
                                <NavLink to="/"><button>Go to home</button></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorPage;