import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const Impact = ({ apiDashboardUrl, userId, title }) => {

    const [totalWaste, setTotalWaste] = useState();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()
    if (!userId) navigate('/send-otp')
    const getTotalWaste = async () => {
        try {
            await axios.get(`${apiDashboardUrl}/user/totalWaste?userId=${userId}`).then((res) => {
                if (res.data.status === "success") {
                    setTotalWaste(res.data.data.TotalWaste);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getTotalWaste();
        document.title = `Impact - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                <div className="app-content--inner container">
                                    <div className="page-title" id="impact-box-head">
                                        <h5 className="display-2">Impact</h5>
                                        <div className="card-title display-4 pt-3" id="impactnotbold">
                                            <span className="font-weight-bold" id="impactbold">
                                                {totalWaste < 1000 ? `${totalWaste + null} kgs ` : `${parseInt(totalWaste / 1000)} Tonnes`}
                                            </span> of waste collected from you saves our mother earth:
                                            <div className="divider mt-3" />
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="card card-body" id="impact-box">
                                                    <div className="container-blog-left">
                                                        <div className="align-center">
                                                            <img className="impact-image" src="assets/images/dashboard/tree-yard.svg" alt="tree-yard-response" />
                                                        </div>
                                                        <div className="align-center">
                                                            <h4 className="font-weight-bold">
                                                                {parseInt(totalWaste / 1000 * 5)}
                                                            </h4>
                                                            <h5>Trees</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="card card-body" id="impact-box">
                                                    <div className="container-blog-right">
                                                        <div className="align-center">
                                                            <img className="impact-image" src="assets/images/dashboard/ecologism-battery.svg" alt="ecologism-battery-response" />
                                                        </div>
                                                        <div className="align-center">
                                                            <h4 className="font-weight-bold">{parseInt(totalWaste / 1000 * 237)}</h4>
                                                            <h5 className="">Kwh of energy</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="card card-body" id="impact-box">
                                                    <div className="container-blog-left">
                                                        <div className="align-center">
                                                            <img className="impact-image" src="assets/images/dashboard/waterfall-river.svg" alt="waterfall-river-response" />
                                                        </div>
                                                        <div className="align-center">
                                                            <h4 className="font-weight-bold">{parseInt(totalWaste / 1000 * 0.06)}</h4>
                                                            <h5>Crore litres of water</h5>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Impact;