import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const ProfilePropertyInformation = ({ refOpenProperty, apiDashboardUrl, editPropertyInformation }) => {

    const [propertyId, setPropertyId] = useState("");
    const [propertyName, setPropertyName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [address, setAddress] = useState("");
    const [landMark, setLandmark] = useState("");
    const [floorStreet, setFloorStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPincode] = useState("");
    const [country, setCountry] = useState("");
    const [propertyType, setPropertyType] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const refCloseProperty = useRef(null);
    const navigate = useNavigate();

    const initialValue = () => {
        setPropertyId(editPropertyInformation.PropertyId);
        setPropertyName(editPropertyInformation.PropertyName);
        setContactPerson(editPropertyInformation.ContactPerson);
        setContactNumber(editPropertyInformation.ContactNumber);
        setAddress(editPropertyInformation.Address?.split(",")[0]);
        setLandmark(editPropertyInformation.Landmark);
        setFloorStreet(editPropertyInformation.FloorStreet);
        setCity(editPropertyInformation.City);
        setState(editPropertyInformation.State);
        setPincode(editPropertyInformation.Address?.split(",")[4]);
        setCountry(editPropertyInformation.Address?.split(",")[5]);
        setPropertyType(editPropertyInformation.PropertyType);
    };

    const getCurrentLatitudeLongitude = () => {
        navigator.geolocation.getCurrentPosition((posn) => {
            setLatitude(posn.coords.latitude);
            setLongitude(posn.coords.longitude);
        })
    };

    const refreshPage = () => window.location.reload(false);

    const updatePersonalInformation = async (e) => {

        e.preventDefault();

        await fetch(`${apiDashboardUrl}/user/updateProperty/${propertyId}`, {
            method: "PUT",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                PropertyName: propertyName,
                ContactPerson: contactPerson,
                ContactNumber: contactNumber,
                Address: address + ", " + landMark + ", " + city + ", " + state + ", " + pincode + ", " + country,
                Landmark: landMark,
                FloorStreet: floorStreet,
                City: city,
                State: state,
                PropertyType: propertyType,
                Latitude: latitude,
                Longitude: longitude,
                Direction: ""
            })
        }).then((res) => {
            res.json().then((data) => {
                const status = data.status;
                if (status === "success") {
                    swal("Success", data.message, "success").then((ok) => {
                        if (ok) {
                            navigate("/profile");
                            refCloseProperty.current.click();
                            refreshPage();
                        }
                    })
                } else {
                    swal("Error", data.message, "error");
                }
            })
        })
    };

    useEffect(() => {
        initialValue();
        getCurrentLatitudeLongitude();
        // eslint-disable-next-line
    }, [editPropertyInformation]);

    return (
        <>
            <button ref={refOpenProperty} type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#propertyModal"></button>

            <div className="modal fade" id="propertyModal" tabIndex="-1" aria-labelledby="propertyModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="propertyModalLabel">
                                Edit Property Information
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="mb-2">
                                <label htmlFor="propertyName" className="font-size-md form-label br-2rem">
                                    Property Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="propertyName"
                                    name="propertyName"
                                    value={propertyName}
                                    onChange={(e) => { setPropertyName(e.target.value) }}
                                    placeholder="eg: Ecowrap"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="contactPerson" className="font-size-md form-label br-2rem">
                                    Owner Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contactPerson"
                                    name="contactPerson"
                                    value={contactPerson}
                                    onChange={(e) => { setContactPerson(e.target.value) }}
                                    placeholder="eg: John doe"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="contactNumber" className="font-size-md form-label br-2rem">
                                    Owner Number
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="contactNumber"
                                    name="contactNumber"
                                    value={contactNumber}
                                    onChange={(e) => { setContactNumber(e.target.value) }}
                                    placeholder="eg: 9425006123"
                                    autoComplete="off"
                                    required
                                    disabled
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="address" className="font-size-md form-label br-2rem">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    name="address"
                                    value={address}
                                    onChange={(e) => { setAddress(e.target.value) }}
                                    placeholder="eg: 7A, Gangotri Nagar Gopalpura Bypass Road"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="landMark" className="font-size-md form-label br-2rem">
                                    Area
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="landMark"
                                    name="landMark"
                                    value={landMark}
                                    onChange={(e) => { setLandmark(e.target.value) }}
                                    placeholder="eg: Puliya Triveni Nagar"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="floorStreet" className="font-size-md form-label br-2rem">
                                    Floor/Street
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="floorStreet"
                                    name="floorStreet"
                                    value={floorStreet}
                                    onChange={(e) => { setFloorStreet(e.target.value) }}
                                    placeholder="eg: Floor and street"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="city" className="font-size-md form-label br-2rem">
                                    City
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    name="city"
                                    value={city}
                                    onChange={(e) => { setCity(e.target.value) }}
                                    placeholder="eg: Jaipur"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="state" className="font-size-md form-label br-2rem">
                                    State
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="state"
                                    name="state"
                                    placeholder="eg: Rajasthan"
                                    value={state}
                                    onChange={(e) => { setState(e.target.value) }}
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="pincode" className="font-size-md form-label br-2rem">
                                    Pincode
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="pincode"
                                    name="pincode"
                                    value={pincode}
                                    onChange={(e) => { setPincode(e.target.value) }}
                                    placeholder="eg: 302018"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="country" className="font-size-md form-label br-2rem">
                                    Country
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="country"
                                    name="country"
                                    value={country}
                                    onChange={(e) => { setCountry(e.target.value) }}
                                    placeholder="eg: India"
                                    autoComplete="off"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="propertyType" className="font-size-md form-label br-2rem">
                                    Select your property type
                                </label>
                                <select
                                    as="select"
                                    className="form-control br-2rem"
                                    id="propertyType"
                                    name="propertyType"
                                    value={propertyType}
                                    onChange={(e) => { setPropertyType(e.target.value) }}
                                    required
                                >
                                    <option value="">Select your property type:</option>
                                    <option value="Hotel">Hotel</option>
                                    <option value="Bar/Restaurant">Bar/Restaurant</option>
                                    <option value="Cafe">Cafe</option>
                                    <option value="Educational">Educational</option>
                                    <option value="Buisness">Buisness</option>
                                    <option value="Retail">Retail</option>
                                    <option value="Household">Household</option>
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-pickupschedule"
                                data-bs-dismiss="modal"
                                ref={refCloseProperty}>
                                Close
                            </button>
                            <button
                                type="submit"
                                className="btn btn-pickupschedule"
                                onClick={updatePersonalInformation}>
                                Update Property
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilePropertyInformation;