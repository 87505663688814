import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const ApplyDustbins = ({ apiDashboardUrl, userId, title }) => {

    const [propertyId, setPropertId] = useState([]);
    const [dustbinStatus, setDustbinStatus] = useState([]);
    const [requiredDustbin, setRequiredDustbin] = useState([]);
    const [noDustbinPlaced, setNoDustbinPlaced] = useState([]);
    const [loading, setLoading] = useState(false);
    const [num, setNum] = useState(0);
    const navigate = useNavigate();

    if (!userId) navigate('/send-otp')
    const getApplyDustbins = async () => {
        try {
            await axios.get(`${apiDashboardUrl}/user/getProperties?userId=${userId}`).then((res) => {
                if (res.data.status === "success") {
                    setPropertId(res.data.data[0].PropertyId);
                    setDustbinStatus(res.data.data[0].DustbinStatus);
                    setRequiredDustbin(res.data.data[0].RequiredDustbin);
                    setNoDustbinPlaced(res.data.data[0].NoDustbinPlaced);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    const decNum = () => {
        if (num > 0) setNum(num - 1);
        else setNum(0);
    };

    const incNum = () => {
        if (num < 9) setNum(num + 1);
        else setNum(10);
    };

    const requestDustbin = async (num) => {
        try {
            await axios.patch(`${apiDashboardUrl}/user/requestDustbin/${propertyId}`, {
                RequiredDustbin: num
            }).then((res) => {
                if (res.data.status === "success") {
                    swal("Success", res.data.message, "success").then((ok) => {
                        if (ok) {
                            navigate("/dashboard");
                        }
                    })
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    const removeDustbin = async (reason, discription) => {
        await axios.patch(`${apiDashboardUrl}/user/requestedForRemoveDustbin/${propertyId}`, {
            reason: reason,
            discription: discription
        }).then((res) => {
            if (res.data.status === "success") {
                swal("Success", res.data.message, "success").then((ok) => {
                    if (ok)
                        navigate("/apply-dustbins");
                })
            } else {
                swal("Error", res.data.message, "error");
            }
        }).catch((error) => {
            swal(error);
        })
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getApplyDustbins();
        document.title = `Apply Dustbins - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                <div className="app-content--inner">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <div className="card-footer-down">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="card-footer-down-text text-center text-green">
                                                            <b>“You need to segregate your waste in four categories.”</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <img className="img-fluid about-images" style={{ width: "100%" }} id="pickup-page-image" src="assets/images/dashboard/apply-dustbin.png" alt="pickup-history-response" />
                                    </div>
                                    <div className="row mt-2">
                                        <div style={{ fontSize: 20 }} className="footer-down-head">
                                            Number of dustbins you wants to apply for:
                                        </div>
                                        <div className="col-md-4"></div>
                                        {
                                            dustbinStatus === 0 ? <div className="col-md-4 text-center">
                                                <div className="card-footer-down">
                                                    <div className="card card-body text-light">
                                                        <div className="container-blog-left" style={{ height: "200px" }}>
                                                            <div className="h1-tag mt-2">{num}</div>
                                                            <div className="btn_div">
                                                                <button type="button" className="btn-tag" onClick={decNum}>-</button>
                                                                <button type="button" className="btn-tag" onClick={incNum}>+</button>
                                                            </div>
                                                            <div className="confirm-tag cp"
                                                                onClick={() =>
                                                                    swal("Info", `Are you sure you want to required ${num} dustbins`, "info", {
                                                                        buttons: ["No", "Yes"]
                                                                    }).then((value) => {
                                                                        if (value) {
                                                                            requestDustbin(value);
                                                                        }
                                                                    })
                                                                }
                                                            >
                                                                Confirm
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : dustbinStatus === 1 ? <div className="col-md-12 text-center">
                                                <div className="card-footer-down">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="card-footer-down-text text-center text-green">
                                                                <b>
                                                                    {`Your request for dustbins is ${requiredDustbin}. Our executive will contact you soon!`}
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : dustbinStatus === 3 ? <div className="col-md-12 text-center">
                                                <div className="card-footer-down">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="card-footer-down-text text-center text-green">
                                                                <b>
                                                                    {`We applied ${noDustbinPlaced} dustbins on your property!`}
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : dustbinStatus === 4 ? <div className="col-md-12 text-center">
                                                <div className="card-footer-down">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="card-footer-down-text text-center text-red">
                                                                <b>Your application has been rejected. If you have an query contact us!</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : dustbinStatus === 5 ? <div className="col-md-12 text-center">
                                                <div className="card-footer-down">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="card-footer-down-text text-center text-red">
                                                                <b>Dustbin has been removed from your property!</b>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : <div></div>
                                        }
                                        <div className="col-md-4"></div>
                                    </div>
                                </div>
                                {
                                    dustbinStatus === 3 ? <div className="text-center mb-3">
                                        <button className="btn btn-danger" style={{ verticalAlign: "bottom" }}
                                            onClick={() =>
                                                swal("Warning", "Are you sure you want to remove the dustbins!", "warning", {
                                                    buttons: ["No", "Yes"],
                                                    content: {
                                                        element: "input",
                                                        attributes: {
                                                            placeholder: "Enter the reason, why you want to remove dustbins?",
                                                            type: "text"
                                                        }
                                                    }
                                                }).then((value) => {
                                                    if (value) {
                                                        removeDustbin(value, "");
                                                    }
                                                })
                                            }
                                        >
                                            Remove Dustbins
                                        </button></div> : <div></div>
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default ApplyDustbins;