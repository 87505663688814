import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const RecycleRightDetails = ({ title }) => {

    useEffect(() => {
        document.title = `Recycle Right - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <section id="recycle-right"></section>
            <div className="section_bg_gray pb-30">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">#RECYCLE</span> <span className="our-investors-span2">RIGHT</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <img className="projects-image" src="assets/images/projects/recycle-right2-ecowrap.jpg" alt="ecowrap-recycle-right" />
                        </div>
                        <div className="col-md-12 pt-3">
                            <div className="verticals-mid text-justify">
                                <p className="fonts">
                                    Aluminium foil acts as a total barrier to light and oxygen, odours and flavours, moistness,
                                    and germs and so it is used broadly in food packaging. The purpose of aluminium is to make
                                    long-life packs for drinks and dairy goods, which allows storing without refrigeration. Due
                                    to its wide range of application aluminium foil become a very essential part of every
                                    commercial & house hold kitchen. Every year india consumes many million tone of aluminium
                                    foil in food packaging but the recycling rate is less than 25%.
                                    <br />
                                    <br />
                                    'Freshwrapp' brand of Aditya Birla Group have been taking many steps to build a reveres
                                    supply chain to bring back used aluminium food packaging items. Post consumed aluminium foil
                                    is least bothered and high tradable value commodity of municipal solid waste stream. Ecowrap
                                    is best stakeholder to define this problem statement & has wonderful past experience to deal
                                    with it. In order to this, Ecowrap & Aditya Birla group started a joint program to build and
                                    effective eco-system to bring post-consumed aluminium foil into recycling chain. This joint
                                    initiative called 'EcoAdi'. We aimed to tap 15000 people through schools, collages and high
                                    rise apartments. In this project both these organisation intend to build an effective
                                    infrastructure, reverse supply chain & awareness among targeted population.
                                    <br />
                                    <br />
                                    This project has been supported by Dr. B. Lal. Institute of Biotechnology and there 15
                                    student volunteers. Schools and high rise societies were active participant. We
                                    collected - metric ton of used aluminium foil and bring it back into value chain through
                                    recycling. It helped environment in saving metric ton of green house gas emission &
                                    reduced litres of gasoline consumption.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default RecycleRightDetails;