import React, { useState, useEffect } from "react";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const HowToEarn = ({ title }) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        document.title = `How To Earn - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                <div className="app-content--inner container">
                                    <div className="page-title" id="impact-box-head">
                                        <h5 className="display-2">How To Earn</h5>
                                    </div>
                                    <div className="container text-center">
                                        <div className="card card-body" id="howToEarnBox">
                                            <div className="container-blog-left">
                                                <div className="row text-left">
                                                    <div className="col-md-4">
                                                        <img className="img-fluid blog-blogs-image" src="assets/images/dashboard/plastic-waste.png" alt="plastic-waste" />
                                                    </div>
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-5 mb-3">
                                                        <h3 className="text-center">Plastic Waste</h3>
                                                        <div className="card-howToEarn home-route-text text-center">
                                                            Pet bottles, cold drink bottles, mineral water bottles, HDPE harpic bottles, shampoo bottles, dahi cans etc.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card card-body" id="howToEarnBox">
                                            <div className="container-blog-right">
                                                <div className="row text-right" id="howToEarn">
                                                    <div className="col-md-5 mb-3">
                                                        <h3 className="text-center">Glass Waste</h3>
                                                        <div className="card-howToEarn home-route-text text-center">
                                                            Glass bottles, broken glass, empty liquor bottles, windows glass, vehicle bottles etc.
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-4">
                                                        <img className="img-fluid blog-blogs-image" src="assets/images/dashboard/glass-waste.png" alt="glass-waste" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card card-body" id="howToEarnBox">
                                            <div className="container-blog-left">
                                                <div className="row text-left" id="howToEarn">
                                                    <div className="col-md-4">
                                                        <img className="img-fluid blog-blogs-image" src="assets/images/dashboard/waste-food.png" alt="waste-food" />
                                                    </div>
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-5 mb-3">
                                                        <h3 className="text-center">Waste Food</h3>
                                                        <div className="card-howToEarn home-route-text text-center">
                                                            Organic waste, or food waste, consists of vegtables peels, leftovers, dried leaves, garden wastes etc.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card card-body" id="howToEarnBox">
                                            <div className="container-blog-right">
                                                <div className="row text-right" id="howToEarn">
                                                    <div className="col-md-5 mb-3">
                                                        <h3 className="text-center">Mix Waste</h3>
                                                        <div className="card-howToEarn home-route-text text-center">
                                                            Tetra packs, multi colour film, kurkure/chips packets, sauce packets, shampoo sheshe etc.
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-4">
                                                        <img className="img-fluid blog-blogs-image" src="assets/images/dashboard/mix-waste.png" alt="mix-waste" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default HowToEarn;