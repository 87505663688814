import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const AwardsAndMedia = ({ title }) => {

    useEffect(() => {
        document.title = `Awards & Media - ${title}`;
        AOS.init({ duration: 1000, offset: 120 });
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <section id="awards-media">
                <div className="container2">
                    <div className="our-investors pt-30 pb-30 text-left"><span className="our-investors-span1">AWARDS</span> <span className="our-investors-span2">AND RECOGNITION</span></div>
                </div>
                <div className="awards-media-sub">
                    <div className="row" style={{ width: "100%", margin: "auto" }}>
                        <div className="col-md-6" style={{ width: "100%" }}>
                            <img style={{ width: "100%" }} src="assets/images/mediaAndAwards/media-awards-ecowrap.png" alt="top-sub-background" />
                        </div>
                        <div className="col-md-6" style={{ width: "100%" }}>
                            <div className="mediaawards-top-text pb-30">
                                Our journey has been tough, but our efforts do not go unnoticed.
                                We are being noticed for what we do, and we want to use this
                                opportunity to further encourage people to help us in our
                                campaign to make our country dump free.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="section_bg_gray" >
                <div className="container2">
                    <div className="row text-center">
                        <div className="col-sm-6 col-md-4" data-aos="zoom-in">
                            <div className="manda2 mediaawards-awards-image">
                                <img src="assets/images/mediaAndAwards/awards-ecowrap-5.png" alt="ecowrap-awards" />
                            </div>
                            <div className="mediaawards-awards-text">
                                Winner at Youth Co-lab 2020 by United Nation
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4" data-aos="zoom-in">
                            <div className="manda2 mediaawards-awards-image">
                                <img src="assets/images/mediaAndAwards/awards-ecowrap-3.png" alt="ecowrap-awards" />
                            </div>
                            <div className="mediaawards-awards-text">
                                Finalist at World Tourism Forum Lucerne Switzerland 2019
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4" data-aos="zoom-in">
                            <div className="mediaawards-awards-image">
                                <img src="assets/images/mediaAndAwards/awards-ecowrap-1.png" alt="ecowrap-awards" />
                            </div>
                            <div className="mediaawards-awards-text">
                                Winner at Tata Institution of Social Science 2019
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-sm-6 col-md-4" data-aos="zoom-in">
                            <div className="manda2 mediaawards-awards-image">
                                <img src="assets/images/mediaAndAwards/awards-ecowrap-4.png" alt="ecowrap-awards" />
                            </div>
                            <div className="mediaawards-awards-text">
                                Finalist at Climate Launchpad <br /> 2020
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4" data-aos="zoom-in">
                            <div className="manda2 mediaawards-awards-image">
                                <img style={{ width: "100%" }} src="assets/images/mediaAndAwards/awards-ecowrap-2.png" alt="ecowrap-awards" />
                            </div>
                            <div className="mediaawards-awards-text">
                                Finalist at TTMM IIT Bombay <br /> 2019
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4" data-aos="zoom-in">
                            <div className="manda2 mediaawards-awards-image">
                                <a href="https://swachhatastartupchallenge.com/index.html" target="_blank" rel="noopener noreferrer">
                                    <img style={{ width: "100%", height: "90%" }} src="assets/images/mediaAndAwards/awards-ecowrap-6.png" alt="ecowrap-awards" />
                                </a>
                            </div>
                            <div className="mediaawards-awards-text">
                                Swachhata Startup Challenge <br /> 2022
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="container2">
                    <div className="container2">
                        <div className="our-investors pt-30 pb-30"><span className="our-investors-span1">MEDIA</span> <span className="our-investors-span2">AND PRESS</span></div>
                    </div>
                    <div className="row text-center pb-30">
                        <div className="col-sm-12 col-md-6 pb-30" data-aos="fade-up">
                            <div className="awardsmedia-sub-sub-head">
                                Articles & Social Role
                            </div>
                            <br />
                            <div className="awardsmedia-sub-sub-image">
                                <a href="assets/images/mediaAndAwards/media1-ecowrap.jpeg">
                                    <img className="aandm-image" src="assets/images/mediaAndAwards/media1-ecowrap.jpeg" alt="ecowrap-media" />
                                </a>
                            </div>
                            <br />
                            <div className="awardsmedia-sub-sub-text">
                                Ecowrap has emerged as a one stop solution for waste segregation, collection, tracking, recycling and up-cycling.
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 pb-30" data-aos="fade-up">
                            <div className="awardsmedia-sub-sub-head">
                                Social & Startup Story
                            </div>
                            <br />
                            <div className="awardsmedia-sub-sub-image">
                                <a href="assets/images/mediaAndAwards/media2-ecowrap.jpeg">
                                    <img className="aandm-image" src="assets/images/mediaAndAwards/media2-ecowrap.jpeg" alt="ecowrap-media" />
                                </a>
                            </div>
                            <br />
                            <div className="awardsmedia-sub-sub-text">
                                How this waste management startup is on a mission to make the country clean and garbage free.
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 pb-30" data-aos="fade-up">
                            <div className="aandm-head-1 awardsmedia-sub-sub-head">
                                Making Bharat Swachh & Pretty
                            </div>
                            <br />
                            <div className="awardsmedia-sub-sub-image">
                                <a href="https://yourstory.com/2019/03/swachh-bharat-jaipur-ecowrap-oyo-waste-management-w79ucyzxfw/amp" target="_blank" rel="noopener noreferrer">
                                    <img className="aandm-image-2" src="assets/images/mediaAndAwards/media3-ecowrap.png" alt="ecowrap-media" />
                                </a>
                            </div>
                            <br />
                            <div className="aandm-text-1 awardsmedia-sub-sub-text">
                                Jaipur based ecowrap is upcycling waste, generating employment. Making Bharat Swachh & Pretty.
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 pb-30" data-aos="fade-up">
                            <div className="aandm-head-1 awardsmedia-sub-sub-head">
                                The Country Clean & Garbage Free
                            </div>
                            <br />
                            <div className="awardsmedia-sub-sub-image">
                                <a href="https://startupstorymedia.com/stories-2021-09-ecowrap-angraj-manoj-sabu-chandrakant-ajayswami-startup-story/" target="_blank" rel="noopener noreferrer">
                                    <img className="aandm-image-2" src="assets/images/mediaAndAwards/media4-ecowrap.jpg" alt="ecowrap-media" />
                                </a>
                            </div>
                            <br />
                            <div className="aandm-text-1 awardsmedia-sub-sub-text">
                                How this waste management startup is on a mission to make the country clean and garbage free.
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-sm-12 col-md-6 pb-30" data-aos="fade-up">
                            <div className="service-cards">
                                <div className="service-card-im">
                                    <iframe width="350" height="250" src="https://www.youtube.com/embed/cAtRw9CE-Yg" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                                <h4 className="title">The journey and force behind Ecowrap</h4>
                                <span className="service-line"></span>
                                <p className="fonts">
                                    The journey and force behind ecowrap, waste management startup in jaipur.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6  pb-30" data-aos="fade-up">
                            <div className="service-cards">
                                <div className="service-card-im">
                                    <iframe width="350" height="250" src="https://www.youtube.com/embed/CkMkUA-bj2o" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                                <h4 className="title">Ecowrap @ WTFL (Switzerland)</h4>
                                <span className="service-line"></span>
                                <p className="fonts">
                                    Ecowrap representing India in WTFL (Switzerland).
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-3 col-md-12 pt-30 pb-30" data-aos="fade-up">
                            <div className="service-cards">
                                <div className="service-card-im">
                                    <iframe width="350" height="250" src="https://www.youtube.com/embed/NrhUIa7_euc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                                <h4 className="title">
                                    Angraj Swami । EcoWrap । Rajasthan News
                                </h4>
                                <span className="service-line"></span>
                                <p className="fonts">
                                    Jaipur start up । क्या कोई कचरे से भी पैसा कमा सकता है ? । Angraj Swami । Ecowrap । Rajasthan News
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AwardsAndMedia;