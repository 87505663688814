import React, { useState, useRef, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";
import Mess from "../Mess";
import CurrentPickup from "./CurrentPickup";
import NavbarForDashboard from "./NavbarForDashboard";
import SidebarForDashboard from "./SidebarForDashboard";
import Spinner from "../../components/Spinner";
import image1 from "../../images/dashboardimagenew1.png";
import image2 from "../../images/dashboardimagenew2.png";

const Dashboard = ({ apiDashboardUrl, userId, title }) => {

    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const timeoutRef = useRef(null);
    const navigate = useNavigate();
    const images = [image1, image2];



    console.log("userId", userId);
    const getCheckAdmin = async () => {
        try {

            await axios.get(`${apiDashboardUrl}/user/checkAdmin/${userId}`).then((res) => {
                if (res.data.status === "success") {
                    if (res.data.data === null) {
                        swal("Error", res.data.message, "error");
                    } else {
                        if (res.data.data[0].IsActive === 0) {
                            if (res.data.data[0].PropertyId === "") {
                                navigate("/add-address");
                            }
                        } else {
                            navigate("/blocked");
                        }
                    }
                } else {
                    console.log('res.data.message', res.data.message);
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            console.log('error', error);
            swal(error);
        }
    };

    const resetTimeout = () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(() =>
            setIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            ), 5000);
        return () => resetTimeout();
        // eslint-disable-next-line
    }, [index]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getCheckAdmin();
        document.title = `Dashboard - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);
    if (!userId) {
        navigate('/send-otp')
        return
    }
    return (
        <>
            <Mess />
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                <div className="app-content--inner">
                                    <div className="slideshow">
                                        <div className="slideshowSlider" style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
                                            {
                                                !images ? "" : images.map((image, index) => (
                                                    <img className="img-fluid slide" src={image} key={index} alt="dashboard-response"></img>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-4">
                                            <div className="card card-body" id="dashboard-wallet-impact-quantity-box">
                                                <div className="container-dashboard-left">
                                                    <NavLink to="/wallet">
                                                        <div className="card-body">
                                                            <div className="d-flex align-items-start">
                                                                <div>
                                                                    <div className="font-size-lg" id="dashboard-wallet-impact-quantity-text">
                                                                        Wallet
                                                                    </div>
                                                                    <div className="font-size-md" id="dashboard-wallet-impact-quantity-text">
                                                                        Money Earned
                                                                    </div>
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <div className="bg-white font-size-lg d-50 rounded-circle">
                                                                        <i className="fas fa-wallet"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card card-body" id="dashboard-wallet-impact-quantity-box">
                                                <div className="container-dashboard-right">
                                                    <NavLink to="/impact">
                                                        <div className="card-body">
                                                            <div className="d-flex align-items-start">
                                                                <div>
                                                                    <div className="font-size-lg" id="dashboard-wallet-impact-quantity-text">
                                                                        Impact
                                                                    </div>
                                                                    <div className="font-size-md" id="dashboard-wallet-impact-quantity-text">
                                                                        Trees Saved
                                                                    </div>
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <div className="bg-white font-size-lg d-50 rounded-circle">
                                                                        <i className="far fa-snowflake"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card card-body" id="dashboard-wallet-impact-quantity-box">
                                                <div className="container-dashboard-left">
                                                    <NavLink to="/howToEarn">
                                                        <div className="card-body">
                                                            <div className="d-flex align-items-start">
                                                                <div>
                                                                    <div className="font-size-lg" id="dashboard-wallet-impact-quantity-text">
                                                                        How To Earn
                                                                    </div>
                                                                    <div className="font-size-md" id="dashboard-wallet-impact-quantity-text">
                                                                        Waste Managed
                                                                    </div>
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <div className="bg-white font-size-lg d-50 rounded-circle">
                                                                        <i className="fas fa-battery-three-quarters"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CurrentPickup apiDashboardUrl={apiDashboardUrl} userId={userId} />
                                    <div id="headers">
                                        <NavLink to="/schedule-pickup" className="btn-schedule-pickup">
                                            Schedule Pickup <i className="fa-solid fa-truck"></i>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Dashboard;