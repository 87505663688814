import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const PickupHistory = ({ apiDashboardUrl, userId, title }) => {

    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    if (!userId) navigate('/send-otp')

    var pages = 1;

    const pageMin = () => {
        pages = page - 1;
        getAllPickup(pages);
    };

    const pageAdd = () => {
        pages = page + 1;
        getAllPickup(pages);
    };

    const getAllPickup = async (pageNumber) => {
        try {
            await axios.get(`${apiDashboardUrl}/user/pickupsHistory?userId=${userId}&page=${pageNumber}`).then((res) => {
                if (res.data.status === "success") {
                    setPosts(res.data.data);
                    setTotalPage(res.data.TotalPages);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getAllPickup(pages);
        document.title = `Pickup History - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                {
                                    posts.length === 0 ? <div className="row">
                                        <div className="col-md-3 col-xl-3"></div>
                                        <div className="col-md-6 col-xl-6 align-items-center">
                                            <img src="assets/images/dashboard/data-not-found.png" alt="data-not-found"></img>
                                            <div style={{ fontSize: 20, textAlign: "center" }} className="footer-down-head">
                                                No data found at that moment:
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-xl-3"></div>
                                    </div> : <div className="app-content--inner">
                                        <div className="container row margin-auto">
                                            <div className="col-md-12 text-center" >
                                                <img className="img-fluid about-images" style={{ width: "50%" }} id="pickup-page-image" src="assets/images/dashboard/pickup-history.png" alt="pickup-history-response" />
                                            </div>
                                        </div>
                                        <div className="conntainer row pickups-up">
                                            <span className="col-md-12 text-center">ALL PICKUPS</span>
                                        </div>
                                        <div className="conntainer row pickup-table">
                                            <span className="col-lg-2 text-center">Sr. No.</span>
                                            <span className="col-lg-2 text-center">Property Name</span>
                                            <span className="col-lg-2 text-center">Status</span>
                                            <span className="col-lg-2 text-center">Driver Name</span>
                                            <span className="col-lg-2 text-center">Pickup Timing</span>
                                            <span className="col-lg-2 text-center">Details</span>
                                        </div>
                                        {
                                            !posts ? "" : posts.map((data, index) => {
                                                return (
                                                    <div key={index} className="conntainer row pickups-table">
                                                        <span className="col-lg-2 text-center">
                                                            {index + 1 + "."}
                                                        </span>
                                                        <span className="col-lg-2 text-center">
                                                            {data.PropertyName}
                                                        </span>
                                                        <span className="col-lg-2 text-center">
                                                            {data.PickupStatus === "0" ? <span className="badge badge-warning">Under verification</span> : data.PickupStatus === "1" ? <span className="badge badge-danger">Rejected</span> : data.PickupStatus === "2" ? <span className="badge badge-primary">Booked</span> : data.PickupStatus === "3" ? <span className="badge badge-info">Started</span> : data.PickupStatus === "4" ? <span className="badge badge-dark">Reached</span> : data.PickupStatus === "5" ? <span className="badge badge-success">Completed</span> : data.PickupStatus === "6" ? <span className="badge badge-danger">Cancelled</span> : data.PickupStatus === "7" ? <span className="badge badge-warning">Empty Pickup</span> : data.PickupStatus === "8" ? <span className="badge badge-second">Unsegregated Waste</span> : "Not yet updated"}
                                                        </span>
                                                        <span className="col-lg-2 text-center">
                                                            {data.PickupStatus === "5" ? data.DriverName : data.PickupStatus === "7" ? data.DriverName : data.PickupStatus === "8" ? data.DriverName : "Eco Driver"}
                                                        </span>
                                                        <span className="col-lg-2 text-center">
                                                            {moment(data.PickupTime).format("DD-MM-YYYY | hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}
                                                        </span>
                                                        <span className="col-lg-2 text-center">
                                                            <div className="clickable cp" onClick={() => {
                                                                navigate("/pickup-history-details", { state: data })
                                                            }}>
                                                                See details
                                                            </div>
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="container" id="paginationnumbers">
                                            <div className="pagination" id="paginationnumbers">
                                                <div className="page-item text-left">
                                                    <button disabled={page <= 1} className="page-link" onClick={() => {
                                                        pageMin();
                                                        page === 1 ? (
                                                            <div></div>
                                                        ) : (
                                                            setPage(page - 1)
                                                        );
                                                    }}>
                                                        &laquo; Previous
                                                    </button>
                                                </div>
                                                <div className="page-item">
                                                    <div className="page-link">
                                                        {page} of {totalPage}
                                                    </div>
                                                </div>
                                                <div className="page-item text-left">
                                                    <button disabled={page === totalPage} className="page-link" onClick={() => {
                                                        pageAdd();
                                                        page === totalPage ? (
                                                            <div></div>
                                                        ) : (
                                                            setPage(page + 1)
                                                        );
                                                    }}>
                                                        Next &raquo;
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default PickupHistory;