import React, { useState } from "react";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
import ReactNotificationComponent from "./ReactNotificationComponent";
import { onMessageListener } from "../firebaseInit";

const Mess = () => {

    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: "", body: "" });

    onMessageListener().then((payload) => {
        setShow(true);
        setNotification({
            title: payload.notification.title,
            body: payload.notification.body,
        });
        setShow(false);
    }).catch((error) => swal(error));

    return (
        <>
            {
                show ? (
                    <ReactNotificationComponent
                        title={notification.title}
                        body={notification.body}
                    />
                ) : (
                    <></>
                )
            }
        </>
    )
}

export default Mess;