import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const ContactUs = ({ apiUrl, title }) => {

    const [contact, setContact] = useState({ name: "", email: "", query: "", message: "" });
    const [number, setNumber] = useState("");
    const [resume, setResume] = useState("");
    const navigate = useNavigate();

    const postData = async (e) => {
        e.preventDefault();
        try {
            const data = new FormData();
            data.append("Name", contact.name);
            data.append("Number", number);
            data.append("Email", contact.email);
            data.append("Message", contact.message);
            data.append("Query", contact.query);
            data.append("resume", resume);
            e.preventDefault();
            await fetch(`${apiUrl}/website/contactus`, {
                method: "POST",
                body: data,
            }).then((res) => {
                res.json().then((data) => {
                    const status = data.status;
                    if (status === "success") {
                        swal("Success", data.message, "success").then((ok) => {
                            if (ok) {
                                navigate("/");
                            }
                        })
                    } else {
                        swal("Error", data.message, "error");
                    }
                })
            })
        } catch (error) {
            swal(error);
        }
    };

    const changeContact = (e) => setContact({ ...contact, [e.target.name]: e.target.value });

    var regex = /[0-9]|null/;

    const handler = (e) => {
        if (!regex.test(e.nativeEvent.data)) {
            return;
        }
        if (e.target.value.length <= 10) {
            setNumber(e.target.value);
        }
    };

    const errorHandler = () => {
        if (number && number[0] < 6) return "Enter a valid phone number";
        else return "";
    };

    useEffect(() => {
        AOS.init({ duration: 1000, offset: 120 });
        document.title = `Contact Us - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <section id="contact">
                <div className="row">
                    <div className="col-md-5 justify-content-md-center">
                        <div className="contactus-page-heading">
                            <div className="our-investors"><span className="our-investors-span1">CONTACT</span> <span className="our-investors-span2">US</span></div>
                        </div>
                        <div className="container2 contactus-top-form">
                            <div className="mx-wdth-500px card3 col-md-10">
                                <div className="col-md-12">
                                    <div className="contact-form pt-3 pb-3">
                                        <form method="POST" onSubmit={postData}>
                                            <div className="form-row">
                                                <div className="form-group col-md-12" id="contactus-form-left">
                                                    <label htmlFor="name">Your Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        name="name"
                                                        value={contact.name}
                                                        onChange={changeContact}
                                                        placeholder="Enter your full name"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-md-12" id="contactus-form-right">
                                                    <label htmlFor="mobile">Mobile Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="mobile"
                                                        name="number"
                                                        value={number}
                                                        onChange={(e) => { handler(e) }}
                                                        placeholder="Enter your mobile number"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            {
                                                errorHandler() === "Enter a valid phone number" ? <div className="text-center text-danger password-error mb-3">{errorHandler()}</div> : <div></div>
                                            }
                                            <div className="form-row">
                                                <div className="col-md-12" id="contactus-form-left">
                                                    <div className="form-group">
                                                        <label htmlFor="email">Email Address</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            name="email"
                                                            value={contact.email}
                                                            onChange={changeContact}
                                                            placeholder="Enter your email id"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="query">Query</label>
                                                        <select
                                                            className="form-control"
                                                            id="query"
                                                            name="query"
                                                            value={contact.query}
                                                            onChange={changeContact}
                                                            required
                                                        >
                                                            <option value="">
                                                                Select your query
                                                            </option>
                                                            <option value="Service Related">
                                                                Service related
                                                            </option>
                                                            <option value="Feedback/Compliants">
                                                                Feedback/Compliants
                                                            </option>
                                                            <option value="Collaboration/Tie-up">
                                                                Collaboration/Tie-up
                                                            </option>
                                                            <option value="Investment Related">
                                                                Investment Related
                                                            </option>
                                                            <option value="Hiring Related/CV Upload">
                                                                Hiring Related/CV Upload
                                                            </option>
                                                            <option value="Others">Others</option>
                                                        </select>
                                                        {
                                                            contact.query === "Hiring Related/CV Upload" ? (
                                                                <div>
                                                                    <br />
                                                                    <input
                                                                        type="file"
                                                                        id="resume"
                                                                        name="resume"
                                                                        onChange={(e) => { setResume(e.target.files[0]) }}
                                                                        multiple
                                                                    />
                                                                </div>) : (<div></div>)
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-12" id="contactus-form-right">
                                                    <div className="form-group">
                                                        <label htmlFor="message">Message</label>
                                                        <textarea
                                                            type="text"
                                                            className="form-control"
                                                            id="message"
                                                            name="message"
                                                            value={contact.message}
                                                            onChange={changeContact}
                                                            placeholder="Enter your message"
                                                            rows="3"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="btn btn-contactus">
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="contactus-top-image">
                            <img className="contactus-non-background-image" src="assets/images/contactUs/contactus-top-image.png" alt="contactus-non-background" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="section_bg_gray">
                <div className="container2">
                    <div className="card3" data-aos="fade-up">
                        <div className="pt-5" style={{ width: "100%", padding: "10px" }}>
                            <div className="our-investors"><span className="our-investors-span1">GET</span> <span className="our-investors-span2">IN TOUCH</span></div>
                            <div className="contactus-getintouch-head-text">
                                We are ready to lead you into the future of "Eco-Friendly" world.
                            </div>
                        </div>
                        <div className="row" style={{ width: "100%" }}>
                            <div className="col-md-4">
                                <div className="row">
                                    <span className="contact-icon2 col-3 text-right">
                                        <i className="fa fa-phone rotate-phone"></i>
                                    </span>
                                    <span className="col-8">
                                        <p className="contactus-getintouch-text text-left">
                                            +91 9269099902
                                        </p>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <span className="contact-icon2 col-3 text-right">
                                        <i className="fa fa-envelope"></i>
                                    </span>
                                    <span className="col-8">
                                        <p className="contactus-getintouch-text text-left">
                                            info@ecowrap.in
                                        </p>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="row">
                                    <span className="contact-icon2 col-3 text-right">
                                        <i className="fa fa-map-marker"></i>
                                    </span>
                                    <span className="col-8">
                                        <p className="contactus-getintouch-text text-left">
                                            56/104, 2nd Floor, Rajat Path, Mansarovar Sector 5, Mansarovar, Jaipur, Rajasthan 302020
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5" data-aos="fade-up">
                <img className="contactus-sub-image" src="assets/images/contactUs/contactus-sub-image.png" alt="contactus-map" />
            </div>
            <div className="container2 mt-5" data-aos="fade-up">
                <iframe title="myMap" className="img-map-contactus" src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d7118.4042870995!2d75.760083!3d26.865318!3m2!1i1024!2i768!4f13.1!4m8!3e2!4m0!4m5!1s0x396db55c1952d137%3A0x52783339024735b8!2sSHUBH%20WORLD%2C%2056%2F104%2C%20Rajat%20Path%2C%20Mansarovar%20Sector%205%2C%20Mansarovar%2C%20Jaipur%2C%20Rajasthan%20302020!3m2!1d26.865278999999997!2d75.760139!5e0!3m2!1sen!2sin!4v1681970664270!5m2!1sen!2sin" referrerPolicy="no-referrer-when-downgrade" loading="lazy" allowFullScreen style={{ borderRadius: "5px" }}></iframe>
            </div>
            <Footer />
        </>
    )
}

export default ContactUs;