import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const AddPropertyAndAddress = ({ title }) => {

    const [addPropertyAndAddressData, setAddPropertyAndAddressData] = useState(() => {
        const storedData = secureLocalStorage.getItem("addPropertyAndAddressData");
        return storedData ? JSON.parse(storedData) : {
            propertyName: "",
            ownerName: "",
            ownerNumber: "",
            address: "",
            area: "",
            floorStreet: "",
            city: "",
            state: "",
            country: "",
            pincode: "",
            propertyType: "",
            latitude: "",
            longitude: ""
        }
    });
    const navigate = useNavigate();

    const inputOnChange = (e) => {
        const { name, value } = e.target;
        setAddPropertyAndAddressData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const addPropertyAddress = async (e) => {
        e.preventDefault();
        navigate("/add-personal-information", { state: addPropertyAndAddressData })
    };

    const getCurrentLatitudeLongitude = () => {
        navigator.geolocation.getCurrentPosition((posn) => {
            setAddPropertyAndAddressData((prevFormData) => ({
                ...prevFormData,
                latitude: posn.coords.latitude,
                longitude: posn.coords.longitude,
            }))
        })
    };

    var regex = /[0-9]|null/;

    const handleNumberOnChange = (e) => {
        const inputValue = e.target.value;
        const fieldName = e.target.name;

        if (fieldName === "ownerNumber" && (!regex.test(inputValue) || inputValue.length > 10)) {
            setAddPropertyAndAddressData((prevFormData) => ({
                ...prevFormData, ownerNumber: ""
            }))
            return;
        }
        if (fieldName === "pincode" && (!regex.test(inputValue) || inputValue.length > 6)) {
            setAddPropertyAndAddressData((prevFormData) => ({
                ...prevFormData, pincode: ""
            }))
            return;
        }
        setAddPropertyAndAddressData((prevFormData) => ({ ...prevFormData, [fieldName]: inputValue }));
    };

    useEffect(() => {
        getCurrentLatitudeLongitude();
        document.title = `Add Property & Address - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        secureLocalStorage.setItem("addPropertyAndAddressData", JSON.stringify(addPropertyAndAddressData));
    }, [addPropertyAndAddressData]);

    return (
        <>
            <div className="app-wrapper">
                <div className="sidebar-mobile-overlay"></div>
                <div className="app-main">
                    <div className="app-header">
                        <div className="d-flex">
                            <div className="container-fluid p-1">
                                <div className="form-inline ml-auto mobile">
                                    <div className="btn">
                                        <i className="fa fa-bars"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-flex">
                            <div className="nav-dashboard-card">
                                <div className="pt-0 row text-center">
                                    <div className="col-md-6">
                                        <div className="nav-dashboard-sub-card">
                                            <NavLink to="/add-property-and-address">
                                                <div className="nav-dashboard-sub-card-text">
                                                    Ecowrap
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="nav-dashboard-sub-card">
                                            <div className="nav-dashboard-sub-card-text">Shop</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="container-fluid p-2">
                                <div className="form-inline mr-auto">
                                    <button type="button" className="btn bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-node d-44 rounded position-relative">
                                        <i className="fas fa-user-alt text-success"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="app-content">
                        <div className="app-content--inner">
                            <div className="container">
                                <form method="POST" onSubmit={addPropertyAddress}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="container mt-3">
                                                <span className="font-size-xxl font-weight-bold span-property">New property?</span>
                                                <span className="font-size-xxl font-weight-bold span-register"> Register</span>
                                                <div className="container mt-3">
                                                    <div className="mb-3">
                                                        <label htmlFor="propertyName" className="font-size-md form-label br-2rem">
                                                            Property Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control br-2rem"
                                                            id="propertyName"
                                                            name="propertyName"
                                                            value={addPropertyAndAddressData.propertyName}
                                                            onChange={inputOnChange}
                                                            placeholder="eg: Ecowrap"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="ownerName" className="font-size-md form-label br-2rem">
                                                            Onwer Name
                                                        </label>
                                                        <input
                                                            className="form-control br-2rem"
                                                            type="text"
                                                            id="ownerName"
                                                            name="ownerName"
                                                            value={addPropertyAndAddressData.ownerName}
                                                            onChange={inputOnChange}
                                                            placeholder="eg: John doe"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="ownerNumber" className="font-size-md form-label br-2rem">
                                                            Onwer Number
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control br-2rem"
                                                            id="ownerNumber"
                                                            name="ownerNumber"
                                                            value={addPropertyAndAddressData.ownerNumber}
                                                            onChange={handleNumberOnChange}
                                                            placeholder="eg: 9425006123"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container mt-3">
                                                <div className="font-size-xxl font-weight-bold span-address">
                                                    Address
                                                </div>
                                                <div className="container mt-3">
                                                    <div className="mb-3">
                                                        <label htmlFor="address" className="font-size-md form-label br-2rem">
                                                            Address
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control br-2rem"
                                                            id="address"
                                                            name="address"
                                                            value={addPropertyAndAddressData.address}
                                                            onChange={inputOnChange}
                                                            placeholder="eg: 7A, Gangotri Nagar Gopalpura Bypass Road"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="area" className="font-size-md form-label br-2rem">
                                                            Area
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control br-2rem"
                                                            id="area"
                                                            name="area"
                                                            value={addPropertyAndAddressData.area}
                                                            onChange={inputOnChange}
                                                            placeholder="eg: Puliya, Triveni Nagar"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="floorStreet" className="font-size-md form-label br-2rem">
                                                            Floor/Street
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control br-2rem"
                                                            id="floorStreet"
                                                            name="floorStreet"
                                                            value={addPropertyAndAddressData.floorStreet}
                                                            onChange={inputOnChange}
                                                            placeholder="eg: Floor and street"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="city" className="font-size-md form-label br-2rem">
                                                            City
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control br-2rem"
                                                            id="city"
                                                            name="city"
                                                            value={addPropertyAndAddressData.city}
                                                            onChange={inputOnChange}
                                                            placeholder="eg: Jaipur"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="state" className="font-size-md form-label br-2rem">
                                                            State
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control br-2rem"
                                                            id="state"
                                                            name="state"
                                                            value={addPropertyAndAddressData.state}
                                                            onChange={inputOnChange}
                                                            placeholder="eg: Rajasthan"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="country" className="font-size-md form-label br-2rem">
                                                            Country
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control br-2rem"
                                                            id="country"
                                                            name="country"
                                                            value={addPropertyAndAddressData.country}
                                                            onChange={inputOnChange}
                                                            placeholder="eg: India"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="pincode" className="font-size-md form-label br-2rem">
                                                            Pincode
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control br-2rem"
                                                            id="pincode"
                                                            name="pincode"
                                                            value={addPropertyAndAddressData.pincode}
                                                            onChange={handleNumberOnChange}
                                                            placeholder="eg: 302018"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container mt-3">
                                                <div className="font-size-xxl font-weight-bold span-type-property">
                                                    Type of property
                                                </div>
                                                <div className="container mt-3">
                                                    <div className="mb-3">
                                                        <label htmlFor="propertyType" className="font-size-md form-label br-2rem">
                                                            Property Type
                                                        </label>
                                                        <select
                                                            className="form-control br-2rem"
                                                            id="propertyType"
                                                            name="propertyType"
                                                            value={addPropertyAndAddressData.propertyType}
                                                            onChange={inputOnChange}
                                                            required
                                                        >
                                                            <option value="">Select your property type:</option>
                                                            <option value="Hotel">Hotel</option>
                                                            <option value="Bar/Restaurant">Bar/Restaurant</option>
                                                            <option value="Cafe">Cafe</option>
                                                            <option value="Educational">Educational</option>
                                                            <option value="Buisness">Buisness</option>
                                                            <option value="Retail">Retail</option>
                                                            <option value="Household">Household</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="container">
                                                <img className="addAddress-img" src="assets/images/dashboard/addnewaddress-image.png" alt="Save address images" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className="container align-center">
                                            <button type="submit" className="btn btn-pickupschedule">
                                                Save Address
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPropertyAndAddress;