import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Bhayli = ({ title }) => {

    useEffect(() => {
        AOS.init({ duration: 1000, offset: 120 });
        document.title = `Bhayli - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <section id="projects"><div className="pt-1"></div></section>
            <div className="row text-center projects-sub-row">
                <div className="col-md-5 recycleright-projects">
                    <div className="container2">
                        <div className="projects-sub-head"></div>
                        <div className="our-investors"><span className="our-investors-span1">BH</span><span className="our-investors-span2">AYLI</span></div>
                        <div className="projects-sub-text text-justify">
                            “Bhayli” (which means “female friend” in Marwari) is our women’s empowerment initiative that partners with women from rural areas to help them secure sustainable livelihoods from opportunities in up-cycling. This group of women has joined our vision "Mera desh, zero waste" and is using a portion of the waste we collect in Jaipur to craft art forms that are incorporated into unique sustainable products with an even more unique back story.
                        </div>
                        <div className="container2 pd-left-100px pt-3 pe-0">
                            <a href="https://www.bhayli.co.in/" target="_blank" rel="noreferrer noopener">
                                <button className="btn btn-projects">Read More</button>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-7 project-muskaan-img">
                    <img className="width-60percent" src="assets/images/projects/bhayli.png" alt="bhayli-png" />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Bhayli;