import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import BarChart from "./BarChart";
import SidebarForDashboard from "./SidebarForDashboard";
import NavbarForDashboard from "./NavbarForDashboard";
import Spinner from "../../components/Spinner";

const Wallet = ({ apiDashboardUrl, userId, title }) => {

    const [walletAmount, setWalletAmount] = useState();
    const [totalEarning, setTotalEarning] = useState();
    const [txnData, setTxnData] = useState();
    const [totalData, setTotalData] = useState();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    if (!userId) navigate('/send-otp')
    const getWalletData = async () => {
        try {
            await axios.get(`${apiDashboardUrl}/user/userwallet?userId=${userId}`).then((res) => {
                if (res.data.status === "success") {
                    setWalletAmount(res.data.data[1][0].WalletAmount);
                    setTotalEarning(res.data.data[2][0].TotalEarning);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    const getTxnData = async () => {
        try {
            await axios.get(`${apiDashboardUrl}/user/walletHistory?userId=${userId}&page=1`).then((res) => {
                if (res.data.status === "success") {
                    setTxnData(res.data.data);
                    setTotalData(res.data.TotalData);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getWalletData();
        getTxnData();
        document.title = `Wallet - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <SidebarForDashboard />
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <NavbarForDashboard />
                            <div className="app-content">
                                <div className="app-content--inner">
                                    <div className="container">
                                        <div className="row text-center">
                                            <div className="col-md-6">
                                                <div className="wallet-top-card">
                                                    <div className="wallet-top-card-image">
                                                        <img className="wallet-page-top-image" src="assets/images/dashboard/walletmonochromatic.png" alt="wallet-monochromatic-response" />
                                                    </div>
                                                    <div className="wallet-top-card-text">
                                                        <div className="row">
                                                            <div className="wallet-page-top-text col-md-6 text-center">
                                                                Wallet
                                                            </div>
                                                            <div className="wallet-page-top-data col-md-6 text-center">
                                                                ₹ {
                                                                    walletAmount ? parseFloat(walletAmount + null) : 0
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="wallet-top-card">
                                                    <div className="wallet-top-card-image">
                                                        <img className="wallet-page-top-image" src="assets/images/dashboard/banknoteisometric.png" alt="bank-noteisometric-response" />
                                                    </div>
                                                    <div className="wallet-top-card-text">
                                                        <div className="row">
                                                            <div className="wallet-page-top-text col-md-6 text-center">
                                                                Total Earning
                                                            </div>
                                                            <div className="wallet-page-top-data col-md-6 text-center">
                                                                ₹ {totalEarning ?
                                                                    parseFloat(totalEarning + null) : 0}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="d-flex justify-content-center">
                                            <div className="text-center p-3 mb-3 mt-5">
                                                <article className="align-center graph_secion">
                                                    <BarChart apiDashboardUrl={apiDashboardUrl} userId={userId} />
                                                </article>
                                            </div>
                                        </div>
                                        {
                                            !txnData ? "" : txnData.map((element, index) => {
                                                if (index < 10) {
                                                    return (
                                                        <div className="txns" key={index}>
                                                            <div className="container">
                                                                <div className="card p-3 bg-light mb-3">
                                                                    <div className="card-title row">
                                                                        <span className="col-sm-4">
                                                                            ₹ {parseFloat(element.Amount === "" ? 0 : element.Amount)}
                                                                        </span>
                                                                        <span className="col-sm-4">
                                                                            {element.Credit ? (<div>Debited</div>) : (<div>Credited</div>)}
                                                                        </span>
                                                                    </div>
                                                                    <div className="card-text row">
                                                                        <span className="col-sm-4 txnId">
                                                                            Transaction Id : {element.TransactionId}
                                                                        </span>
                                                                        <span className="col-sm-4 date">
                                                                            Date : {moment(element.Time).format("DD-MM-YYYY")}
                                                                        </span>
                                                                        <span className="col-sm-4 time">
                                                                            Time : {moment(element.Time).format("hh:mm A").toLocaleString("en-US", { timeZone: "Asia/Calcutta", hour12: true, hour: "numeric", minute: "numeric" })}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                        {
                                            totalData >= 10 ? <button className="btn btn-secondary wallet-btn" onClick={() => { navigate("/wallet-history") }}>
                                                See All &nbsp; <i className="fas fa-arrow-right"></i>
                                            </button> : <div></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Wallet;