import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import swal from "sweetalert";

const SidebarForDashboard = () => {

    const apiDashboardUrl = process.env.REACT_APP_DASHBOARD_API;
    const userId = secureLocalStorage.getItem("UserId");

    const [show, setShow] = useState(false);
    const [showDocuments, setShowDocuments] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const refOpenAuditReport = useRef(null);
    const refCloseAuditReport = useRef(null);
    const navigate = useNavigate();

    const toggleAuditReport = () => refOpenAuditReport.current.click();

    var currentMonth = moment().format("YYYY/MM/DD");
    var lastMonth = moment().subtract(30, "days").format("YYYY/MM/DD");
    var threeMonth = moment().subtract(90, "days").format("YYYY/MM/DD");
    var sixMonth = moment().subtract(180, "days").format("YYYY/MM/DD");
    var oneYear = moment().subtract(365, "days").format("YYYY/MM/DD");

    const logout = () => {
        // swal("Success", "Logout Succesfully!", "success").then((ok) => {
        //     if (ok) {

        //     }
        // })
        secureLocalStorage.clear();
        navigate("/");
    };

    return (
        <>
            <button ref={refOpenAuditReport} type="button" className="btn btn-dark d-none" data-bs-toggle="modal" data-bs-target="#auditReportModal"></button>

            <div className="modal fade" id="auditReportModal" tabIndex="-1" aria-labelledby="auditReportModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="auditReportModalLabel">
                                Choose your option
                            </h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="mb-2 col-md-6">
                                    <a href={`${apiDashboardUrl}/user/appViewAuditReport/${userId}?startDate=${lastMonth}&endDate=${currentMonth}`}>
                                        <div
                                            id="audit-report"
                                            className={`cp mt-1 mr-2`}>
                                            Last one month
                                        </div>
                                    </a>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <a href={`${apiDashboardUrl}/user/appViewAuditReport/${userId}?startDate=${threeMonth}&endDate=${currentMonth}`}>
                                        <div
                                            id="audit-report"
                                            className={`cp mt-1 mr-2`}>
                                            Last three month
                                        </div>
                                    </a>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <a href={`${apiDashboardUrl}/user/appViewAuditReport/${userId}?startDate=${sixMonth}&endDate=${currentMonth}`}>
                                        <div
                                            id="audit-report"
                                            className={`cp mt-1 mr-2`}>
                                            Last six month
                                        </div>
                                    </a>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <a href={`${apiDashboardUrl}/user/appViewAuditReport/${userId}?startDate=${oneYear}&endDate=${currentMonth}`}>
                                        <div
                                            id="audit-report"
                                            className={`cp mt-1 mr-2`}>
                                            Last one year
                                        </div>
                                    </a>
                                </div>
                                <div className="mb-2 col-md-6">
                                    <div
                                        onClick={() => setShow(prev => !prev)}
                                        id="audit-report"
                                        className={`cp mt-1 mr-2`}>
                                        Custom
                                    </div>
                                    {
                                        show && <div className="row">
                                            <div className="mb-2">
                                                <label htmlFor="startDate" className="font-size-md form-label br-2rem ml-2">
                                                    Start Date
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control ml-2"
                                                    id="audit-report"
                                                    name="startDate"
                                                    value={startDate}
                                                    onChange={(e) => { setStartDate(e.target.value) }}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <label htmlFor="endDate" className="font-size-md form-label br-2rem ml-2">
                                                    End Date
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control ml-2"
                                                    id="audit-report"
                                                    name="endDate"
                                                    placeholder="enddats"
                                                    value={endDate}
                                                    onChange={(e) => { setEndDate(e.target.value) }}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <a href={`${apiDashboardUrl}/user/appViewAuditReport/${userId}?startDate=${startDate}&endDate=${endDate}`}>
                                                    {
                                                        startDate === "" || endDate === "" ? <div></div> : <button
                                                            type="button"
                                                            className="btn btn-pickupschedule">
                                                            Send
                                                        </button>
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-pickupschedule"
                                data-bs-dismiss="modal"
                                ref={refCloseAuditReport}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="app-sidebar app-sidebar--dark">
                <div className="app-sidebar--header">
                    <div className="nav-logo w-100 text-center"></div>
                </div>
                <div className="app-sidebar--content scrollbar-container">
                    <div className="sidebar-navigation">
                        <ul id="sidebar-nav">
                            <li className="left-nav-list-logo">
                                <a href="/dashboard" aria-expanded="true">
                                    <img className="navbar-logo1" src="assets/images/logo/ecowrap-logo.png" alt="ecowrap-logo" />
                                </a>
                            </li>
                            <li className="left-nav-list-item">
                                <a href="/dashboard" aria-expanded="true">
                                    <span>
                                        <i className="fas fa-tachometer-alt" id="leftnavbar-components-id"></i>
                                        <span className="leftnavbar-components">Dashboard</span>
                                    </span>
                                    <i className="fas fa-chevron-right"></i>
                                </a>
                            </li>
                            <li className="left-nav-list-item">
                                <a href="/apply-dustbins">
                                    <span>
                                        <i className="fas fa-trash" id="leftnavbar-components-id"></i>
                                        <span className="leftnavbar-components">
                                            Dustbins
                                        </span>
                                    </span>
                                    <i className="fas fa-chevron-right"></i>
                                </a>
                            </li>
                            <li className="left-nav-list-item">
                                <a href="/wallet">
                                    <span>
                                        <i className="fas fa-wallet" id="leftnavbar-components-id"></i>
                                        <span className="leftnavbar-components">Wallet</span>
                                    </span>
                                    <i className="fas fa-chevron-right"></i>
                                </a>
                            </li>
                            <li className="left-nav-list-item">
                                <a data-toggle="collapse" href="#documents" aria-expanded="false" aria-controls="ui-basic" onClick={() => setShowDocuments(prev => !prev)}>
                                    <span>
                                        <i className="fas fa-id-card" id="leftnavbar-components-id"></i>
                                        <span className="leftnavbar-components">Documents</span>
                                    </span>
                                    <i className="fa fa-chevron-right"></i>
                                </a>
                                {
                                    showDocuments && <div className="collapse" id="documents">
                                        <ul>
                                            <li className="left-nav-list-item">
                                                <a className="left-nav-sub-history" href={`${apiDashboardUrl}/user/viewPerformaInvoice/${userId}`}>
                                                    Performa Invoice
                                                </a>
                                            </li>
                                            <li className="left-nav-list-item">
                                                <a className="left-nav-sub-history" href={`${apiDashboardUrl}/user/viewMouDocument/${userId}`}>
                                                    MOU Document
                                                </a>
                                            </li>
                                            <li className="left-nav-list-item">
                                                <div>
                                                    {/* eslint-disable-next-line */}
                                                    <a className="cp left-nav-sub-history" onClick={() => { toggleAuditReport() }}>
                                                        Audit Report
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </li>
                            <li className="left-nav-list-item">
                                <a href="#history" data-toggle="collapse" aria-expanded="false" aria-controls="ui-basic" onClick={() => setShowHistory(prev => !prev)}>
                                    <span>
                                        <i className="fas fa-history" id="leftnavbar-components-id"></i>
                                        <span className="leftnavbar-components">History</span>
                                    </span>
                                    <i className="fa fa-chevron-right"></i>
                                </a>
                                {
                                    showHistory && <div className="collapse" id="history">
                                        <ul>
                                            <li className="left-nav-list-item">
                                                <a className="left-nav-sub-history" href="/wallet-history">
                                                    Wallet History
                                                </a>
                                            </li>
                                            <li className="left-nav-list-item">
                                                <a className="left-nav-sub-history" href="/pickup-history">
                                                    Pickup History
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </li>
                            <li className="left-nav-list-item">
                                <a href="/rate-list">
                                    <span>
                                        <i className="fas fa-rupee" id="leftnavbar-components-id"></i>
                                        <span className="leftnavbar-components">Rate List</span>
                                    </span>
                                    <i className="fas fa-chevron-right"></i>
                                </a>
                            </li>
                            <li className="left-nav-list-item">
                                <a href="/help-and-support">
                                    <span>
                                        <i className="fas fa-headset" id="leftnavbar-components-id"></i>
                                        <span className="leftnavbar-components">
                                            Help & Support
                                        </span>
                                    </span>
                                    <i className="fas fa-chevron-right"></i>
                                </a>
                            </li>
                            <li className="left-nav-list-item">
                                <a href="#!" onClick={() => logout()}>
                                    <span>
                                        <i className="fa fa-sign-out" id="leftnavbar-components-id"></i>
                                        <span className="leftnavbar-components"> &nbsp; &nbsp; Logout</span>
                                    </span>
                                    <i className="fas fa-chevron-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SidebarForDashboard;