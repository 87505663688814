import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import DOMPurify from "dompurify";
import Navbar from "./Navbar";
import Footer from "./Footer";

const BlogDetails = ({ apiUrl, title }) => {

    const location = useLocation().state;

    useEffect(() => {
        document.title = `Blog Details - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <div className="section_bg_gray pb-30">
                <div className="container2">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5 text-uppercase"><span className="our-investors-span2">{location?.Heading}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="blog-details-card">
                            <img className="blogdetails-image" src={`${apiUrl}/blogImage/` + location?.Image} alt="blog-details" />
                            <div className="row">
                                <div className="col-md-12 text-right pt-2">
                                    <div style={{ fontFamily: "Open Sans", fontStyle: "normal", fontWeight: "500", color: "rgba(8, 59, 124, 1)", paddingRight: "20px", fontSize: "18px" }}><i>{moment(location?.CreationTime).fromNow()}</i></div>
                                </div>
                            </div>
                            <div style={{ margin: "50px" }}>
                                <div style={{ marginLeft: "10px" }}>
                                    <div className="set-inner-HTML text-left" style={{ fontFamily: "Open Sans", fontStyle: "normal", fontWeight: "500", fontSize: "18px" }}
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(location?.Content) }}>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 card-text text-left">
                                    <a href={location?.link} target="_blank" rel="noreferrer"
                                        style={{ fontFamily: "Open Sans", fontStyle: "normal", fontWeight: "500", paddingLeft: "60px", color: "rgba(8, 59, 124, 1)", fontSize: "18px" }}>
                                        <i>{location?.link}</i>
                                    </a>
                                </div>
                                <div className="col-md-6 card-text text-right"
                                    style={{
                                        fontFamily: "Open Sans", fontStyle: "normal", fontWeight: "500", paddingRight: "60px", color: "rgba(8, 59, 124, 1)", fontSize: "18px",
                                    }}><i>{location?.Writer}</i>
                                </div>
                            </div>
                            <div style={{ marginBottom: "30px" }}>
                                <a className="App-link" target="_blank" rel="noopener noreferrer"
                                    href="https://www.linkedin.com/shareArticle?mini=true&url=http://ecowrap.in/blog-details">
                                    <img style={{ width: 32, height: 32 }} src="assets/images/blog/linkedin.png" alt="linkedin" />
                                </a>
                                &nbsp; &nbsp;
                                <a className="App-link" target="_blank" rel="noopener noreferrer"
                                    href="http://www.twitter.com/share?url=http://ecowrap.in/blog-details">
                                    <img style={{ width: 32, height: 32 }} src="assets/images/blog/twitter.png" alt="twitter" />
                                </a>
                                &nbsp; &nbsp;
                                <a className="App-link" target="_blank" rel="noopener noreferrer"
                                    href="https://www.facebook.com/sharer/sharer.php?u=http://ecowrap.in/blog-details">
                                    <img style={{ width: 32, height: 32 }} src="assets/images/blog/facebook.png" alt="facebook" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default BlogDetails;