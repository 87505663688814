import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const SendOtp = ({ apiUserUrl, title }) => {

    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const mobileVerification = async (e) => {
        try {
            e.preventDefault();
            const response = await fetch(`${apiUserUrl}/sendOTP`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    phoneNumber: phoneNumber,
                })
            });
            const data = await response.json();
            if (data.status === true) {
                setMessage(data.message);
                secureLocalStorage.setItem("phoneNumber", phoneNumber);
                secureLocalStorage.setItem("hash", data.data[0]);
                navigate("/verify-login-otp");
            } else {
                setMessage(data.message);
            }
        } catch (error) {
            setMessage(error.message || "An error occurred");
        }
    };

    var regex = /[0-9]|null/;

    const handler = (e) => {
        if (!regex.test(e.nativeEvent.data)) return;
        if (e.target.value.length <= 10) setPhoneNumber(e.target.value);
    };

    const errorHandler = () => {
        if (phoneNumber && phoneNumber.length < 6) return "Enter a valid phone number";
        return null;
    };

    useEffect(() => {
        document.title = `Send OTP - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="container">
                <div className="row justify-content-md-center pt-5">
                    <div className="col-md-6 text-center pt-5">
                        <div className="contact-form">
                            <form method="POST" onSubmit={mobileVerification}>
                                <div className="form-row justify-content-md-center text-center">
                                    <div className="form-group col-md-6">
                                        <div className="navbar-brand navbar-brands pt-5">
                                            <img className="navbar-logo" src="assets/images/logo/ecowrap-logo.png" alt="Ecowrap logo" />
                                        </div>
                                        <h4 className="verify-h4">Let's Get Started</h4>
                                        <h5 className="verify-h5">Login</h5>
                                        <h6 className="verify-h6">Enter the 10 digit mobile number</h6>
                                        <input
                                            type="text"
                                            className="form-control justify-content-md-center text-center"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            onChange={handler}
                                            placeholder="Enter the 10 digit mobile number"
                                            required="required"
                                            autoComplete="off"
                                            title="10 digit phone number"
                                            pattern="[0-9]{10}"
                                        />
                                    </div>
                                </div>
                                {
                                    errorHandler() === "Enter a valid phone number" ? <div className="text-center text-danger password-error mb-3">{errorHandler()}</div> : <div className="text-warning">{message}</div>
                                }
                                <div className="text-center">
                                    <button type="submit" className="btn btn-sm btn-light custom-btn" id="verification">
                                        Send OTP
                                    </button>
                                </div>
                                <div className="text-center">
                                    <div className="a-divider a-divider-break mt-2">
                                        <h5 aria-level="5">Or</h5>
                                    </div>
                                    <span className="create-account" onClick={() => navigate("/add-property-and-address")}>
                                        Create new account
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img className="mobile-verify-img" src="assets/images/auth/mobile-image.png" alt="Mobile verification images" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SendOtp;