import React, { useState, useEffect } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const AddPersonalInformation = ({ title }) => {

    const [addPersonalInformationData, setAddPersonalInformationData] = useState(() => {
        const storedData = secureLocalStorage.getItem("addPersonalInformationData");
        return storedData ? JSON.parse(storedData) : {
            name: "",
            number: "",
            email: ""
        }
    });

    const addPropertyAndAddressData = useLocation().state;
    const navigate = useNavigate();

    const addUserInformation = async (e) => {
        e.preventDefault();
        navigate("/check-registration-otp", { state: { addPropertyAndAddressData, addPersonalInformationData } });
    };

    const inputOnChange = (e) => {
        const { name, value } = e.target;
        setAddPersonalInformationData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    var regex = /[0-9]|null/;

    const handleNumberOnChange = (e) => {
        if (!regex.test(e.nativeEvent.data)) return;
        const inputValue = e.target.value;
        setAddPersonalInformationData((prevFormData) => ({
            ...prevFormData,
            number: inputValue.length <= 10 ? inputValue : prevFormData.number
        }))
    };

    useEffect(() => {
        document.title = `Add Personal Information - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        secureLocalStorage.setItem("addPersonalInformationData", JSON.stringify(addPersonalInformationData));
    }, [addPersonalInformationData]);

    return (
        <>
            <div className="app-wrapper">
                <div className="sidebar-mobile-overlay"></div>
                <div className="app-main">
                    <div className="app-header">
                        <div className="d-flex">
                            <div className="container-fluid p-1">
                                <div className="form-inline ml-auto mobile">
                                    <div className="btn">
                                        <i className="fa fa-bars"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-flex">
                            <div className="nav-dashboard-card">
                                <div className="pt-0 row text-center">
                                    <div className="col-md-6">
                                        <div className="nav-dashboard-sub-card">
                                            <NavLink to="/add-personal-information">
                                                <div className="nav-dashboard-sub-card-text">
                                                    Ecowrap
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="nav-dashboard-sub-card">
                                            <div className="nav-dashboard-sub-card-text">Shop</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="container-fluid p-2">
                                <div className="form-inline mr-auto">
                                    <button type="button" className="btn bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-node d-44 rounded position-relative">
                                        <i className="fas fa-user-alt text-success"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="app-content">
                        <div className="app-content--inner">
                            <div className="container">
                                <form method="POST" onSubmit={addUserInformation}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="container mt-3">
                                                <span className="font-size-xxl font-weight-bold span-property">User information!</span>
                                                <span className="font-size-xxl font-weight-bold span-register"> Add</span>
                                                <div className="container mt-3">
                                                    <div className="mb-3">
                                                        <label htmlFor="name" className="font-size-md form-label br-2rem">
                                                            Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control br-2rem"
                                                            id="name"
                                                            name="name"
                                                            value={addPersonalInformationData.name}
                                                            onChange={inputOnChange}
                                                            placeholder="eg: John doe"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="number" className="font-size-md form-label br-2rem">
                                                            Number
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control br-2rem"
                                                            id="number"
                                                            name="number"
                                                            value={addPersonalInformationData.number}
                                                            onChange={handleNumberOnChange}
                                                            placeholder="eg: 9425006123"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="email" className="font-size-md form-label br-2rem">
                                                            Email
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className="form-control br-2rem"
                                                            id="email"
                                                            name="email"
                                                            value={addPersonalInformationData.email}
                                                            onChange={inputOnChange}
                                                            placeholder="eg: johndoe@gmail.com"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="container">
                                                <img className="addAddress-img" src="assets/images/dashboard/addnewaddress-image.png" alt="Save information images" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className="container align-center">
                                            <button type="submit" className="btn btn-pickupschedule">
                                                Save Information
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AddPersonalInformation;