import firebase from "firebase/app";
import "firebase/messaging";
import swal from "sweetalert";

const firebaseConfiguration = {
  apiKey: "AIzaSyC94fgpKJVgGD8H6yt6jBiYZXNeNvlGwW8",
  authDomain: "ecowrap-driver.firebaseapp.com",
  projectId: "ecowrap-driver",
  storageBucket: "ecowrap-driver.appspot.com",
  messagingSenderId: "247948584094",
  appId: "1:247948584094:web:c1d332aee4874ef9c3478f",
  measurementId: "G-R5GX0W1X26"
};

firebase.initializeApp(firebaseConfiguration);

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging()
  messaging.usePublicVapidKey("BA0J_kyFyeAEylVzQUclw6bmcmfTldMyW1YUs17NafpXaV77znAeHyR0C0LKkc13SGMsI7WxCy9Sp_L-L_c9wDw")
}

const REACT_APP_VAPID_KEY = "BA0J_kyFyeAEylVzQUclw6bmcmfTldMyW1YUs17NafpXaV77znAeHyR0C0LKkc13SGMsI7WxCy9Sp_L-L_c9wDw";
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setIsTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setIsTokenFound(true);
    } else {
      setIsTokenFound(false);
    }
  } catch (error) {
    swal("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });