import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import moment from "moment";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Blog = ({ apiUrl, title }) => {

    const [blogs, setBlogs] = useState([]);
    const navigate = useNavigate();

    const getBlogs = async () => {
        try {
            const response = await fetch(`${apiUrl}/website/blogs?active=0`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            })
            if (response.status === 200) {
                const data = await response.json();
                if (data.status === true) {
                    setBlogs(data.data);
                }
            }
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        }
    };

    useEffect(() => {
        getBlogs();
        AOS.init({ duration: 1000, offset: 120 });
        document.title = `Blogs - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <section id="blog">
                <div className="row">
                    <div className="col-md-6">
                        <div className="blogs-top-heading">
                            <div className="our-investors"><span className="our-investors-span1">THE STAGES</span> <span className="our-investors-span2">OF A PLASTIC <br /> BOTTLE'S LIFE</span></div>
                        </div>
                        <div className="clientspartners-top-text text-justify">
                            One of the most pressing environmental issues we face is plastic pollution. We consume <strong>400 billion</strong> plastic water bottles around the globe, equivalent to <strong>20,000</strong> bottles per second. Only <strong>9%</strong> of all plastic gets recycled, while the remaining <strong>91</strong>% is dumped in landfills or leaches into our oceans...<NavLink to="/trending-blog-details"><div className="btn btn-blog">Read more</div></NavLink>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img className="width-100percent" src="assets/images/blog/ecowrap-blog-trending.png" alt="blogs-top" />
                    </div>
                </div>
            </section>
            {
                blogs.data?.map((element, index) => {
                    return (
                        <div className="section_bg_gray cp" key={index} onClick={() => navigate("/blog-details", { state: element })}>
                            <hr />
                            {
                                index % 2 === 0 ?
                                    <div className="container-blog-left" data-aos="fade-up">
                                        <div className="row text-left">
                                            <div className="col-md-4">
                                                <img className="img-fluid blog-blogs-image" src={blogs?.imageUrl + element.Image} alt="ecowrap-blog-blogs1" />
                                            </div>
                                            <div className="col-md-8">
                                                <div className="head-blog-sub">{element.Heading}</div>
                                                <div className="head-blog-sub-heading">
                                                    {element.shortDiscription}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 text-right">
                                                        <small className="text-info">
                                                            <i>{moment(element.CreationTime).fromNow()}</i>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="container-blog-right" data-aos="fade-up">
                                        <div className="row text-right">
                                            <div className="col-md-8">
                                                <div className="head-blog-sub text-left">{element.Heading}</div>
                                                <div className="head-blog-sub-heading text-left">
                                                    {element.shortDiscription}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 text-left">
                                                        <small className="text-info">
                                                            <i>{moment(element.CreationTime).fromNow()}</i>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <img className="img-fluid blog-blogs-image" src={blogs?.imageUrl + element.Image} alt="ecowrap-blog-blogs1" />
                                            </div>
                                        </div>
                                    </div>
                            }
                            <hr />
                        </div>
                    );
                })
            }
            <Footer />
        </>
    )
}

export default Blog;