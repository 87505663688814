import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "aos/dist/aos.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

const IncentiveList = ({ apiUrl, title }) => {

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const getCategoryList = async () => {
        try {
            await axios.get(`${apiUrl}/website/rateList`).then((res) => {
                if (res.data.status === true) {
                    setCategoryList(res.data.data.data);
                    setSelectedCategory(res.data.data.data[0].CategoryId);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        }
    };

    useEffect(() => {
        getCategoryList();
        document.title = `Incentive List - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <section id="rate-list"></section>
            <div className="section_bg_gray">
                {
                    categoryList.length === 0 ? <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-xl-3"></div>
                            <div className="col-md-6 col-xl-6 align-items-center">
                                <img src="assets/images/dashboard/data-not-found.png" alt="data-not-found"></img>
                                <div style={{ fontSize: 20, textAlign: "center" }} className="text-center footer-down-head">
                                    No data found at that moment
                                </div>
                            </div>
                            <div className="col-md-3 col-xl-3"></div>
                        </div>
                    </div> : <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pt-5">
                                    <div className="home-page-headings"></div>
                                    <div className="our-investors"><span className="our-investors-span1">CAT</span><span className="our-investors-span2">EGORY</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="row margin-auto">
                            {
                                !categoryList ? "" : categoryList.map((element, index) => {
                                    return (
                                        <div className="col-md-3" key={index} onClick={() => {
                                            setSelectedIndex(index);
                                            setSelectedCategory(element.CategoryId);
                                        }}>
                                            <div className={`card cp mt-5 ${selectedCategory === element.CategoryId ? "activated-incentive" : null}`} id="incentivelist-top-box">
                                                <img className="img-fluid text-center product-image-pricinglist" src={`${apiUrl}/rateListCategory/${categoryList[index].CategoryImage}`} alt="ecowrap-pricinglist" />
                                                <div className="font-size-lg text-center">
                                                    {categoryList[index].CategoryName}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="pt-3 pb-5">
                                        {categoryList.length === 0 ? (<div></div>) : <div className="our-investors text-uppercase"><span className="our-investors-span2">{categoryList[selectedIndex].CategoryName}</span></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            categoryList.length === 0 ? <div></div> : categoryList[selectedIndex].SubCategory.map((element, index) => {
                                return (
                                    <div className="align-items-center" key={index}>
                                        <div className="card-pricinglist">
                                            <div className="row">
                                                <span className="col-md-3">
                                                    <img className="img-fluid product-image-pricinglist" src={`${apiUrl}/rateListCategory/${element[2]}`} alt="ecowrap-pricinglist" />
                                                </span>
                                                <span className="col-md-9">
                                                    <div className="container card-title font-size-xxl font-weight-bold mt-3 color-356859">
                                                        {element[0]}
                                                    </div>
                                                    <div className="container font-size-lg color-000000">
                                                        {element[1]}
                                                    </div>
                                                    <div className="container text-right font-size-lg font-weight-bold color-green">
                                                        ₹{element[3]}{"/"}{element[4]}
                                                    </div>
                                                </span>
                                            </div>
                                            <br />
                                        </div>
                                        <br />
                                    </div>
                                );
                            })
                        }
                    </div>
                }
            </div>
            <Footer />
        </>
    )
}

export default IncentiveList;