import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const CheckRegistredProperty = ({ title }) => {

    const { message, registerdUser, addPersonalInformationData, addPropertyAndAddressData } = useLocation().state;
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `Check Registered Property - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="app-wrapper">
                <div className="sidebar-mobile-overlay"></div>
                <div className="app-content--inner">
                    <div className="container row margin-auto">
                        <div className="col-md-12 text-center" >
                            <img className="img-fluid about-images" style={{ width: "50%", height: "60%" }} id="pickup-page-image" src="assets/images/dashboard/pickup-history.png" alt="Registered property images" />
                        </div>
                    </div>
                    <div className="row pickups-up">
                        <span className="col-md-12 text-center">{message}</span>
                    </div>
                    <div className="row pickup-table">
                        <span className="col-lg-3 text-center">Property Name</span>
                        <span className="col-lg-3 text-center">Property Type</span>
                        <span className="col-lg-3 text-center">Address</span>
                        <span className="col-lg-3 text-center">Action</span>
                    </div>
                    {
                        !registerdUser ? "" : registerdUser.map((data, index) => {
                            return (
                                <div key={index} className="row pickups-table">
                                    <span className="col-lg-3 text-center">
                                        {data.PropertyName}
                                    </span>
                                    <span className="col-lg-3 text-center">
                                        {data.PropertyType}
                                    </span>
                                    <span className="col-lg-3 text-center">
                                        {data.Address?.split(",")[data.Address?.split(",").length - 4]}
                                    </span>
                                    <span className="col-lg-3 text-center text-decoration-underline cp" onClick={() => navigate("/send-otp")}>
                                        Click here to login to your existing account
                                    </span>
                                </div>
                            )
                        })
                    }
                    <div className="text-center">
                        Or
                    </div>
                    <div className="text-center text-decoration-underline cp" onClick={() =>
                        navigate("/deactive-otp", { state: { addPersonalInformationData, addPropertyAndAddressData } })}>
                        Click here to create new account
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckRegistredProperty;