import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Faq = ({ apiUrl, title }) => {

    const [difference, setDifference] = useState([]);

    const getDifferenceData = async () => {
        try {
            await axios.get(`${apiUrl}/website/totalGenerate`).then((res) => {
                if (res.data.status === "success") {
                    setDifference(res.data.data[0]);
                } else {
                    swal("Error", res.data.message, "error");
                }
            })
        } catch (error) {
            swal(error);
        };
    };

    useEffect(() => {
        getDifferenceData();
        document.title = `FAQ - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Navbar />
            <div className="section_bg_gray pb-30">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="our-investors pt-5 pb-5"><span className="our-investors-span1">F</span><span className="our-investors-span2">AQ</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <h4 className="fonts text-left">
                            <b>What is Ecowrap?</b>
                        </h4>
                        <div className="col-md-12">
                            <div className="verticals-mid text-justify">
                                <p className="fonts">
                                    We are a tech-enabled behavior-change start-up focused on source segregation of India’s waste material. We incentivize source segregation through a cash-back system and sustainability ratings. Check us out at <NavLink to="/" target="_blank" rel="noreferrer"><u>ecowrap.in</u></NavLink>
                                </p>
                            </div>
                        </div>
                        <h4 className="fonts text-left pt-3">
                            <b>What are we doing with your waste?</b>
                        </h4>
                        <div className="col-md-12">
                            <div className="verticals-mid text-justify">
                                <p className="fonts">
                                    We are a waste management company with a zero-dump model:
                                    <br />
                                    <b>We Recycle</b>. We sell the segregated waste we collect directly to Indian recyclers who would otherwise import segregated waste from other countries.
                                    <br />
                                    <b>We Up-cycle</b>. A portion of our waste goes to Bhayli our women’s empowerment initiative where we help rural women secure sustainable livelihoods through opportunities in up-cycling. Check us out at <a href="https://www.bhayli.co.in/" target="_blank" rel="noreferrer"><u>bhayli.co.in</u></a>
                                </p>
                            </div>
                        </div>
                        <h4 className="fonts text-left pt-3">
                            <b>Why do we segregate?</b>
                        </h4>
                        <div className="col-md-12">
                            <div className="verticals-mid text-justify">
                                <p className="fonts">
                                    When wet and dry waste is mixed it becomes contaminated and there are very few ways to reuse the waste which leads to it ending up in landfills. By source segregating, we are able to reduce contamination of waste and bring it back into the value chain.
                                </p>
                            </div>
                        </div>
                        <h4 className="fonts text-left pt-3">
                            <b>Whose waste do we manage?</b>
                        </h4>
                        <div className="col-md-12">
                            <div className="verticals-mid text-justify">
                                <p className="fonts">
                                    We partner with 1,400+ hotels, restaurants, bars, and cafes throughout Jaipur to manage their waste. We also manage the waste for Zero-dump movie shoots, weddings, and largescale events like this one. Get in touch with us if you would like to become one of our partners <b className="cp" onClick={() => { window.open("tel:9950699905") }}><u>(+91 9950699905)</u></b>.
                                </p>
                            </div>
                        </div>
                        <h4 className="fonts text-left pt-3">
                            <b>What is our impact?</b>
                        </h4>
                        <div className="col-md-12">
                            <div className="verticals-mid text-justify">
                                <p className="fonts">
                                    Metric Tonnes (MT) of waste diverted from landfills: <b>{parseInt((difference.CollectedWaste / 1000) + 11200) + "+ MT"}</b>
                                    <br />
                                    Greenhouse Gas Emission saved: <b>{parseInt(11200 + (difference.CollectedWaste / 1000)) * 1.84 + "+ MT"}</b>
                                    <br />
                                    Tress saved: <b>{parseInt(difference.CollectedWaste / 1000 * 5 + 11.2 * 5) + "+"}</b>
                                    <br />
                                    Employment generated: <b>30+</b>
                                    <br />
                                    Livelihoods generated from up-cycling: <b>7+</b>
                                    <br />
                                    Up-cycled products designed: <b>12+</b>
                                    <br />
                                    Up-cycled art forms developed: <b>2+</b>
                                </p>
                            </div>
                        </div>
                        <h4 className="fonts text-left pt-3">
                            <b>How can you support us?</b>
                        </h4>
                        <div className="col-md-12">
                            <div className="verticals-mid text-justify">
                                <p className="fonts">
                                    1. Ensure that the waste you produce today gets placed in the proper dustbin:
                                    <br />
                                    <br />
                                    <div className="ps-3">
                                        a. <b>Dry Waste</b>: mineral water bottles, paper water cups, plastic coffee lids, plastic cutlery, glass bottles, broken glass, cold drink cans, tetra paks, empty kukure/chips packets, paper waste, cardboard, clean packaging waste
                                        <br />
                                        b. <b>Wet Waste</b>: food waste, biodegradable paper plates, wooden cutlery, dirty paper coffee cups, <i>kulhad</i> (earthen/terracotta chai cups), tissues
                                    </div>
                                    <br />
                                    2. Purchase <a href="https://www.bhayli.co.in/products" target="_blank" rel="noreferrer"><u>products</u></a>   made from your waste.
                                    <br />
                                    <br />
                                    3. Follow us on Instagram: <a href="https://www.instagram.com/ecowrap.in/?hl=en" target="_blank" rel="noopener noreferrer"><u>@ecowrap.in</u></a>, <a href="https://www.instagram.com/bhayli_ecowrap/?hl=en" target="_blank" rel="noopener noreferrer"><u>@bhayli_ecowrap</u></a>
                                    <br />
                                    <br />
                                    4. Upload a photo of yourself on instagram segregating at this event using the following hashtags: <a href="https://www.instagram.com/explore/tags/climate_mafia" target="_blank" rel="noreferrer" ><u>#climate_mafia</u></a>, <a href="https://www.instagram.com/explore/tags/zerodump_jlf" target="_blank" rel="noreferrer"><u>#zerodump_jlf</u></a>, <a href="https://www.instagram.com/explore/tags/meradesh_zerowaste" target="_blank" rel="noreferrer"><u>#meradesh_zerowaste</u></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Faq;