import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import swal from "sweetalert";
import Spinner from "../../components/Spinner";

const AddAddress = ({ apiDashboardUrl, userId, title }) => {

    const [propertyName, setPropertyName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [address, setAddress] = useState("");
    const [area, setArea] = useState("");
    const [floorStreet, setFloorStreet] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [pincode, setPincode] = useState("");
    const [propertyType, setPropertyType] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [loading, setLoading] = useState(false);
    const destructuringAdd = address + ", " + area + ", " + city + ", " + state + ", " + pincode + ", " + country;
    const navigate = useNavigate();

    if (!userId) navigate('/send-otp')
    const addAddressDetails = async (e) => {
        try {
            e.preventDefault();
            await fetch(`${apiDashboardUrl}/user/addProperties`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    UserId: userId,
                    PropertyName: propertyName,
                    ContactPerson: contactPerson,
                    ContactNumber: contactNumber,
                    Address: destructuringAdd,
                    Landmark: area,
                    FloorStreet: floorStreet,
                    City: city,
                    State: state,
                    PropertyType: propertyType,
                    Latitude: latitude,
                    Longitude: longitude,
                    Direction: "",
                })
            }).then((res) => {
                res.json().then((data) => {
                    const status = data.status;
                    if (status === "success") {
                        swal("Success", data.message, "success").then((ok) => {
                            if (ok) {
                                navigate("/dashboard");
                            }
                        })
                    } else {
                        swal("Error", data.message, "error");
                    }
                })
            })
        } catch (error) {
            swal(error);
        }
    };

    const getCurrentLatitudeLongitude = () => {
        navigator.geolocation.getCurrentPosition((posn) => {
            setLatitude(posn.coords.latitude);
            setLongitude(posn.coords.longitude);
        })
    };

    var regex = /[0-9]|null/;

    const handler = (e) => {
        if (!regex.test(e.nativeEvent.data)) {
            return;
        }
        const inputValue = e.target.value;
        if (inputValue.length <= 6) {
            setPincode(e.target.value);
        }
        if (inputValue.length <= 10) {
            setContactNumber(e.target.value)
        }
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
        getCurrentLatitudeLongitude();
        document.title = `Add Address - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? <Spinner /> :
                    <div className="app-wrapper">
                        <div className="sidebar-mobile-overlay"></div>
                        <div className="app-main">
                            <div className="app-header">
                                <div className="d-flex">
                                    <div className="container-fluid p-1">
                                        <div className="form-inline ml-auto mobile">
                                            <div className="btn">
                                                <i className="fa fa-bars"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-none d-lg-flex">
                                    <div className="nav-dashboard-card">
                                        <div className="pt-0 row text-center">
                                            <div className="col-md-6">
                                                <div className="nav-dashboard-sub-card">
                                                    <NavLink to="/add-address">
                                                        <div className="nav-dashboard-sub-card-text">
                                                            Ecowrap
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="nav-dashboard-sub-card">
                                                    <div className="nav-dashboard-sub-card-text">Shop</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="container-fluid p-2">
                                        <div className="form-inline mr-auto">
                                            <button type="button" className="btn bg-neutral-success text-success font-size-lg p-0 d-inline-block shadow-node d-44 rounded position-relative">
                                                <i className="fas fa-user-alt text-success"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="app-content">
                                <div className="app-content--inner">
                                    <div className="container">
                                        <form method="POST" onSubmit={addAddressDetails}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="container mt-3">
                                                        <span className="font-size-xxl font-weight-bold span-property">New property?</span>
                                                        <span className="font-size-xxl font-weight-bold span-register"> Register</span>
                                                        <div className="container mt-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="propertyName" className="font-size-md form-label br-2rem">
                                                                    Property Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control br-2rem"
                                                                    id="propertyName"
                                                                    name="propertyName"
                                                                    value={propertyName}
                                                                    onChange={(e) => setPropertyName(e.target.value)}
                                                                    placeholder="eg: Ecowrap"
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="contactPerson" className="font-size-md form-label br-2rem">
                                                                    Onwer Name
                                                                </label>
                                                                <input
                                                                    className="form-control br-2rem"
                                                                    type="text"
                                                                    id="contactPerson"
                                                                    name="contactPerson"
                                                                    value={contactPerson}
                                                                    onChange={(e) => setContactPerson(e.target.value)}
                                                                    placeholder="eg: John doe"
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="contactNumber" className="font-size-md form-label br-2rem">
                                                                    Onwer Number
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control br-2rem"
                                                                    id="contactNumber"
                                                                    name="contactNumber"
                                                                    value={contactNumber}
                                                                    onChange={(e) => { handler(e) }}
                                                                    placeholder="eg: 9425006123"
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-3">
                                                        <div className="font-size-xxl font-weight-bold span-address">
                                                            Address
                                                        </div>
                                                        <div className="container mt-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="address" className="font-size-md form-label br-2rem">
                                                                    Address
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control br-2rem"
                                                                    id="address"
                                                                    name="address"
                                                                    value={address}
                                                                    onChange={(e) => setAddress(e.target.value)}
                                                                    placeholder="eg: 7A, Gangotri Nagar Gopalpura Bypass Road"
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="area" className="font-size-md form-label br-2rem">
                                                                    Area
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control br-2rem"
                                                                    id="area"
                                                                    name="area"
                                                                    value={area}
                                                                    onChange={(e) => setArea(e.target.value)}
                                                                    placeholder="eg: Puliya, Triveni Nagar"
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="floorStreet" className="font-size-md form-label br-2rem">
                                                                    Floor/Street
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control br-2rem"
                                                                    id="floorStreet"
                                                                    name="floorStreet"
                                                                    value={floorStreet}
                                                                    onChange={(e) => setFloorStreet(e.target.value)}
                                                                    placeholder="eg: Floor and street"
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="city" className="font-size-md form-label br-2rem">
                                                                    City
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control br-2rem"
                                                                    id="city"
                                                                    name="city"
                                                                    value={city}
                                                                    onChange={(e) => setCity(e.target.value)}
                                                                    placeholder="eg: Jaipur"
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="state" className="font-size-md form-label br-2rem">
                                                                    State
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control br-2rem"
                                                                    id="state"
                                                                    name="state"
                                                                    value={state}
                                                                    onChange={(e) => setState(e.target.value)}
                                                                    placeholder="eg: Rajasthan"
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="country" className="font-size-md form-label br-2rem">
                                                                    Country
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control br-2rem"
                                                                    id="country"
                                                                    name="country"
                                                                    value={country}
                                                                    onChange={(e) => setCountry(e.target.value)}
                                                                    placeholder="eg: India"
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                            <div className="mb-3">
                                                                <label htmlFor="pincode" className="font-size-md form-label br-2rem">
                                                                    Pincode
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control br-2rem"
                                                                    id="pincode"
                                                                    name="pincode"
                                                                    value={pincode}
                                                                    onChange={(e) => { handler(e) }}
                                                                    placeholder="eg: 302018"
                                                                    autoComplete="off"
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="container mt-3">
                                                        <div className="font-size-xxl font-weight-bold span-type-property">
                                                            Type of property
                                                        </div>
                                                        <div className="container mt-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="propertyType" className="font-size-md form-label br-2rem">
                                                                    Property Type
                                                                </label>
                                                                <select
                                                                    className="form-control br-2rem"
                                                                    id="propertyType"
                                                                    name="propertyType"
                                                                    value={propertyType}
                                                                    onChange={(e) => setPropertyType(e.target.value)}
                                                                    required
                                                                >
                                                                    <option value="">Select your property type:</option>
                                                                    <option value="Hotel">Hotel</option>
                                                                    <option value="Bar/Restaurant">Bar/Restaurant</option>
                                                                    <option value="Cafe">Cafe</option>
                                                                    <option value="Educational">Educational</option>
                                                                    <option value="Buisness">Buisness</option>
                                                                    <option value="Retail">Retail</option>
                                                                    <option value="Household">Household</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="container">
                                                        <img className="addAddress-img" src="assets/images/dashboard/addnewaddress-image.png" alt="confirm-pickup-response" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-3">
                                                <div className="container align-center">
                                                    <button type="submit" className="btn btn-pickupschedule">
                                                        Save Address
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default AddAddress;