import React, { useState, useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import review from "./DataReview";
import Navbar from "./Navbar";
import Footer from "./Footer";

const ClientsPartners = ({ apiUrl, title }) => {

    const [clientsAndPartners, setClientsAndPartners] = useState([]);
    const [index, setIndex] = useState(0);
    const timeOutRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const getClientsAndPartners = async () => {
        try {
            const response = await fetch(`${apiUrl}/website/clientandpartners`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            })
            if (response.status === 200) {
                const data = await response.json();
                if (data.status === "success") {
                    setClientsAndPartners(data.data);
                }
            }
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        }
    };

    const resetTimeout = () => {
        if (timeOutRef.current) clearTimeout(timeOutRef.current);
    };

    useEffect(() => {
        getClientsAndPartners();
        AOS.init({ duration: 1000, offset: 120 });
        document.title = `Clients and Partners - ${title}`;
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        resetTimeout();
        timeOutRef.current = setTimeout(() =>
            setIndex((prevIndex) =>
                prevIndex === review.length - 1 ? 0 : prevIndex + 1
            ), 5000);
        return () => resetTimeout();
    }, [index]);

    return (
        <>
            <Navbar />
            <section id="clientsparters">
                <div className="row">
                    <div className="col-md-6">
                        <div className="clientspartners-top-heading">
                            <div className="our-investors"><span className="our-investors-span1">CLIENTS</span> <span className="our-investors-span2">AND PARTNERS</span></div>
                        </div>
                        <div className="clientspartners-top-text">
                            Every individual is responsible for making our surroundings
                            cleaner, greener, and garbage-free. We have 1000+ clients
                            connected with us. Here are some of our top performers.
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="clientspartners-top-image">
                            <img className="width-100percent" src="assets/images/clientsAndPartners/clientspartners-top.png" alt="clientspartners-top" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="section_bg_gray" data-aos="fade-up">
                <div className="container2">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="clpa1 clientspartners-sub-head" style={{ fontSize: 28 }}>Hotels</div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <Slider {...settings}>
                            {
                                clientsAndPartners.Hotels?.map((item, index) => {
                                    return (
                                        <div className="card-carsoul" key={index}>
                                            <div className="single-testimonial-item">
                                                <div className="card-top">
                                                    <img src="assets/images/users/hotel.png" alt={item.PropertyName} />
                                                </div>
                                                <h4><u>{item.PropertyName}</u> <span>{"Green Champion: " + item.ContactPerson}</span></h4>
                                                <br />
                                                <p><a className="color-black" href={`https://www.google.com/maps/place/${item.Latitude},${item.Longitude}`} target="_blank" rel="noopener noreferrer">
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i> {item.Address.slice(0, 65) + "..."}
                                                </a></p>
                                                <div>{"Total Waste Diverted from Landfill: " + item.TotalSoldWaste + " Kg"}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray" data-aos="fade-up">
                <div className="container2">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="clpa3 clientspartners-sub-head" style={{ fontSize: 28 }}>
                                    Restaurants & Bars
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <Slider {...settings}>
                            {
                                clientsAndPartners.Restaurants?.map((item, index) => {
                                    return (
                                        <div className="card-carsoul" key={index}>
                                            <div className="single-testimonial-item">
                                                <div className="card-top">
                                                    <img src="assets/images/users/restaurant.png" alt={item.PropertyName} />
                                                </div>
                                                <h4><u>{item.PropertyName}</u> <span>{"Green Champion: " + item.ContactPerson}</span></h4>
                                                <br />
                                                <p><a className="color-black" href={`https://www.google.com/maps/place/${item.Latitude},${item.Longitude}`} target="_blank" rel="noopener noreferrer">
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i> {item.Address.slice(0, 65) + "..."}
                                                </a></p>
                                                <div>{"Total Waste Diverted from Landfill: " + item.TotalSoldWaste + " Kg"}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray" data-aos="fade-up">
                <div className="container2">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="clpa3 clientspartners-sub-head" style={{ fontSize: 28 }}>
                                    Cafes & Night Clubs
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <Slider {...settings}>
                            {
                                clientsAndPartners.Cafes?.map((item, index) => {
                                    return (
                                        <div className="card-carsoul" key={index}>
                                            <div className="single-testimonial-item">
                                                <div className="card-top">
                                                    <img src="assets/images/users/cafe.png" alt={item.PropertyName} />
                                                </div>
                                                <h4><u>{item.PropertyName}</u> <span>{"Green Champion: " + item.ContactPerson}</span></h4>
                                                <br />
                                                <p><a className="color-black" href={`https://www.google.com/maps/place/${item.Latitude},${item.Longitude}`} target="_blank" rel="noopener noreferrer">
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i> {item.Address.slice(0, 65) + "..."}
                                                </a></p>
                                                <div>{"Total Waste Diverted from Landfill: " + item.TotalSoldWaste + " Kg"}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray" data-aos="fade-up">
                <div className="container2">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="clpa3 clientspartners-sub-head" style={{ fontSize: 28 }}>
                                    Educational Partners
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <Slider {...settings}>
                            {
                                clientsAndPartners.Educations?.map((item, index) => {
                                    if (index < 5) {
                                        return (
                                            <div className="card-carsoul" key={index}>
                                                <div className="single-testimonial-item">
                                                    <div className="card-top">
                                                        <img src="assets/images/users/educational.png" alt={item.PropertyName} />
                                                    </div>
                                                    <h4><u>{item.PropertyName}</u> <span>{"Green Champion: " + item.ContactPerson}</span></h4>
                                                    <br />
                                                    <p><a className="color-black" href={`https://www.google.com/maps/place/${item.Latitude},${item.Longitude}`} target="_blank" rel="noopener noreferrer">
                                                        <i className="fa fa-map-marker" aria-hidden="true"></i> {item.Address.slice(0, 65) + "..."}
                                                    </a></p>
                                                    <div>{"Total Waste Diverted from Landfill: " + item.TotalSoldWaste + " Kg"}</div>
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray pb-30" data-aos="fade-up">
                <div className="container2">
                    <div className="row text-center">
                        <div className="col-md-12">
                            <div className="pt-30">
                                <div className="clpa3 clientspartners-sub-head" style={{ fontSize: 28 }}>
                                    Bussiness Partners
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <Slider {...settings}>
                            {
                                clientsAndPartners.Business?.map((item, index) => {
                                    return (
                                        <div className="card-carsoul" key={index}>
                                            <div className="single-testimonial-item">
                                                <div className="card-top">
                                                    <img src="assets/images/users/business.png" alt={item.PropertyName} />
                                                </div>
                                                <h4><u>{item.PropertyName}</u> <span>{"Green Champion: " + item.ContactPerson}</span></h4>
                                                <br />
                                                <p><a className="color-black" href={`https://www.google.com/maps/place/${item.Latitude},${item.Longitude}`} target="_blank" rel="noopener noreferrer">
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i> {item.Address.slice(0, 65) + "..."}
                                                </a></p>
                                                <div>{"Total Waste Diverted from Landfill: " + item.TotalSoldWaste + " Kg"}</div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="section_bg_gray">
                <div className="what-they-say">
                    <div className="row text-center">
                        <div className="wtkau-heading">
                            <div className="our-investors"><span className="our-investors-span1">WHAT</span> <span className="our-investors-span2">THEY SAY ABOUT ECOWRAP</span></div>
                        </div>
                    </div>
                    <div className="slideshow-review">
                        <div className="slideshowSlider" style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
                            {
                                review.map((rvw, index) => (
                                    <div className="slide-review mb-5" key={index}>
                                        <div className="card-candp">
                                            <div className="content-review">
                                                "{review[index].content}"
                                            </div>
                                            <div className="row text-center pb-10">
                                                <div className="col-3">
                                                    <div className="rvw-photo-image">
                                                        <img className="rvw-photo" src={rvw.image} alt="review" />
                                                    </div>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row">
                                                        <div className="col-12 head-review text-left">
                                                            <div className="row text-left">
                                                                <div className="col-12 rate-review">
                                                                    {rvw.rating >= "0.5" ? (
                                                                        <span className="fa fa-star checked"></span>
                                                                    ) : (
                                                                        <span className="fa fa-star"></span>
                                                                    )}
                                                                    {rvw.rating >= "1.5" ? (
                                                                        <span className="fa fa-star checked"></span>
                                                                    ) : (
                                                                        <span className="fa fa-star"></span>
                                                                    )}
                                                                    {rvw.rating >= "2.5" ? (
                                                                        <span className="fa fa-star checked"></span>
                                                                    ) : (
                                                                        <span className="fa fa-star"></span>
                                                                    )}
                                                                    {rvw.rating >= "3.5" ? (
                                                                        <span className="fa fa-star checked"></span>
                                                                    ) : (
                                                                        <span className="fa fa-star"></span>
                                                                    )}
                                                                    {rvw.rating >= "4.5" ? (
                                                                        <span className="fa fa-star checked"></span>
                                                                    ) : (
                                                                        <span className="fa fa-star"></span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {rvw.name}
                                                            </div>
                                                            <div>
                                                                {rvw.establishment}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ClientsPartners;